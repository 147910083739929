import React, { useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Typography,
	InputLabel,
	TextField,
	Select,
	Modal,
	MenuItem,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IRootState } from '../../../interfaces/store';

import MoreHorizontalIcon from '../../icons/MoreHorizontalIcon';
import FormTextField from '../form/FormTextField';
import FormSelectField from '../form/FormSelectField';
import ResourceEditMenu from './ResourceEditMenu';
import FormHoursField from '../form/FormHoursField';
import FormUploadField from '../form/FormUploadField';

export default function ResourceEditForm(props: any) {
	
	const user = useSelector((state: IRootState) => state.main.my_user );

	const [ title, setTitle ] = useState("Lorem ipsum dolor sit amet, consetetur elitr");
	const [ category, setCategory ] = useState("Category");
	const [ subCategory, setSubCategory ] = useState("Sub Category");
	const [ location, setLocation ] = useState("Robert Robertson, 1234 NW Bobcat Lane");
	const [ cancerType, setCancerType ] = useState("Stet clita kasd gubergren");
	const [ phase, setPhase ] = useState("Stet clita kasd gubergren");
	const [ contactPerson, setContactPerson ] = useState("Mark Locker");
	const [ cancerCenter, setCancerCenter ] = useState("At vero eos et accusam et");
	const [ startHoursAvailable, setStartHoursAvailable ] = useState("");
	const [ endHoursAvailable, setEndHoursAvailable ] = useState("");
	const [ resourceUrl, setResourceUrl ] = useState("");

	const style = {
		card: {
			position: 'absolute',
			width: 840, maxHeight: '75vh',
			top: '50%', left: '50%',
			transform: 'translate(-50%, -50%)',
			overflowY: 'scroll',
		},
		wrap: {
			display: 'flex',
			mb: 2,
		},
	};

	return (

		<Modal
			open={props.openEditModal}
			onClose={props.handleCloseEditModal}
		>
			<Card sx={style.card}>
				<CardContent>
					<Box sx={style.wrap}>
						<Typography variant="h4">Edit Resource</Typography>
						<Box sx={{ ml: 'auto', position: 'relative', }}>
							<ResourceEditMenu />
							{/*<UserEditMenu user={user} />*/}
						</Box>
					</Box>
					<Grid container spacing={2}>
						<Grid xs={12} md={6}>
							<FormTextField
								label="Title"
								id="resource_title"
								value={title}
								setValue={setTitle}
							/>
						</Grid>
						<Grid xs={12} md={6}>
							<FormSelectField
								label="Category"
								id="resource_category"
								value={category}
								setValue={setCategory}
							>
								<MenuItem value="category_1">Category Example 1</MenuItem>
								<MenuItem value="category_2">Category Example 2</MenuItem>
								<MenuItem value="category_3">Category Example 3</MenuItem>
							</FormSelectField>
						</Grid>
						<Grid xs={12} md={6}>
							<FormTextField
								label="Location"
								id="resource_location"
								value={location}
								setValue={setLocation}
							/>
						</Grid>
						<Grid xs={12} md={6}>
							<FormSelectField
								label="Subcategory"
								id="resource_subcategory"
								value={subCategory}
								setValue={setSubCategory}
							/>
						</Grid>
						<Grid xs={12} md={6}>
							<FormTextField
								label="Cancer Type"
								id="resource_cancer_type"
								value={cancerType}
								setValue={setCancerType}
							/>
						</Grid>
						<Grid xs={12} md={6}>
							<FormSelectField
								label="Phase of Treatment"
								id="resource_phase"
								value={phase}
								setValue={setPhase}
							/>
						</Grid>
						<Grid xs={12} md={6}>
							<FormTextField
								label="Contact Person"
								id="resource_contact_person"
								value={contactPerson}
								setValue={setContactPerson}
							/>
						</Grid>
						<Grid xs={12} md={6}>
							<FormTextField
								label="Cancer Center Source"
								id="resource_cancer_center_source"
								value={cancerCenter}
								setValue={setCancerCenter}
							/>
						</Grid>
						<Grid xs={12} md={6}>
							<FormHoursField
								label="Hours Available"
								id="resource_hours_available"
								startValue={startHoursAvailable}
								endValue={endHoursAvailable}
								setStartValue={setStartHoursAvailable}
								setEndValue={setEndHoursAvailable}
							/>
						</Grid>
						<Grid xs={12} md={6}>
							<FormTextField
								label="Resource URL"
								type="url"
								id="resource_url"
								value={resourceUrl}
								setValue={setResourceUrl}
							/>
						</Grid>
						<Grid xs={12}>
							<FormUploadField 
							/>
						</Grid>
						<Grid xs={12}>
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
								<Button
									variant="outlined"
									onClick={props.handleCloseEditModal}
								>Cancel</Button>
								<Button variant="secondary" sx={{ ml: 2 }}>Save</Button>
							</Box>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Modal>

	)
}