import { all, call, put } from 'redux-saga/effects'; //call
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import GetFriendlyError from '../../utils/general';
import { autoYields } from './schema';

const client = new HttpClient();

export async function genericGet(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.get(url + objectToQuery(data));
}

export async function genericPost(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.post(url, data);
}

export async function genericPut(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.put(url, data);
}

export async function genericDelete(data: any, url: string): Promise<HttpResponse<{ x: string }>> {
  return client.delete(`${url}/${data.id}`);
}

const genericSaga = {
  *genericHandler(action: any, apiEndpoint: any): Generator {
    try {
      const response = (yield call(apiEndpoint, action.payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        //console.log("genericHandler response: " + action.type, response.data)
        const yields = autoYields(response.data);
        if (yields.length > 0) {
          yield all(yields);
        }
        if (action.onSuccess) {
          //console.log("call it with this data?", response.data)
          //console.log("the action?", action.onSuccess);
          action.onSuccess(response.data);
        }
        if (action.autoSuccessMessage){
          yield put({ type: ACTIONS.SUCCESS, payload: {message: action.autoSuccessMessage, epoch: new Date().getTime()} });
        }
      } else {
        throw response.status;
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail(e);
      }
      if (action.autoHandleError === true) {
        const error = e as Error;
        const friendlyError = GetFriendlyError(error.message);
        yield put({ type: ACTIONS.ERROR, payload: {error: friendlyError, epoch: new Date().getTime()} });
      }
    }
  },
};

export default genericSaga;
