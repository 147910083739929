import {
	Box,
	Card,
	CardContent,
	Typography,
	Pagination,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import theme from '../../themes/theme';
import { COLORS } from '../../enums/layout-enums';
import { useEffect, useState } from 'react';


const blocks = [
	{
		name: 'Category Name',
	},
	{
		name: 'Category Name',
	},
	{
		name: 'Category Name',
	},
	{
		name: 'Category Name',
	},
	{
		name: 'Category Name',
	},
	{
		name: 'Category Name',
	},
	{
		name: 'Category Name',
	},
	{
		name: 'Category Name',
	},
	{
		name: 'Category Name',
	}
];

export default function StaticSearchResources(props: any) {

	let style = {
		categoryLink: {
			fontSize: '0.875rem',
			mt: 1,
		},
		categoryLinkWrap: {
			width: '50%',
		},
		categoryLinkWrapRight: {
			width: '50%',
			textAlign: 'right',
		},
	};

	return (

		<Box sx={{ pt: 8, pb: 3, ml:2, mr:2 }} justifyContent="center" alignItems="center">
			<Grid container spacing={4}>
				{blocks.map((block, i) => {
					return (

						<Grid xs={12} md={4} key={i}>
							<Box>
							{/* <ResourceCard
								color={theme.palette.violet.main}
								icon="truck"
								title="Lorem Ipsum dolor sit amet consectetur adipisicing elit"
								type="Lorem"
								phase="Ipsum"
								agency="Lorem ipsum"
								language="Spanish"
								text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat similique illo expedita! Consectetur odit iusto aperiam odio veritatis quisquam enim ad atque reiciendis, hic consequuntur iure. Qui voluptatibus doloremque maiores, molestias inventore a nulla temporibus assumenda. Quos, error, quo cumque earum nesciunt eveniet?"
							/> */}
								 <Card variant="outlined">
									<CardContent sx={{ p: 2, }}>
										<Typography
											color={COLORS.BLUE_BRAND_PRIMARY_DARK}
											sx={{
												fontSize: '1.125rem',
												mb: 1,
											}}
										>{block.name}</Typography>
										<img
											src={process.env.PUBLIC_URL + "/images/category-" + (i + 1) + ".png"}
											alt=""
											style={{
												display: 'block',
												width: '100%', height: 'auto',
												borderRadius: 10,
											}}
										/>
										<Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1, }}>
											<Box sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Lorem Ipsum Link</Typography>
											</Box>
											<Box sx={style.categoryLinkWrapRight}>
												<Typography sx={style.categoryLink}>Lorem Ipsum Link</Typography>
											</Box>
											<Box sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Lorem Ipsum Link</Typography>
											</Box>
											<Box sx={style.categoryLinkWrapRight}>
												<Typography sx={style.categoryLink}>Lorem Ipsum Link</Typography>
											</Box>
											<Box sx={style.categoryLinkWrap}>
												<Typography sx={style.categoryLink}>Lorem Ipsum Link</Typography>
											</Box>
										</Box>
									</CardContent>
								</Card> 
							</Box>
						</Grid>
					
					)
				})}
			</Grid>
			{/* <Grid sx={{mt:3}} container justifyContent="center" alignItems="center">
				<Pagination shape="rounded" count={5} page={page} onChange={(event, value) => setPage(value)} />
			</Grid> */}
		</Box>

	)
}