import React, { useState } from 'react';
import {
	Box,
	Button,
	Card,
	FormControl,
	InputLabel,
	Input,
	MenuItem,
	Checkbox,
	OutlinedInput,
	Select,
	Typography,
	Paper,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import FilterIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '../../icons/SearchIcon';
import { COLORS } from '../../../enums/layout-enums';
import PlacesAutocomplete from 'react-places-autocomplete';

import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
interface IROption {
	value: string;
	label: string;
  }
const radiusOptions:IROption[] = [
	{ value: "5", label: "5 miles" },
	{ value: "10", label: "10 miles" },
	{ value: "15", label: "15 miles" },
	{ value: "20", label: "20 miles" },
	{ value: "25", label: "25 miles" },
	{ value: "50", label: "50 miles" },
	{ value: "100", label: "100 miles" },
	{ value: "150", label: "150 miles" },
	{ value: "200", label: "200 miles" },

  ];
export default function SearchForm(props: any) {

	const theme = useTheme();
	const isScreenSM = useMediaQuery(theme.breakpoints.down('md'));

    const handleCategoreisCheckboxChange = (event: SelectChangeEvent<number[]>, child: React.ReactNode)=> {
		props.setSelectedCategoriesId(Array.isArray(event.target.value) ? event.target.value : [event.target.value]);
      };
	const handleRadiusSelectChange = (event: SelectChangeEvent<string[]>, child: React.ReactNode) => {
		props.setSelectedRadiusOptions(Array.isArray(event.target.value) ? event.target.value : [event.target.value]);
	};
	const handleSelect = (address:any) => {
	  geocodeByAddress(address)
		.then(results => getLatLng(results[0]))
		.then(latLng => props.setlatLng(latLng))
		.then(()=> props.setAddress(address))
		.catch(error => console.error('Error', error));
	};
	const [filtersVisible, setFiltersVisible] = useState((props?.address && props?.showSearchResult) ? true: false);

	const handleToggleFilters = () => {
		setFiltersVisible(prev => !prev);
	}
	const handleClear = () => {
		// setSearchCategories([]);
		// setSearchRadius([]);
	}

	const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter'){
			// if key is Enter prevent default action of page realoads
			e.preventDefault();
			// Call the search function
			props.handleSearch();
		}
	}

	

	return (

		<>
			<form action="">
				<Box>
					<Box justifyContent="center" sx={{
						marginTop: '-20px',
						position: 'relative',
						zIndex: 98,
						display: 'flex',
					}}>
						
						<Card sx={{ flex: '1 1 auto', maxWidth: "80%" }}>
							<Box sx={{ display: 'flex', alignItems: 'center', p: 2, }}>
								<Box sx={{ display: 'flex', flex: '1 1 auto', alignItems: 'center' }}>
									<Box
										sx={{
											width: 48,
											display: 'flex',
											justifyContent: 'center',
											cursor: 'pointer',
										}}
									>
										<SearchIcon />
									</Box>
									<Box sx={{ ml: 1, width: '100%', }}>
										<Input
											value={props.search}
											placeholder="Enter keyword"
											fullWidth
											onKeyDown={keyPress}
											sx={{

												'&:before': { display: 'none', },
												'&:after': { display: 'none', },
											}}
											onChange={(e) => props.setSearch(e?.target.value)}
										/>
									</Box>
								</Box>
								<Box 
								onClick={handleToggleFilters}
								sx={{
									display: 'flex',
									alignItems: 'center',
									flex: '0 0 auto',
									ml: 'auto', pl: 2,
									borderLeft: 'solid 1px ' + COLORS.GRAY_BORDER,
									cursor: 'pointer'
								}}>
									<FilterIcon color="primary" sx={{ mr: 1, }} />
									{!isScreenSM && 
										<Typography 
											sx={{
												color: COLORS.BLUE_BRAND_PRIMARY,
												fontWeight: 600,
												cursor: 'pointer',
												mr: 1,
											}}
											
										>Advanced filters</Typography>
									}
								</Box>
							</Box>
						</Card>

						<Paper sx={{ml: 2, display:'flex'}}>
							<Button sx={{ backgroundColor:'white', justifyContent:"center" }}
								onClick={() => {props.handleSearch()}}>
								<Typography sx={{
												color: COLORS.BLUE_BRAND_PRIMARY,
												fontWeight: 900,
												cursor: 'pointer',
												fontStyle:"bold",
												textTransform:"capitalize",
												pl:1,
												pr:1
											}} >
									Search
								</Typography>
							</Button>
						</Paper>
					</Box>
					{filtersVisible ?

						<Box sx={{
							display: 'flex',
							alignItems: 'center',
							pb: 1,
						}}>
							<Box sx={{
								flex: '1 1 auto',
								mr: 0,
								alignItems:'center',
							}}>
								<Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ maxWidth: '100%', alignItems:'center'}} >
								{!props.showSearchResult &&
									<Grid xs={12} md={4}>
										<FormControl fullWidth >
      										<InputLabel id="my-select-category">Select Categories</InputLabel>
											<Select sx={{backgroundColor:"white"}}
												labelId="my-select-category"
												multiple
												value={props.selectedCategoriesId}
												input={<OutlinedInput label="Select Categories" />}
												onChange={handleCategoreisCheckboxChange}
												renderValue={(selected) => {
													if(selected.length === 1){
														return props.categoriesOptions
														.filter((x:any) => selected.includes(x.id))
														.map((x:any) => x.name)
														.join(', ');
													}
													else if (selected.length > 1){
														return `${selected.length} categories selected`;
													}
													else return '';
												}}
												>
												{props.categoriesOptions.map((item: any) => (
													<MenuItem  sx={{ pt: 0, pb: 0, }} key={item.id} value={item.id}>
														<Checkbox checked={props.selectedCategoriesId.indexOf(item.id) > -1} size="small" />
														<Typography sx={{fontSize: '0.875rem'}}>{item.name}</Typography>
													</MenuItem>
												))}
												</Select>
										</FormControl>
									</Grid>
								}
									<Grid xs={12} md={!props.showSearchResult ? 6 : 8}>
										<Box pt={!props.showSearchResult ? 5 : 3}>
											<PlacesAutocomplete 
													value= {props.address}
													onChange={(a)=> props.setAddress(a)}
													onSelect={(a)=> handleSelect(a)}
													>
													{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
												<div>
													<OutlinedInput
														fullWidth
														sx={{
															pl: 3,
															backgroundColor:"white"
														}}
														// placeholder= "Search Places ..."
														{...getInputProps({
															placeholder: 'Search Location ...',
															className: 'location-search-input',
														})}

													/>
													<div className="autocomplete-dropdown-container" style={{position: 'absolute', zIndex:900, backgroundColor:"white", borderRight:'solid 1px #DDDDDD', borderLeft:'solid 1px #DDDDDD'}}>
													{loading && <div>Loading...</div>}
													{suggestions.map(suggestion => {
														const className = suggestion.active
														? 'suggestion-item--active'
														: 'suggestion-item';
														// inline style for demonstration purpose
														const style = suggestion.active
														? { backgroundColor: '#fafafa', cursor: 'pointer',borderBottom: 'solid 1px #DDDDDD', padding:"12px"  }
														: { backgroundColor: '#ffffff', cursor: 'pointer', borderBottom: 'solid 1px #DDDDDD', padding:"12px" };
														return (
														<div
															{...getSuggestionItemProps(suggestion, {
															className,
															style,
															})}
														>
															<span>{suggestion.description}</span>
														</div>
														);
													})}
													</div>
													<Typography sx={{pl:2, pr:2, textAlign:"center"}} style={{fontSize:"0.875rem",color:"#0009"}}>* When searching by city or state, use the state capital or city center as the central point of the radius search</Typography>
												</div>
												)}
											</PlacesAutocomplete>
										</Box>
									</Grid>
									<Grid xs={12} md={2}>
										<FormControl fullWidth >
      										<InputLabel id="my-select-label">Distance</InputLabel>
											<Select sx={{backgroundColor:"white"}}
												// multiple
												labelId="my-select-label"
												value={props.selectedRadiusOptions}
												onChange={handleRadiusSelectChange}
												input={<OutlinedInput label="Distance" />}
												renderValue={(selected) => (selected as string[]).join(", ") + " miles"}
												>
												{radiusOptions.map((radiusOptions: IROption) => (
													<MenuItem  sx={{ pt: 0, pb: 0, }} key={radiusOptions.value} value={radiusOptions.value}>
														<Checkbox checked={props.selectedRadiusOptions.indexOf(radiusOptions.value) > -1} size="small" />
														<Typography sx={{fontSize: '0.875rem'}}>{radiusOptions.label}</Typography>
													</MenuItem>
												))}
												</Select>
										</FormControl>
									</Grid>
									{/* <Grid >
										<Button
											variant="white"
											sx={{
												flex: '0 0 auto',
												width: 124,
												ml: 'auto',
												backgroundColor:'white'
											}}
											onClick={handleClear}
										>Clear
										</Button>
									</Grid> */}
								</Grid>
							</Box>

						</Box>
					: null}
				</Box>
			</form>
		</>

	)
}