import {
	Typography,
	Box,
} from '@mui/material';
import ArrowLeftIcon from '../../components/icons/ArrowLeftIcon';

import { useNavigate  } from "react-router";

function BackToLogin() {

	const history = useNavigate();

	const handleBackToLogin = () => {
		history('/Admin');
	}

	return (

		<Box sx={{ alignItems: 'center', mb: 2, }}>
			<ArrowLeftIcon color="primary" sx={{ mr: 1 }} />
			<Typography variant="button" color="primary" sx={{ fontSize: '1.125rem', }} onClick={() => handleBackToLogin()}>
				Back to log in
			</Typography>
		</Box>

	)
}

export default BackToLogin;