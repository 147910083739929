import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function FolderIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<g clipPath="url(#clip0_158_19976)">
				<path d="M20.8006 8.29273C20.8006 8.13113 20.6694 7.99993 20.5078 7.99993H20.0006V10.7999H20.8006V8.29273Z" />
				<path fillRule="evenodd" clipRule="evenodd" d="M19.2006 0.799927H4.80059V10.8423C4.80419 10.8413 4.80808 10.841 4.81198 10.8407C4.81588 10.8404 4.81979 10.8401 4.82339 10.8391C4.91019 10.8155 4.99979 10.7999 5.09299 10.7999H19.2006V0.799927ZM11.2008 3.9991H7.20078C6.97998 3.9991 6.80078 3.8203 6.80078 3.5991C6.80078 3.3779 6.97998 3.1991 7.20078 3.1991H11.2008C11.4216 3.1991 11.6008 3.3779 11.6008 3.5991C11.6008 3.8203 11.4216 3.9991 11.2008 3.9991ZM16.8008 6.79988H7.20078C6.97998 6.79988 6.80078 6.62108 6.80078 6.39988C6.80078 6.17868 6.97998 5.99988 7.20078 5.99988H16.8008C17.0216 5.99988 17.2008 6.17868 17.2008 6.39988C17.2008 6.62108 17.0216 6.79988 16.8008 6.79988ZM7.20078 9.60066H16.8008C17.0216 9.60066 17.2008 9.42186 17.2008 9.20066C17.2008 8.97946 17.0216 8.80066 16.8008 8.80066H7.20078C6.97998 8.80066 6.80078 8.97946 6.80078 9.20066C6.80078 9.42186 6.97998 9.60066 7.20078 9.60066Z" />
				<path d="M4.00059 11.8339V1.59993H3.60059V12.9303L4.00059 11.8339Z" />
				<path d="M2.80059 2.39993H2.40059V16.2203L2.80059 15.1239V2.39993Z" />
				<path d="M1.09339 5.19993C0.932192 5.19993 0.800592 5.33113 0.800592 5.49273V20.5591L1.60059 18.3771V5.19993H1.09339Z" />
				<path d="M18.9302 23.1999C19.0322 23.1999 19.1218 23.1315 19.1478 23.0335L23.197 11.9275C23.1874 11.8183 23.1482 11.5999 22.9966 11.5999H5.09299C4.99139 11.5999 4.90139 11.6687 4.87499 11.7667L4.86459 11.7995L4.80059 11.9751L4.77339 12.0491L4.00059 14.1695V14.1683L0.798192 22.9479C0.783392 23.0087 0.822192 23.0783 0.847392 23.1111C0.872592 23.1443 0.929392 23.1999 1.02659 23.1999H18.9302Z" />
			</g>
		</SvgIcon>
	)
}

export default FolderIcon;