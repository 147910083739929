import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { ListItemButton } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import Toolbar from '@mui/material/Toolbar';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './interfaces/store';
import { getUserRolesByUser, logout } from './store/actions/authActions';
import { useEffect, useState } from 'react';
import { COLORS } from './enums/layout-enums';
import { release_notes } from './pages/private/site/ReleaseNotes';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { Outlet } from "react-router-dom"

const drawerWidth = 240;
const authContStyle = {
  marginTop: 0,
  width: 800,
};

function AppAuthenticated(props: any) {
  const { window } = props;
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const user = useSelector((state: IRootState) => state.main.my_user);
  const user_roles = useSelector((state: IRootState) => state.main.user_roles);
  const roles = useSelector((state: IRootState) => state.main.roles);
  const [isAdmin, setIsAdmin] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    dispatch(getUserRolesByUser({}));
  }, []);

  useEffect(() => {
    let userRolesArr = Object.values(user_roles);
    let rolesArr = Object.values(roles);
    if (!isAdmin && user?.id && userRolesArr.length && rolesArr.length) {
      const adminRole = rolesArr.find((x) => x.name === 'Admin');
      if (adminRole) {
        const userAdminRole = userRolesArr.find(
          (x) => x.user_id === user.id && x.role_id === adminRole.id,
        );
        if (userAdminRole) {
          setIsAdmin(true);
        }
      }
    }
  }, [user, user_roles, roles, isAdmin]);

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
          <ListItemButton
            key={'dashboard'}
            onClick={(): void => {
              history('/Dashboard');
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={'Dashboard'} />
          </ListItemButton>

        <ListItemButton
          key={'users'}
          onClick={(): void => {
            history('/Users');
          }}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={'Users'} />
        </ListItemButton>

        <ListItemButton
          key={'account'}
          onClick={(): void => {
            history('/resources');
          }}
        >
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          <ListItemText primary={'Resource'} />
        </ListItemButton>

        <ListItemButton
          key={'email'}
          onClick={(): void => {
            history('/domains');
          }}
        >
          <ListItemIcon>
            <MarkEmailReadIcon />
          </ListItemIcon>
          <ListItemText primary={'Trusted Domains'} />
        </ListItemButton>

      </List>
      <Divider />
      <List>
        <ListItemButton
          key={'logout'}
          onClick={(): void => {
            dispatch(
              logout({}, () => {
                history('/Admin');
              }),
            );
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Log Out'} />
        </ListItemButton>
      </List>
      {/* <TimeZoneSelector/> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  // Redirect to /Dashboard of user redirected to /
  if(location.pathname === '/' ) return <Navigate to="/dashboard" replace />

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: COLORS.BLUE_BRAND_PRIMARY }}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: 1 }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <img
                onClick={() => {
                  history('/Search');
                }}
                alt={'logo'}
                width={150}
                style={{ cursor: 'pointer', float: 'left' }}
                src={process.env.PUBLIC_URL + '/images/ternLogo.png'}
              ></img>
            </div>
            <div
              style={{
                cursor: 'pointer',
                fontSize: 12,
                flex: 1,
                justifyContent: 'right',
                textAlign: 'right',
              }}
            >
              App Version: {release_notes[0].version}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 6 }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default AppAuthenticated;
