import { Modal as MaterialModal } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import classes from './Modal.module.css';

const styles = {
    content:{
        position: 'relative' as 'relative',
        width: '100%', 
        maxWidth: '900px',
        maxHeight: "calc(100vh - 160px)",
        background: '#fff',
        borderRadius: '20px',
        boxShadow: '0px 4px 20px rgb(35 102 169 / 12%)',
        margin: '30px',
        display: "flex",
        flexDirection: "column",
        // overflowY: "scroll"
    },
    header:{
        padding: '40px 50px 20px',
        fontFamily: 'Helvetica Neue',
        fontFtyle: 'normal',
        fontWeight: '500',
        fontSize: '22px',
        lineHeight: '27px',
        display: 'flex',
        alignItems: 'center',
        color: '#123559',
    },
    body:{
        padding: '20px 50px 20px',
        flex: 1,
        overflowY: "scroll"
    },
    footer:{
        padding: '20px 50px 40px',
    },
}

const ModalCloseButton = ({ onClick }:any) => {
    return (
        <div className={classes.closeButton} onClick={onClick}>
                <HighlightOffIcon />
        </div>
    )
}

export const ModalHeader = ({ style, children }:any) => {
    return (
        <div style={{...styles.header, ...style}}>
            {children}
        </div>
    )
}
ModalHeader.defaultProps = {
    style: {}
}

export const ModalBody = ({ style, children }:any) => {
    return (
        <div style={{...styles.body, ...style}}>
            {children}
        </div>
    )
}
ModalBody.defaultProps = {
    style: {}
}

export const ModalFooter = ({ style, children }:any) => {
    return (
        <div style={{...styles.footer, ...style}}>
            {children}
        </div>
    )
}
ModalFooter.defaultProps = {
    style: {}
}

const Modal = ({ isOpen, showCloseButton, children, onClose, style }:any) => {
    return (
        <MaterialModal
            open={isOpen}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            slotProps={{
                // backdrop:{
                //     style: { backgroundColor: "rgba(248, 250, 251, 0.6)" },
                // }
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div style={{...styles.content, ...style}}>
                {showCloseButton && <ModalCloseButton onClick={onClose} />}
                {children}
            </div>
        </MaterialModal>
    )
}
Modal.defaultProps = {
    isOpen: false,
    showCloseButton: true,
    onClose: () => {},
    style: {},
}

export default Modal;