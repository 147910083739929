import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function TrayIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M17.3474 1H6.6526C6.07025 1.00035 5.50729 1.18614 5.02849 1.52419C4.55129 1.86111 4.17847 2.33359 3.94036 2.8787L3.93991 2.87973L0.0650302 11.6905C0.0221469 11.7881 0 11.8934 0 12V19.6744C0 20.527 0.296582 21.3613 0.849018 21.9903C1.40423 22.6224 2.17983 23 3.01277 23H20.9872C21.8202 23 22.5958 22.6224 23.151 21.9903C23.7034 21.3613 24 20.527 24 19.6744V12C24 11.8934 23.9779 11.7881 23.935 11.6905L20.0601 2.87973L20.0594 2.87819C19.8213 2.3333 19.4486 1.861 18.9715 1.52419C18.4927 1.18614 17.9298 1.00035 17.3474 1ZM5.91091 2.77885C6.14254 2.61531 6.39928 2.53507 6.65288 2.53488H17.3471C17.6007 2.53507 17.8575 2.61531 18.0891 2.77885C18.3221 2.94335 18.5226 3.18782 18.6565 3.49469L18.6574 3.49666L22.0595 11.2326H16.4936C16.2219 11.2326 15.9705 11.3768 15.833 11.6117L13.8081 15.0698H10.1919L8.16704 11.6117C8.02953 11.3768 7.77813 11.2326 7.50638 11.2326H1.94049L5.34263 3.49666L5.3435 3.49469C5.47742 3.18782 5.67792 2.94335 5.91091 2.77885Z" />
		</SvgIcon>
	)
}

export default TrayIcon;