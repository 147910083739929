import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function MentalHealthIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<g clipPath="url(#clip0_158_19708)">
				<path d="M22.125 5.625C21.7272 5.625 21.3456 5.78304 21.0643 6.06434C20.783 6.34564 20.625 6.72718 20.625 7.125V11.8702L19.8443 12.8625C19.7853 12.6167 19.6452 12.3979 19.4467 12.2415C19.2482 12.085 19.0028 11.9999 18.75 12H18.7009C18.5466 12.0003 18.3941 12.0322 18.2526 12.0937C18.1112 12.1552 17.9839 12.2451 17.8785 12.3577L13.2544 17.3119C12.9298 17.6587 12.7494 18.116 12.75 18.591V23.25C12.75 23.3495 12.7895 23.4448 12.8598 23.5152C12.9302 23.5855 13.0255 23.625 13.125 23.625H17.625C17.7245 23.625 17.8198 23.5855 17.8902 23.5152C17.9605 23.4448 18 23.3495 18 23.25V21.6214C17.9996 21.4736 18.0285 21.3271 18.085 21.1905C18.1415 21.054 18.2245 20.9299 18.3293 20.8256L21.2651 17.8901C21.2904 17.8647 21.3119 17.836 21.3293 17.8046L23.5793 13.6796C23.6093 13.6245 23.625 13.5628 23.625 13.5V7.125C23.625 6.72718 23.467 6.34564 23.1857 6.06434C22.9044 5.78304 22.5228 5.625 22.125 5.625V5.625ZM22.875 13.4044L20.6966 17.4L17.799 20.2972C17.6246 20.4708 17.4863 20.6771 17.3921 20.9044C17.2978 21.1317 17.2495 21.3753 17.25 21.6214V22.875H13.5V18.591C13.4997 18.3061 13.6079 18.0317 13.8026 17.8237L18.4271 12.8689C18.4622 12.8314 18.5046 12.8015 18.5517 12.781C18.5988 12.7606 18.6495 12.75 18.7009 12.75H18.75C18.8495 12.75 18.9448 12.7895 19.0152 12.8598C19.0855 12.9302 19.125 13.0255 19.125 13.125V13.7794L16.5803 17.0182L17.1698 17.4818L21.2948 12.2318C21.3467 12.1657 21.375 12.0841 21.375 12V7.125C21.375 6.92609 21.454 6.73532 21.5947 6.59467C21.7353 6.45402 21.9261 6.375 22.125 6.375C22.3239 6.375 22.5147 6.45402 22.6553 6.59467C22.796 6.73532 22.875 6.92609 22.875 7.125V13.4044Z" fill="#1F2937"/>
				<path d="M22.125 5.625C21.7272 5.625 21.3456 5.78304 21.0643 6.06434C20.783 6.34564 20.625 6.72718 20.625 7.125V11.8702L19.8443 12.8625C19.7853 12.6167 19.6452 12.3979 19.4467 12.2415C19.2482 12.085 19.0028 11.9999 18.75 12H18.7009C18.5466 12.0003 18.3941 12.0322 18.2526 12.0937C18.1112 12.1552 17.9839 12.2451 17.8785 12.3577L13.2544 17.3119C12.9298 17.6587 12.7494 18.116 12.75 18.591V23.25C12.75 23.3495 12.7895 23.4448 12.8598 23.5152C12.9302 23.5855 13.0255 23.625 13.125 23.625H17.625C17.7245 23.625 17.8198 23.5855 17.8902 23.5152C17.9605 23.4448 18 23.3495 18 23.25V21.6214C17.9996 21.4736 18.0285 21.3271 18.085 21.1905C18.1415 21.054 18.2245 20.9299 18.3293 20.8256L21.2651 17.8901C21.2904 17.8647 21.3119 17.836 21.3293 17.8046L23.5793 13.6796C23.6093 13.6245 23.625 13.5628 23.625 13.5V7.125C23.625 6.72718 23.467 6.34564 23.1857 6.06434C22.9044 5.78304 22.5228 5.625 22.125 5.625ZM22.875 13.4044L20.6966 17.4L17.799 20.2972C17.6246 20.4708 17.4863 20.6771 17.3921 20.9044C17.2978 21.1317 17.2495 21.3753 17.25 21.6214V22.875H13.5V18.591C13.4997 18.3061 13.6079 18.0317 13.8026 17.8237L18.4271 12.8689C18.4622 12.8314 18.5046 12.8015 18.5517 12.781C18.5988 12.7606 18.6495 12.75 18.7009 12.75H18.75C18.8495 12.75 18.9448 12.7895 19.0152 12.8598C19.0855 12.9302 19.125 13.0255 19.125 13.125V13.7794L16.5803 17.0182L17.1698 17.4818L21.2948 12.2318C21.3467 12.1657 21.375 12.0841 21.375 12V7.125C21.375 6.92609 21.454 6.73532 21.5947 6.59467C21.7353 6.45402 21.9261 6.375 22.125 6.375C22.3239 6.375 22.5147 6.45402 22.6553 6.59467C22.796 6.73532 22.875 6.92609 22.875 7.125V13.4044Z" fill="#1F2937"/>
				<path d="M22.875 13.4044L20.6966 17.4L17.799 20.2972C17.6246 20.4708 17.4863 20.6771 17.3921 20.9044C17.2978 21.1317 17.2495 21.3753 17.25 21.6214V22.875H13.5V18.591C13.4997 18.3061 13.6079 18.0317 13.8026 17.8237L18.4271 12.8689C18.4622 12.8314 18.5046 12.8015 18.5517 12.781C18.5988 12.7606 18.6495 12.75 18.7009 12.75H18.75C18.8495 12.75 18.9448 12.7895 19.0152 12.8598C19.0855 12.9302 19.125 13.0255 19.125 13.125V13.7794L16.5803 17.0182L17.1698 17.4818L21.2948 12.2318C21.3467 12.1657 21.375 12.0841 21.375 12V7.125C21.375 6.92609 21.454 6.73532 21.5947 6.59467C21.7353 6.45402 21.9261 6.375 22.125 6.375C22.3239 6.375 22.5147 6.45402 22.6553 6.59467C22.796 6.73532 22.875 6.92609 22.875 7.125V13.4044Z" fill="#1F2937"/>
				<path d="M6.1215 12.3577C6.01614 12.2451 5.88881 12.1552 5.74737 12.0937C5.60593 12.0322 5.45337 12.0003 5.29913 12H5.25C4.99723 11.9999 4.7518 12.085 4.55328 12.2415C4.35477 12.3979 4.21473 12.6167 4.15575 12.8625L3.375 11.8702V7.125C3.375 6.72718 3.21697 6.34564 2.93566 6.06434C2.65436 5.78304 2.27282 5.625 1.875 5.625C1.47718 5.625 1.09564 5.78304 0.81434 6.06434C0.533035 6.34564 0.375 6.72718 0.375 7.125V13.5C0.374979 13.5628 0.390709 13.6245 0.42075 13.6796L2.67075 17.8046C2.68806 17.836 2.70965 17.8647 2.73488 17.8901L5.67075 20.8256C5.77548 20.9299 5.85851 21.054 5.91502 21.1905C5.97153 21.3271 6.00042 21.4736 6 21.6214V23.25C6 23.3495 6.03951 23.4448 6.10984 23.5152C6.18016 23.5855 6.27554 23.625 6.375 23.625H10.875C10.9745 23.625 11.0698 23.5855 11.1402 23.5152C11.2105 23.4448 11.25 23.3495 11.25 23.25V18.591C11.2506 18.116 11.0702 17.6587 10.7456 17.3119L6.1215 12.3577ZM10.5 22.875H6.75V21.6214C6.7507 21.375 6.70254 21.131 6.60831 20.9034C6.51407 20.6758 6.37563 20.4691 6.201 20.2954L3.30338 17.4L1.125 13.4044V7.125C1.125 6.92609 1.20402 6.73532 1.34467 6.59467C1.48532 6.45402 1.67609 6.375 1.875 6.375C2.07391 6.375 2.26468 6.45402 2.40533 6.59467C2.54598 6.73532 2.625 6.92609 2.625 7.125V12C2.62502 12.0841 2.65328 12.1657 2.70525 12.2318L6.83025 17.4818L7.41975 17.0182L4.875 13.7794V13.125C4.875 13.0255 4.91451 12.9302 4.98484 12.8598C5.05516 12.7895 5.15054 12.75 5.25 12.75H5.29913C5.35055 12.75 5.40143 12.7606 5.44858 12.7811C5.49574 12.8016 5.53818 12.8316 5.57325 12.8693L10.1974 17.8237C10.3921 18.0317 10.5003 18.3061 10.5 18.591V22.875Z" fill="#1F2937"/>
				<path d="M6.1215 12.3577C6.01614 12.2451 5.88881 12.1552 5.74737 12.0937C5.60593 12.0322 5.45337 12.0003 5.29913 12H5.25C4.99723 11.9999 4.7518 12.085 4.55328 12.2415C4.35477 12.3979 4.21473 12.6167 4.15575 12.8625L3.375 11.8702V7.125C3.375 6.72718 3.21696 6.34564 2.93566 6.06434C2.65436 5.78304 2.27282 5.625 1.875 5.625C1.47718 5.625 1.09564 5.78304 0.81434 6.06434C0.533035 6.34564 0.375 6.72718 0.375 7.125V13.5C0.374979 13.5628 0.390709 13.6245 0.42075 13.6796L2.67075 17.8046C2.68806 17.836 2.70965 17.8647 2.73488 17.8901L5.67075 20.8256C5.77548 20.9299 5.85851 21.054 5.91502 21.1905C5.97153 21.3271 6.00042 21.4736 6 21.6214V23.25C6 23.3495 6.03951 23.4448 6.10984 23.5152C6.18016 23.5855 6.27554 23.625 6.375 23.625H10.875C10.9745 23.625 11.0698 23.5855 11.1402 23.5152C11.2105 23.4448 11.25 23.3495 11.25 23.25V18.591C11.2506 18.116 11.0702 17.6587 10.7456 17.3119L6.1215 12.3577ZM10.5 22.875H6.75V21.6214C6.7507 21.375 6.70254 21.131 6.60831 20.9034C6.51407 20.6758 6.37563 20.4691 6.201 20.2954L3.30338 17.4L1.125 13.4044V7.125C1.125 6.92609 1.20402 6.73532 1.34467 6.59467C1.48532 6.45402 1.67609 6.375 1.875 6.375C2.07391 6.375 2.26468 6.45402 2.40533 6.59467C2.54598 6.73532 2.625 6.92609 2.625 7.125V12C2.62502 12.0841 2.65328 12.1657 2.70525 12.2318L6.83025 17.4818L7.41975 17.0182L4.875 13.7794V13.125C4.875 13.0255 4.91451 12.9302 4.98484 12.8598C5.05516 12.7895 5.15054 12.75 5.25 12.75H5.29913C5.35055 12.75 5.40143 12.7606 5.44858 12.7811C5.49574 12.8016 5.53818 12.8316 5.57325 12.8693L10.1974 17.8237C10.3921 18.0317 10.5003 18.3061 10.5 18.591V22.875Z" fill="#1F2937"/>
				<path d="M10.5 22.875H6.75V21.6214C6.7507 21.375 6.70254 21.131 6.60831 20.9034C6.51407 20.6758 6.37563 20.4691 6.201 20.2954L3.30338 17.4L1.125 13.4044V7.125C1.125 6.92609 1.20402 6.73532 1.34467 6.59467C1.48532 6.45402 1.67609 6.375 1.875 6.375C2.07391 6.375 2.26468 6.45402 2.40533 6.59467C2.54598 6.73532 2.625 6.92609 2.625 7.125V12C2.62502 12.0841 2.65328 12.1657 2.70525 12.2318L6.83025 17.4818L7.41975 17.0182L4.875 13.7794V13.125C4.875 13.0255 4.91451 12.9302 4.98484 12.8598C5.05516 12.7895 5.15054 12.75 5.25 12.75H5.29913C5.35055 12.75 5.40143 12.7606 5.44858 12.7811C5.49574 12.8016 5.53818 12.8316 5.57325 12.8693L10.1974 17.8237C10.3921 18.0317 10.5003 18.3061 10.5 18.591V22.875Z" fill="#1F2937"/>
				<path d="M10.5001 14.25C10.2586 14.2492 10.0239 14.1706 9.8307 14.0258C9.6375 13.881 9.49612 13.6778 9.42756 13.4463C9.83867 13.3501 10.2053 13.1179 10.468 12.7873C10.7307 12.4567 10.8741 12.0472 10.8751 11.625H10.1251C10.1247 11.7895 10.0884 11.9519 10.0185 12.1008C9.94855 12.2498 9.84684 12.3815 9.72049 12.4869C9.59414 12.5922 9.44622 12.6686 9.28715 12.7106C9.12809 12.7525 8.96176 12.7591 8.79987 12.7299C8.63799 12.7006 8.48449 12.6362 8.3502 12.5411C8.21592 12.4461 8.10411 12.3228 8.02265 12.1799C7.94119 12.0369 7.89207 11.8779 7.87875 11.7139C7.86543 11.5499 7.88824 11.385 7.94556 11.2308C7.97816 11.1434 7.97702 11.047 7.94235 10.9604C7.90768 10.8738 7.84197 10.8032 7.75806 10.7625C7.56785 10.6703 7.40744 10.5265 7.2952 10.3474C7.18296 10.1684 7.12344 9.9613 7.12344 9.74996C7.12344 9.53862 7.18296 9.33156 7.2952 9.15248C7.40744 8.97341 7.56785 8.82958 7.75806 8.73746C7.84215 8.69669 7.90798 8.62599 7.94266 8.53921C7.97735 8.45243 7.97838 8.35583 7.94556 8.26833C7.88823 8.1141 7.86542 7.94917 7.87876 7.78517C7.8921 7.62117 7.94124 7.46209 8.02274 7.31915C8.10424 7.17621 8.2161 7.05288 8.35043 6.95787C8.48477 6.86285 8.63831 6.79846 8.80024 6.76924C8.96216 6.74001 9.12853 6.74666 9.28761 6.78872C9.44668 6.83077 9.5946 6.90721 9.72092 7.01265C9.84725 7.11808 9.94891 7.24994 10.0187 7.39893C10.0886 7.54792 10.1249 7.71042 10.1251 7.87496H10.8751C10.8741 7.45273 10.7307 7.04318 10.468 6.71262C10.2053 6.38206 9.83867 6.14985 9.42756 6.05358C9.50391 5.7945 9.67118 5.57167 9.89865 5.42603C10.1261 5.28039 10.3985 5.22173 10.6657 5.26081C10.933 5.29989 11.1772 5.43411 11.3534 5.63878C11.5296 5.84346 11.6261 6.10486 11.6251 6.37496V13.125C11.6251 13.4233 11.5065 13.7095 11.2956 13.9205C11.0846 14.1314 10.7984 14.25 10.5001 14.25Z" fill="#FAC628"/>
				<path d="M12.3751 6.37496C12.374 6.10486 12.4705 5.84346 12.6467 5.63878C12.8229 5.43411 13.0671 5.29989 13.3344 5.26081C13.6016 5.22173 13.874 5.28039 14.1015 5.42603C14.3289 5.57167 14.4962 5.7945 14.5726 6.05358C14.1614 6.14985 13.7949 6.38206 13.5322 6.71262C13.2695 7.04318 13.126 7.45273 13.1251 7.87496H13.8751C13.8754 7.71045 13.9118 7.54801 13.9817 7.39909C14.0516 7.25017 14.1533 7.11839 14.2796 7.01304C14.406 6.90769 14.5539 6.83133 14.713 6.78935C14.872 6.74737 15.0384 6.74078 15.2002 6.77006C15.3621 6.79933 15.5156 6.86375 15.6499 6.95878C15.7842 7.05381 15.896 7.17714 15.9775 7.32006C16.0589 7.46299 16.108 7.62205 16.1214 7.78602C16.1347 7.94999 16.1119 8.11488 16.0546 8.26908C16.022 8.35649 16.0231 8.45291 16.0578 8.53952C16.0924 8.62612 16.1581 8.6967 16.2421 8.73746C16.4323 8.82958 16.5927 8.97341 16.7049 9.15248C16.8172 9.33156 16.8767 9.53862 16.8767 9.74996C16.8767 9.9613 16.8172 10.1684 16.7049 10.3474C16.5927 10.5265 16.4323 10.6703 16.2421 10.7625C16.158 10.8032 16.0921 10.8739 16.0575 10.9607C16.0228 11.0475 16.0217 11.1441 16.0546 11.2316C16.1119 11.3858 16.1347 11.5507 16.1214 11.7147C16.108 11.8787 16.0589 12.0378 15.9774 12.1808C15.8959 12.3237 15.784 12.447 15.6497 12.5421C15.5153 12.6371 15.3618 12.7015 15.1999 12.7307C15.038 12.7599 14.8716 12.7533 14.7125 12.7112C14.5534 12.6691 14.4055 12.5927 14.2792 12.4873C14.1529 12.3818 14.0512 12.25 13.9814 12.101C13.9116 11.952 13.8753 11.7895 13.8751 11.625H13.1251C13.126 12.0472 13.2695 12.4567 13.5322 12.7873C13.7949 13.1179 14.1614 13.3501 14.5726 13.4463C14.4962 13.7054 14.3289 13.9282 14.1015 14.0739C13.874 14.2195 13.6016 14.2782 13.3344 14.2391C13.0671 14.2 12.8229 14.0658 12.6467 13.8611C12.4705 13.6565 12.374 13.3951 12.3751 13.125V6.37496Z" fill="#FAC628"/>
				<path d="M4.87506 4.43621C4.87932 3.5535 5.23271 2.70834 5.85804 2.08533C6.48338 1.46231 7.32984 1.11206 8.21256 1.11108H8.22493C8.66337 1.11146 9.09738 1.19882 9.50182 1.36812C9.90626 1.53741 10.2731 1.78527 10.5811 2.09733L11.7001 3.22496C11.7389 3.2696 11.7872 3.30503 11.8414 3.32866C11.8956 3.35228 11.9545 3.36352 12.0136 3.36154C12.0727 3.35957 12.1307 3.34443 12.1832 3.31724C12.2357 3.29004 12.2816 3.25147 12.3173 3.20433L13.4176 2.09583C13.7257 1.78384 14.0926 1.53603 14.4971 1.36675C14.9015 1.19746 15.3356 1.11005 15.7741 1.10958H15.7876C16.6705 1.11057 17.5172 1.46102 18.1426 2.08436C18.768 2.7077 19.1212 3.55324 19.1251 4.43621C19.1286 5.23577 18.9698 6.02773 18.6585 6.7642C18.3472 7.50066 17.8897 8.16634 17.3138 8.72096C17.1879 8.5289 17.0281 8.36141 16.8421 8.22671C16.8882 7.98421 16.886 7.73498 16.8355 7.49334C16.785 7.25171 16.6873 7.02244 16.5479 6.81871C16.4085 6.61498 16.2302 6.44082 16.0232 6.30623C15.8163 6.17164 15.5848 6.07928 15.3421 6.03446C15.2775 5.67503 15.1089 5.34244 14.8572 5.0779C14.6055 4.81335 14.2817 4.62849 13.9259 4.54621C13.5701 4.46393 13.198 4.48785 12.8556 4.61501C12.5133 4.74217 12.2158 4.96698 12.0001 5.26158C11.7841 4.96747 11.4867 4.74312 11.1446 4.61627C10.8025 4.48942 10.4307 4.46565 10.0753 4.54789C9.71981 4.63013 9.39625 4.81479 9.14462 5.079C8.893 5.34321 8.72436 5.6754 8.65956 6.03446C8.41707 6.07952 8.18586 6.17197 7.97917 6.30653C7.77247 6.44109 7.59435 6.6151 7.45501 6.81861C7.31567 7.02211 7.21785 7.25111 7.16715 7.49247C7.11646 7.73384 7.11387 7.98284 7.15956 8.22521C6.9722 8.35856 6.81133 8.52567 6.68518 8.71796C6.10982 8.16359 5.65278 7.49836 5.34167 6.76244C5.03056 6.02652 4.87183 5.23518 4.87506 4.43621Z" fill="#1F2937"/>
			</g>
		</SvgIcon>
	)
}

export default MentalHealthIcon;