import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CopyIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M8.25 4C8.25 3.0335 9.0335 2.25 10 2.25H18C18.9665 2.25 19.75 3.0335 19.75 4V16C19.75 16.9665 18.9665 17.75 18 17.75H14.75V20C14.75 20.9665 13.9665 21.75 13 21.75H5C4.0335 21.75 3.25 20.9665 3.25 20V8C3.25 7.0335 4.0335 6.25 5 6.25H8.25V4ZM9.75 6.25H13C13.9665 6.25 14.75 7.0335 14.75 8V16.25H18C18.1381 16.25 18.25 16.1381 18.25 16V4C18.25 3.86193 18.1381 3.75 18 3.75H10C9.86193 3.75 9.75 3.86193 9.75 4V6.25ZM4.75 8C4.75 7.86193 4.86193 7.75 5 7.75H13C13.1381 7.75 13.25 7.86193 13.25 8V20C13.25 20.1381 13.1381 20.25 13 20.25H5C4.86193 20.25 4.75 20.1381 4.75 20V8Z" />
		</SvgIcon>
	)
}

export default CopyIcon;