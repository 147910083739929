import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function StarIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path d="M11.5459 3.34598C11.6888 2.88467 12.3112 2.88467 12.4541 3.34598L14.2514 9.14603C14.3153 9.35233 14.4987 9.49201 14.7055 9.49201H20.5216C20.9842 9.49201 21.1765 10.1127 20.8022 10.3978L16.097 13.9824C15.9296 14.1099 15.8596 14.3359 15.9235 14.5422L17.7208 20.3423C17.8637 20.8036 17.3602 21.1872 16.9859 20.9021L12.2807 17.3175C12.1133 17.19 11.8867 17.19 11.7193 17.3175L7.01406 20.9021C6.63982 21.1872 6.13629 20.8036 6.27924 20.3423L8.07649 14.5422C8.14041 14.3359 8.07038 14.1099 7.90302 13.9824L3.19775 10.3978C2.82352 10.1127 3.01585 9.49201 3.47843 9.49201H9.29446C9.50133 9.49201 9.68468 9.35233 9.7486 9.14603L11.5459 3.34598Z" />
		</SvgIcon>
	)
}

export default StarIcon;