import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function PhoneIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path d="M23.743 18.2009C22.8496 16.512 19.7472 14.6807 19.6106 14.6006C19.2119 14.3737 18.7959 14.2536 18.4066 14.2536C17.8278 14.2536 17.3539 14.5188 17.067 15.0011C16.6131 15.5439 16.0503 16.1783 15.9137 16.2766C14.8566 16.9939 14.029 16.9124 13.1134 15.9968L8.00299 10.886C7.09313 9.9761 7.00948 9.13831 7.7218 8.08696C7.82146 7.94948 8.45592 7.38621 8.99873 6.93194C9.34488 6.72594 9.58247 6.41984 9.68658 6.04432C9.82495 5.54467 9.72306 4.95693 9.39649 4.38432C9.31952 4.25262 7.48733 1.14973 5.79929 0.256772C5.48429 0.0899257 5.12924 0.00183105 4.7733 0.00183105C4.18689 0.00183105 3.63519 0.230521 3.22052 0.644744L2.09131 1.77351C0.305393 3.55898 -0.34108 5.58294 0.168801 7.78886C0.594147 9.62728 1.8337 11.5836 3.85365 13.6031L10.3962 20.1457C12.9528 22.7022 15.39 23.9987 17.6405 23.9987C17.6405 23.9987 17.6405 23.9987 17.6409 23.9987C19.296 23.9987 20.839 23.2953 22.2258 21.9085L23.3546 20.7797C24.0407 20.0941 24.1964 19.0574 23.743 18.2009Z" />
		</SvgIcon>
	)
}

export default PhoneIcon;