import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function MoneyIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<g clipPath="url(#clip0_158_19712)">
				<path d="M9.27617 0.960037C9.17465 1.08897 9.15762 1.26525 9.23256 1.41124L10.3138 3.60004H13.6578L14.739 1.41044C14.8144 1.26414 14.7974 1.08723 14.6954 0.958037C14.5996 0.832417 14.4394 0.774255 14.2854 0.809237L13.9902 0.876037C13.3352 1.04757 12.6626 1.14286 11.9858 1.16004C11.309 1.14352 10.6364 1.04889 9.98137 0.878037L9.68497 0.811237C9.53117 0.773959 9.37002 0.832616 9.27617 0.960037ZM8.38577 4.80004C8.38577 4.57912 8.56485 4.07064 8.78577 4.07064H13.903H13.9058H13.9102H15.1858C15.4067 4.07064 15.5858 4.57912 15.5858 4.80004C15.5858 5.02095 15.4067 5.5 15.1858 5.5H13.8938H10.0802H8.78577C8.56485 5.5 8.38577 5.02095 8.38577 4.80004ZM10.1754 6.00004C6.78051 7.7692 4.68935 11.3171 4.78576 15.144C4.78576 19.8856 7.00656 22 11.9858 22C12.4361 22 12.8639 21.9827 13.2696 21.9478C17.3485 21.596 19.1858 19.4568 19.1858 15.144C19.282 11.3168 17.1903 7.76887 13.795 6.00004H10.1754ZM10.7858 12.8C10.7858 13.4627 11.323 14 11.9858 14C13.0133 13.999 13.8744 14.7767 13.9777 15.799C14.0809 16.8213 13.3927 17.7555 12.3858 17.96V18.4C12.3858 18.6209 12.2067 18.8 11.9858 18.8C11.7649 18.8 11.5858 18.6209 11.5858 18.4V17.96C10.6552 17.7686 9.98697 16.95 9.98577 16C9.98577 15.7791 10.1649 15.6 10.3858 15.6C10.6067 15.6 10.7858 15.7791 10.7858 16C10.7858 16.6627 11.323 17.2 11.9858 17.2C12.6485 17.2 13.1858 16.6627 13.1858 16C13.1858 15.3373 12.6485 14.8 11.9858 14.8C10.9583 14.801 10.0971 14.0233 9.99388 13.001C9.89062 11.9787 10.5788 11.0445 11.5858 10.84V10.4C11.5858 10.1791 11.7649 10 11.9858 10C12.2067 10 12.3858 10.1791 12.3858 10.4V10.84C13.3163 11.0314 13.9846 11.85 13.9858 12.8C13.9858 13.0209 13.8067 13.2 13.5858 13.2C13.3649 13.2 13.1858 13.0209 13.1858 12.8C13.1858 12.1373 12.6485 11.6 11.9858 11.6C11.323 11.6 10.7858 12.1373 10.7858 12.8Z" />
			</g>
		</SvgIcon>
	)
}

export default MoneyIcon;