import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function PlusIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path d="M13.7929 1.35354C14.3783 0.768183 15.1722 0.439331 16 0.439331C16.4099 0.439331 16.8158 0.520066 17.1945 0.676927C17.5732 0.833788 17.9173 1.0637 18.2071 1.35354C18.497 1.64339 18.7269 1.98748 18.8837 2.36617C19.0406 2.74487 19.1213 3.15075 19.1213 3.56065C19.1213 3.97055 19.0406 4.37643 18.8837 4.75513C18.7269 5.13383 18.497 5.47792 18.2071 5.76776L5.70713 18.2678C5.57897 18.3959 5.41839 18.4868 5.24256 18.5308L1.24256 19.5308C0.901783 19.616 0.541294 19.5161 0.292914 19.2678C0.0445346 19.0194 -0.055315 18.6589 0.0298785 18.3181L1.02988 14.3181C1.07384 14.1423 1.16476 13.9817 1.29291 13.8535L13.7929 1.35354ZM9.00002 18.5607C9.00002 18.0084 9.44774 17.5607 10 17.5607H19C19.5523 17.5607 20 18.0084 20 18.5607C20 19.1129 19.5523 19.5607 19 19.5607H10C9.44774 19.5607 9.00002 19.1129 9.00002 18.5607Z" />
		</SvgIcon>
	)
}

export default PlusIcon;