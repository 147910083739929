import React, { useState } from 'react';
import {
	Box,
	Button,
	Typography,
	InputLabel,
	OutlinedInput,
	Select,
	TextField,
	MenuItem,
	FormControl
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchBar(props: any) {
	const [category, setCategory] = useState('');
	const [search, setSearch] = useState('');

	const style = {
		content: {
			display: 'flex',
			alignItems: 'center',
			flexGrow: 1,
			boxShadow: 1,
			padding: 2,
			borderRadius: '8px',
			height: 76 
		},
		button: {
			height: 76,
			width: 124,
			ml: 2,
			mr: 0,
		},
		menuItem: {
			fontSize: '0.875rem',
		}
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<Box sx={style.content}>
				<Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', }}>
					<SearchIcon sx={{ ml: 1, mr: 2, }} />
					<TextField
						value={search}
						placeholder="Enter keyword"
						fullWidth
						size="small"
						variant="standard"
						id="search-bar"
						sx={{
							margin: '0',
							border: 'none',
						}}
						InputProps={{
							style: {
								fontSize: '1rem',
								paddingBottom: 0,
							}
						}}
						onChange={(e) => setSearch(e?.target.value)}
					/>
				</Box>
				<FormControl size="small" sx={{ width: '310px' }}>
					<Select
						displayEmpty={true}
						value={category}
						onChange={(e) => setCategory(e?.target.value)}
						renderValue={(selected) => (
							<Typography sx={{ fontSize: '1rem', color: '#BDBFC2', }}>Filtered by Category: {(selected.length === 0) ? 'All Categories' : selected}</Typography>
						)}
						sx={{
							'.MuiOutlinedInput-notchedOutline': { border: 0 }
						}}
						MenuProps={{
							sx: {
								boxShadow: 0,
							}
						}}
					>
						<MenuItem sx={style.menuItem} value="">All Categories</MenuItem>
						<MenuItem sx={style.menuItem} value={'sample-1'}>Category 1</MenuItem>
						<MenuItem sx={style.menuItem} value={'sample-2'}>Category 2</MenuItem>
						<MenuItem sx={style.menuItem} value={'sample-3'}>Category 3</MenuItem>
					</Select>
				</FormControl>
			</Box>
			<Button variant="white" sx={style.button}>
				<Typography variant="gradient">Search</Typography>
			</Button>
		</Box>
	)
}

export default SearchBar;