import {
	Box,
} from '@mui/material';

import { COLORS } from '../../enums/layout-enums';
import theme from '../../themes/theme';

export default function IconClose(props: any) {

	const style = {
		circle: {
			width: 24, height: 24,
			borderRadius: 12,
			position: 'relative',
			// left: -39, top: 10,
			background: COLORS.GRAY_LIGHT,
			cursor: 'pointer',

			'&:before': {
				content: '""',
				display: 'block',
				position: 'absolute',
				left: '50%', top: '50%',
				width: '1px', height: '14px',
				background: COLORS.GRAY,
				transform: 'translate(-50%, -50%) rotate(45deg)',
				borderRadius: '1px',
			},

			'&:after': {
				content: '""',
				display: 'block',
				position: 'absolute',
				left: '50%', top: '50%',
				width: '1px', height: '14px',
				background: COLORS.GRAY,
				transform: 'translate(-50%, -50%) rotate(-45deg)',
				borderRadius: '1px',
			},

			'&:hover': {
				'&:before': { background: theme.palette.error.main },
				'&:after': { background: theme.palette.error.main },
			}
		},
	}

	return (

		<Box onClick={props.onClick ? props.onClick : () => {}} sx={style.circle}></Box>

	)
}