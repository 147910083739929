import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, CardContent, Chip, FormControlLabel, FormGroup, IconButton, Switch, Tooltip, Typography, } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import Breadcrumbs from '../../../components/shared/Breadcrumbs';
import PageTitle from '../../../components/shared/PageTitle';
import Table from '../../../components/shared/Table/Table';
import { useDispatch } from 'react-redux';
import ResourceForm from '../../../components/shared/ResourceForm';
import { getAllResources, getCancerCenters, getCancerTypes, getResourceCategories, getTreatmentPhases } from '../../../store/actions/resourceActions';
import MaterialTable, { MTableBodyRow, Query, QueryResult } from '@material-table/core';
import { tableIcons } from '../../../components/shared/MaterialTableShared';
import { TABLE_DEFAULT_PAGE_SIZES } from '../../../shared/constants/layout-constants';
import Edit from '@mui/icons-material/Edit';
import { COLORS } from '../../../enums/layout-enums';
import EyeIcon from '../../../components/icons/EyeIcon';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getResource } from '../../../store/actions/userActions';
import Loading from '../../../components/general/Loading';

const iconStyle = {
	color: COLORS.BLUE_BRAND_PRIMARY,
	cursor: 'pointer',
};

export default function ResourcesPage(props: any) {

	const dispatch = useDispatch();
  	const tableRef =  useRef<any>(null);
	  
	const [fetching, setFetching] = useState<boolean>(false);
	const [openModal, setModalOpen] = useState<boolean>(false);
	const [categories, setCategories] = useState<any[]>([]);
	const [cancerTypes, setCancerTypes] = useState<any[]>([]);
	const [cancerCenters, setCancerCenters] = useState<any[]>([]);
	const [treatmentPhases, setTreatmentPhases] = useState<any[]>([]);
	const [editableData, setEditableData] = useState<any>(null);
	const [showSuspended, setShowSuspended] = useState<boolean>(false);

	useEffect(() => {
		dispatch(getResourceCategories(
			{},
			(response) => {
				setCategories(response);
			},
			() => {},
		))
		dispatch(getCancerTypes(
			{},
			(response) => {
				setCancerTypes(response);
			},
			() => {},))
		dispatch(getCancerCenters(
			{},
			(response) => {
				setCancerCenters(response);
			},
			() => {},))
		dispatch(getTreatmentPhases(
			{},
			(response) => {
				setTreatmentPhases(response);
			},
			() => {},))
	}, [])

	useEffect(() => {
		updateTable?.()
	}, [showSuspended])

	const columns = [
		{
			title: 'Title',
			field: 'title',
			sortingField: 'r.title',
			render: (row: any) => {
				return (  
					<div style={{ maxHeight:'175px', width:'350px', overflowX:'auto', overflowY:'auto',lineBreak:"anywhere" }}>
						{row?.title}
					</div>
				);
			},
		},
		{
			title: 'Category',
			render: (row:any) => (row?.category?.name),
			sortingField: 'category.name',
		},
		{
			title: 'Cancer Type',
			render: (row:any) => (row?.cancerType?.name),
			sortingField: 'cancerType.name',
		},
		{
			title: 'Copy Count',
			field: 'copy_count',
			// render: (row:any) => (0),
			sortingField: 'r.copy_count',
		},
		{
			title: 'Status',
			render: (row:any) => {
				return(<>
					{row.active && <Chip label="Active" size="small" color="success" />}
					{!row.active && <Chip label="Suspended" size="small" color="error" />}
				</>)
			},
			sortingField: 'r.active',
		},
		{
			title: 'User Actions',
			sorting: false,
			render: (row: any) => {
			  return (
				<>
					{row.active && <Tooltip title={'View'} placement="top">
						<IconButton href={`/resources/${row.id}`}>
							<VisibilityIcon style={iconStyle} />
						</IconButton>
					</Tooltip>}
					<Tooltip title={'Edit'} placement="top">
						<IconButton onClick={() => {onClickEdit(row)}}>
							<Edit style={iconStyle} />
						</IconButton>
					</Tooltip>
				</>
			  );
			},
		  },
	];

	const components =  {
		Action: (props:any) => {
		  return (<FormGroup>
			<FormControlLabel
			  control={<Switch defaultChecked onChange={(event) => setShowSuspended(event?.target?.checked)} checked={showSuspended} />}
			  label="Include suspended"
			  labelPlacement="start"
			  sx={{ textTransform:'capitalize' }}
			/>
		  </FormGroup>)
		},
	}

	const actions: any = [
		{
		  icon: Edit,
		  tooltip: 'Refresh Data',
		  isFreeAction: true,
		  onClick: () => alert("HeHe")
		}
	]

	const handleTableDataQuery  = async (query: Query<object>): Promise<any> =>
		new Promise((resolve, reject) => {

			const {page, pageSize, search, orderBy, orderDirection}: any = query;

			dispatch(getAllResources(
				{
					skip: (page) * pageSize,
					take: pageSize,
					search: search,
					active: showSuspended,
					...(orderBy && {orderBy: orderBy?.sortingField}),
					...(orderDirection && {orderDirection}),
				},
				(data) => {
					resolve({
						data: data.data,
						page: page,
						totalCount: data.total,
					});
				},
				(error) => {
				return error
				},
			))
	})

	const updateTable = () => {
		// Custom table
		// tableRef?.current && tableRef?.current.fetch();

		// Material table
		tableRef?.current && tableRef.current.onQueryChange();
	}

	const onClickAdd = () => {
		setModalOpen(true)
	}

	const onClickEdit = (row: any) => {
		setFetching(true);

		dispatch(getResource(
			{body:{isActive: false}, id:row.id},
			(res) => {
				setEditableData(res)
				setModalOpen(true);
				setFetching(false);
			},
			(error) => {
				setFetching(false);
			},
		));
	}

	const closeModal = () => {
		setModalOpen(false);
		setEditableData(null);
	}

	const onSuspendResource = () => {
		updateTable();
		closeModal();
	}

	return (

		<>
		{fetching && <Loading loading={fetching} />}

		<Breadcrumbs title={"Resource Management"} />
		<Box display={"flex"} alignItems={"center"}>
			<h1 className='pageTitle'>Resource Management</h1>
			<Button 
				className='pageSubTitle' 
				sx={{ marginLeft: "20px" }} 
				startIcon={<AddBoxOutlinedIcon />}
				onClick={onClickAdd}
			>Add</Button>
		</Box>

		<Box marginTop={"35px"}>

			<MaterialTable
				icons={tableIcons}
				tableRef={tableRef}
				title={"Resources"}
				columns={columns}
				data={handleTableDataQuery}
				options={{
					sorting: true,
					search: true,
					// pageSizeOptions: TABLE_DEFAULT_PAGE_SIZES,
					pageSizeOptions:[10, 15, 20],
					toolbar: true,
					// showTitle: false
				}}
				actions={actions}
				components={components}
			/>

			{openModal && <ResourceForm
				data={editableData}
				onSuccess={updateTable}
				openModal={openModal} 
				closeModal={closeModal}
				categories={categories}
				cancerTypes={cancerTypes}
				cancerCenters={cancerCenters}
				treatmentPhases={treatmentPhases}
				onSuspendResource={onSuspendResource}
			/>}

		</Box>

		</>

	)
}