import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import UserTable from '../../../components/user/UserTable';
import { getUsers } from '../../../store/actions/userActions';
import AddEditUserForm from '../../../components/shared/AddUserForm';
import StandardModal from '../../../components/shared/StandardModal';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import BasicBreadcrumbs from '../../../components/shared/Breadcrumbs';

function Users() {
  const dispatch = useDispatch();
  const [userTableKey, setUserTableKey] = useState(0);
  const [addEditUserModalOpen, setAddEditUserModalOpen] = useState(false);
  const roles = useSelector((state: IRootState) => state.main.roles);

  useEffect(() => {
    dispatch(getUsers({}, () => {}));
  }, []);

  const handleAddEditUserModalOpen = () => {
    setAddEditUserModalOpen(true);
  };

  const handleAddEditUserModalClose = () => {
    setAddEditUserModalOpen(false);
  };

  return (
    <>
      <BasicBreadcrumbs title={"User Management"} />
      <Box display={"flex"} alignItems={"center"} marginBottom={"35px"}>
        <h1 className='pageTitle'>User Management</h1>
        <Button 
          className='pageSubTitle' 
          sx={{ marginLeft: "20px" }} 
          startIcon={<AddBoxOutlinedIcon />}
          onClick={handleAddEditUserModalOpen}
        >Add</Button>
      </Box>
      {/* <div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
        <div style={{ flex: 1, textAlign: 'right' }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ maxHeight: 40, marginLeft: '15px' }}
            onClick={handleAddEditUserModalOpen}
          >
            Add User
          </Button>
        </div>
      </div> */}
      <UserTable
        title={'Users'}
        userTableKey={userTableKey}
        setUserTableKey={setUserTableKey}
      />
      <StandardModal
        actions={[]}
        title={'Add User'}
        open={addEditUserModalOpen}
        onClose={handleAddEditUserModalClose}
      >
        <AddEditUserForm
          roles={roles}
          handleCloseModal={handleAddEditUserModalClose}
          onSuccess={() => setUserTableKey(userTableKey + 1)}
        />
      </StandardModal>
    </>
  );
}

export default Users;
