import errors from './errors';

export default function GetFriendlyError(error: string): string {
  if (errors[error]) {
    return errors[error];
  }
  return errors.UNKNOWN_ERROR;
}

export const boolToString = (val: any, trueVal: string, falseVal: string) => {
  let boolval = false;
  if (typeof val === 'boolean'){
    boolval = val;
  }
  else{
    if (typeof val === "string"){
      if (val.toLowerCase() === 'true'){
        boolval = true;
      }
    }
  }
  if (boolval === true){
    return trueVal
  }
  return falseVal
}

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const getUrlParam = (param: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param)
}

export const deepClone = (object: object | any[]) => {
  return JSON.parse(JSON.stringify(object));
};

export const isUserAdmin = () => {
  const roles = JSON.parse(localStorage.getItem('roles') ?? '{}');
	const isAdmin = Object.values(roles).find((role:any) => role.name.toUpperCase() === "ADMIN");
  return isAdmin;
}

export const convertResourcesToCopyHtml = (resourceBoardItems: any[]) => {
  let copyString = '<div style="border-radius: 10px; background: #F5F9FE; padding: 20px;"><p style="font-size: 0.875rem; margin: 0; padding: 0;">These resources were gathered by your navigator from the <b>Tern Cancer Resource Database</b>. Please click on the resource name to access the link.</p></div>';
		resourceBoardItems.map((resource: any, index: number) => {

			const url = resource?.resource?.cta_url;
			let urlPrefix = '';

			if(url && (!url.includes('http://') && !url.includes('https://'))) urlPrefix += 'http://';
			let category = (resource.category?.name ?? '');

			if(index === 0) { copyString += '<div style="padding: 20px; border-radius: 10px; border: solid 1px #dddddd; margin-top: 10px;">'; }
			copyString += `<div>`
			
			if(url) copyString += `<a href="${urlPrefix}${(url ? url : '#')}" style="color: #3683EF; text-decoration: none; font-weight: bold; font-size: 1.125rem;">` + resource?.resource?.title + '</a>';
			else copyString += `<span style="color: #3683EF; text-decoration: none; font-weight: bold; font-size: 1.125rem;">` + resource?.resource?.title + '</span>';
			
			copyString += '<br /><span style="font-size: 0.875rem"><strong>Category:</strong> ' + category + '</span>';
			copyString += `<div/>`
			if(resourceBoardItems.length !== (index + 1)) { copyString += '<hr style="height: 1px; background: #ddd;" />'; }

		});
		if(resourceBoardItems.length) { copyString += '</div>'; }
		return copyString;
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatTime(timeString: string) {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
}
