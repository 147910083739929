import {
	Typography,
} from '@mui/material';

export default function ProfilePage(props: any) {

	return (

		<>
			<Typography variant="h1" sx={{ pt: 2, pb: 2, }}>My Profile</Typography>
		</>

	)
}