import {
	Grid,
	Box,
	Typography,
	Button
} from '@mui/material';

import theme from '../../../themes/theme';

import IconBlock from '../IconBlock';

export default function ResourceDetailBlock(props: any) {

	const style = {
		title: {
			fontSize: '0.75rem',
			textTransform: 'uppercase',
			color: '#AAA',
		},
		content: {
			fontSize: {
				xs: '0.75rem',
				md: '0.875rem',
				xl: '1rem',
			},
			// whiteSpace: 'nowrap',
			// overflow: 'hidden',
			// textOverflow: 'ellipsis',

			display: '-webkit-box',
			'-webkit-line-clamp': '1',
			'-webkit-box-orient': 'vertical',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			overflowWrap: 'anywhere',
			whiteSpace: 'break-spaces',
		},
		link: {
			textDecoration: 'underline',
			display: '-webkit-box',
			'-webkit-line-clamp': '1',
			'-webkit-box-orient': 'vertical',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			overflowWrap: 'anywhere',
			whiteSpace: 'break-spaces',
		}
	};

	return (

		<Box sx={{ display: 'flex' }}>
			<IconBlock color={theme.palette.primary.main} icon={props.icon} title={props.description} />
			<Box sx={{ flex:1 }}>
				<Typography sx={style.title}>{props.title}</Typography>
				{!props.link && !props.button &&  <Typography sx={style.content}>{props.content}</Typography>}
				{props.link && <a style={style.link as any} href={props.link} download>{props.content}</a>}
				{props.button && <Button onClick={props.onClick ? props.onClick : ()=>{}} style={style.link as any} sx={{padding:0, textTransform: 'initial'}}>{props.content}</Button>}
			</Box>
		</Box>

	)
}