import {
	Box,
	Typography,
} from "@mui/material";
import {
	CCarousel,
	CCarouselItem
} from "@coreui/react";

const slides = [
  {
    title: "Tern Resources is the trusted source for cancer resources.",
    caption: 'Approved Navigators can login to search for nationwide resources',
    image: '/images/slides/slide-1.png',
  },
  {
    title: "Tern Resources is the trusted source for cancer resources.",
    caption: 'Approved Navigators can login to search for nationwide resources',
    image: '/images/slides/slide-2.png',
  },
  {
    title: "Tern Resources is the trusted source for cancer resources.",
    caption: 'Approved Navigators can login to search for nationwide resources',
    image: '/images/slides/slide-3.png',
  },
];

const style = {
	image: {
		backgroundSize: 'cover',
		backgroundRepeat: 'none',
		position: 'relative',
		width: '100%',
		height: {
			xs: 520,
			md: '100vh',
		},
	},
	content: {
		position: 'absolute',
		left: {
			xs: 20,
			lg: 80,
			xl: 85,
		},
		right: {
			xs: 20,
			lg: 80,
			xl: 85,
		},
		bottom: {
			xs: 60,
			lg: 140,
		},
		padding: 4,
		backdropFilter: 'blur(5px)',
		backgroundColor: 'rgba(255, 255, 255, 0.65)',
		border: 'solid 1px rgba(255, 255, 255, .25)',
		borderRadius: '10px',
	}
}

function Carousel(props: any) {

	return (

		<>
			<CCarousel controls transition="crossfade">
				{slides.map(function(slide, i) {
					return (

						<CCarouselItem key={i}>
							<Box sx={style.image} style={{
								backgroundImage: 'url(' + slide.image + ')',
							}}>                  
								<Box sx={style.content}>
									<Box sx={{ mb: 1, }}>
										<Typography variant="h3" color="primary">{slide.title}</Typography>
									</Box>
									<Typography variant="body2">{slide.caption}</Typography>
								</Box>
							</Box>
						</CCarouselItem>

					)
				})}
			</CCarousel>
		</>

	)
}

export default Carousel;