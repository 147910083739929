import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function DietIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<g clipPath="url(#clip1_158_19715)">
				<path d="M14.3801 0.0500743C14.5919 -0.0554745 14.8492 0.0306919 14.9547 0.242492C14.9548 0.242794 14.9549 0.243045 14.9551 0.243346C15.058 0.457657 14.9684 0.714851 14.7545 0.818793C14.5711 0.921178 13.0088 1.86087 12.5572 4.49307C12.2739 4.5838 11.9726 4.60394 11.6797 4.55177C12.1596 1.29954 14.1525 0.167875 14.3801 0.0500743Z" />
				<path d="M4.28778 0.551382C4.27372 0.427404 4.3507 0.311261 4.47031 0.27586C6.08161 -0.25319 7.84662 -0.0132693 9.25822 0.926728C10.0683 1.42831 10.7273 2.13999 11.1654 2.98603C11.0358 3.38844 10.9356 3.79974 10.8655 4.21667C10.5616 4.04529 10.2428 3.90188 9.91295 3.78819C9.11365 2.58523 7.91456 1.70383 6.52791 1.29996C6.29753 1.24578 6.06685 1.38859 6.01267 1.61897C5.95849 1.84935 6.1013 2.08003 6.33168 2.13421C7.17506 2.38985 7.94107 2.85252 8.5598 3.48008C7.99028 3.41717 7.41563 3.41591 6.84586 3.47622C6.49015 3.51127 6.13921 3.58443 5.79906 3.6943C5.01452 2.80647 4.4914 1.71854 4.28778 0.551382Z" />
				<path d="M0.859924 10.2881H1.72932C1.7483 10.8618 1.79967 11.4341 1.88312 12.002H0.859924C0.386611 12.002 0.00292969 11.6183 0.00292969 11.145C0.00915616 10.6744 0.389222 10.2943 0.859924 10.2881Z" />
				<path d="M0.00292969 15.8584V12.6289C0.263186 12.78 0.558944 12.8595 0.859924 12.859H1.71692V13.716C1.71692 13.9526 1.90878 14.1445 2.14539 14.1445C2.382 14.1445 2.57386 13.9526 2.57386 13.716V12.859H3.85933V14.5729C3.85933 14.8096 4.05119 15.0014 4.2878 15.0014C4.52446 15.0014 4.71627 14.8096 4.71627 14.5729V12.859H6.00174V13.716C6.00174 13.9526 6.1936 14.1445 6.43021 14.1445C6.66686 14.1445 6.85868 13.9526 6.85868 13.716V12.859H8.14415V14.5729C8.14415 14.8096 8.33601 15.0014 8.57262 15.0014C8.80927 15.0014 9.00109 14.8096 9.00109 14.5729V12.859H10.2866V13.716C10.2866 13.9526 10.4784 14.1445 10.715 14.1445C10.9517 14.1445 11.1435 13.9526 11.1435 13.716V12.859H12.429V14.5729C12.429 14.8096 12.6208 15.0014 12.8574 15.0014C13.094 15.0014 13.2859 14.8096 13.2859 14.5729V12.859H14.9998V16.7154H0.859723C0.386611 16.7154 0.00292969 16.3317 0.00292969 15.8584Z" />
				<path d="M17.1419 12.8593C17.3786 12.8593 17.5704 13.0511 17.5704 13.2878V16.2872C17.5704 16.5238 17.3786 16.7157 17.1419 16.7157H15.8564V12.8593H17.1419V12.8593Z" />
				<path d="M17.1418 17.5723C17.8518 17.5723 18.4273 16.9967 18.4273 16.2868V13.2874C18.4273 12.5774 17.8518 12.0019 17.1418 12.0019H2.74979C2.63204 11.2492 2.57304 10.4885 2.57324 9.72665C2.57324 7.28426 4.20152 4.59849 6.93142 4.32985C8.19816 4.15626 9.48669 4.40793 10.595 5.04544C11.4576 5.57274 12.5428 5.57274 13.4055 5.04544C14.5145 4.40899 15.8031 4.15812 17.0699 4.33201C19.7985 4.59854 21.4268 7.28471 21.4268 9.7267C21.4268 14.4127 19.3846 17.7146 17.4337 19.2863H6.57019C5.95768 18.7892 5.41402 18.2128 4.95351 17.5724H17.1418V17.5723Z" />
				<path d="M5.57324 23.5714V20.572C5.57324 20.3354 5.76506 20.1436 6.00171 20.1436H7.28718V24H6.00171C5.76511 24 5.57324 23.8081 5.57324 23.5714Z" />
				<path d="M23.9977 23.1426C23.9977 23.6159 23.614 23.9996 23.1407 23.9996H8.14355V20.1432H9.85749V21.8571C9.85749 22.0938 10.0494 22.2856 10.286 22.2856C10.5226 22.2856 10.7144 22.0937 10.7144 21.8571V20.1432H11.9999V21.0002C11.9999 21.2368 12.1918 21.4286 12.4284 21.4286C12.665 21.4286 12.8568 21.2368 12.8568 21.0002V20.1432H14.1423V21.8571C14.1423 22.0938 14.3341 22.2856 14.5708 22.2856C14.8074 22.2856 14.9993 22.0937 14.9993 21.8571V20.1432H16.2847V21.0002C16.2847 21.2368 16.4766 21.4286 16.7132 21.4286C16.9499 21.4286 17.1417 21.2368 17.1417 21.0002V20.1432H18.4271V21.8571C18.4271 22.0938 18.6189 22.2856 18.8556 22.2856C19.0923 22.2856 19.2841 22.0937 19.2841 21.8571V20.1432H20.5695V21.0002C20.5695 21.2368 20.7614 21.4286 20.998 21.4286C21.2347 21.4286 21.4265 21.2368 21.4265 21.0002V20.1432H23.1404C23.4414 20.1436 23.7371 20.0642 23.9974 19.9131V23.1426H23.9977Z" />
				<path d="M23.1408 19.2862H18.7119C19.2306 18.7645 19.6933 18.1901 20.0925 17.5723H23.1407C23.609 17.5842 23.9858 17.961 23.9977 18.4293C23.9978 18.9025 23.6141 19.2862 23.1408 19.2862Z" />
			</g>
		</SvgIcon>
	)
}

export default DietIcon;