import {
	Box
} from '@mui/material';

import Logo from './Logo';
import Carousel from './Carousel';

const style = {
	wrap: {
		display: 'flex',
		alignItems: 'stretch',
		justifyContent: {
			sm: 'flex-start',
			md: 'flex-end',
		},
		flexDirection: {
			xs: "column",
			md: "row-reverse",
		},
		width: '100%',
		height: {
			xs: 'auto',
			md: '100vh',
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		pt: {
			xs: 16,
			lg: 0,
		},
		pb: {
			xs: 8,
			lg: 0,
		},
		flexBasis: {
			md: "60%",
		},
		justifyContent: 'center',
		alignItems: 'center',
	},
	contentBlock: {
		pl: 4, pr: 4,
		maxWidth: {
			xs: 420,
			lg: 482,
		},
		flexBasis: {
			xs: "100%",
		},
	},
	carouselWrap: {
		width: {
			xs: "100%",
			md: "40%",
		},
	},
};

function AuthWrap(props: any) {
	return (

		<Box sx={style.wrap}>
      		<Logo />
			<Box sx={style.content}>
				<Box sx={style.contentBlock}>
					{props.children}
				</Box>
			</Box>
			<Box sx={style.carouselWrap}>
				<Carousel />
			</Box>
		</Box>

	)
}

export default AuthWrap;