import PageWrap from '../../../components/shared/PageWrap';
import { Outlet } from "react-router-dom"
import Footer from '../../../components/shared/Footer'
function Homepage() {
	
	return (
		<>
		<PageWrap>
			<Outlet/>
		</PageWrap>
		<Footer/>
		</>
	)
}

export default Homepage;