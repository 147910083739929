import {
	Box,
} from '@mui/material';

import { COLORS } from '../../enums/layout-enums';

export default function Tag(props: any) {

	const style = {
		tag: {
			background: "#EBF3FD",
			color: COLORS.BLUE_BRAND_PRIMARY,
			display: 'inline-block',
			padding: '2px 12px',
			fontSize: '0.875rem',
			borderRadius: '4px',
		}
	}

	return (

		<Box sx={style.tag}>
			{props.children}
		</Box>

	)
}