// import React from "react";
// import { Container, Button, Typography } from "@mui/material";
// import { Link } from 'react-router-dom';

// const footerStyle: React.CSSProperties = {
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   backgroundColor: "#f5f5f5",
//   padding: "16px",
//   marginTop: "auto",
// };

// const logoStyle: React.CSSProperties = {
//   marginRight: "8px",
//   height: "30px",
// };

// const Footer: React.FC = () => {
//   return (
//     <footer style={footerStyle}>
//       <Container maxWidth="lg" style={{ display: "flex", alignItems: "center" }}>
//              {/* <img src="aaaa" alt="Logo" style={logoStyle} /> */}
//              <Link to={`/privacy`}  target="_blank" className="titleText">
//              <Typography variant="body2" color="textSecondary">Privacy Policy</Typography>
//              </Link>
//         <Typography variant="body2" color="textSecondary" style={{ marginLeft: "auto" }}>
//          &copy;{new Date().getFullYear()} Tern. All rights reserved.
//          </Typography>
//       </Container>
//     </footer>
//   );
// };

// export default Footer;


// anothor component in th center

import React from "react";
import { Container, Typography } from "@mui/material";
import { Link } from 'react-router-dom';

const footerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  padding: "16px",
  marginTop: "auto",
  bottom: 0,
// position: "fixed",
// bottom: 0,
// width: "100%",
// backgroundColor: "#f5f5f5",
// display: "flex",
// justifyContent: "space-between",
// alignItems: "center",
// padding: "10px"
};
const containerStyle: React.CSSProperties = {
    paddingTop: "0px", // assuming the footer is 64px in height
  };
const Footer: React.FC = () => {
  return (
    <div style={containerStyle}>
        <footer style={footerStyle}>
        <Container maxWidth="lg">
            <Typography variant="body2" color="textSecondary" align="center" display="flex" justifyContent="center" alignItems="center">
            &copy; Copyright {new Date().getFullYear()} 
            <span style={{ marginLeft: "4px", marginRight: "4px" }}>|</span>
            <Link to={`/privacy`} className="titleText">
                <Typography variant="body2" color="textSecondary">
                Privacy Policy
                </Typography>
            </Link>
            </Typography>
        </Container>
        </footer>
    </div>
  );
};

export default Footer;