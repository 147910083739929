import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  TextField,
  InputLabel,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  CCarousel,
  CCarouselItem,
  CCarouselCaption,
} from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import Logo from "../../../components/shared/Logo";
import { useDispatch } from "react-redux";
import { IRootState } from "../../../interfaces/store";
import { useSelector } from "../../../store";
import { navigatorLogin } from "../../../store/actions/authActions";
import { useSnackbar } from "notistack";
import { useNavigate  } from "react-router";

interface ILoginProps {}

const Login: React.FC<ILoginProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user = useSelector(
    (state: IRootState) => state.main.my_user
  );
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = () => {
    setLoading(true);
    dispatch(
      navigatorLogin({ email }, () => {
          enqueueSnackbar("Login Success", { variant: "success" });
          setLoading(false);
          history('/Search');
        },
        () => {
          enqueueSnackbar("I’m sorry you are not approved for use at this time.  Please contact an NCHIP administrator for access", {
            variant: "error",
          });
          setLoading(false);
		    }
      )
    );
  };

  const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter'){
			// if key is Enter prevent default action of page realoads
			e.preventDefault();
			// Call the login function
			handleLogin();
		}
	}

  return (

    <> 
      <div className="login-page__content">
        <div className="login-page__content-block">
          <Typography variant="h2" gutterBottom>
            Log in
          </Typography>

          <Typography variant="body2" style={{ marginBottom: 32, marginTop: 40 }}>
            Enter your work email address to be validated for use.
          </Typography>
          
          <div>
            <InputLabel>
              Email
              <div style={{ marginTop: 6 }}>
                <TextField
                  placeholder="Enter your email"
                  size="small"
                  fullWidth={true}
                  id="3_citation_no"
                  variant="outlined"
                  value={email}
                  onChange={(event) => setEmail(event?.target.value)}
                  onKeyDown={keyPress}
                />
              </div>
            </InputLabel>
          </div>
          <div style={{ marginTop: 32 }}>
            <Button disabled={loading} variant='contained' onClick={() => handleLogin()}>
              {loading && <CircularProgress color='inherit' size={18} sx={{marginRight:'5px'}} />}
              Log in
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;