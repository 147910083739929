import { Typography } from "@mui/material";
import { FC } from "react";

const Counter: FC<any> = ({ value=0, description='', color, separator=false, separatorHidden=false }) => {

    return (
        <div style={{display:'flex'}}>
            <div style={{ flex:1, padding:'0 10px' }}>
                <Typography textAlign={"center"} lineHeight={1} color={color} sx={{ fontSize: '80px', fontWeight:'bold' }}>{value}</Typography>
                <Typography textAlign={"center"} textTransform={'uppercase'} color="gray">{description}</Typography>
            </div>
            {separator && !separatorHidden && <div style={{ 
                width:'2px', 
                backgroundColor:'gray', 
                transform: 'rotate(10deg)',
            }}></div>}
        </div>
    )
}

export default Counter;