import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function BabyIcon(props: any) {

	return (
		<svg
		width={44}
		height={44}
		viewBox="0 0 44 44"
		fill={props?.color ?? "#3683EF"}
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	  >
		<rect width={44} height={44} rx={6} fill={props?.color ?? "#3683EF"} fillOpacity={0.1} />
		<g clipPath="url(#clip0_489_17139)">
		  <path
			d="M33.6868 23.1976C32.9372 22.6896 32.054 22.8335 31.2718 23.285C30.4897 23.7365 25.9991 27.576 25.9991 27.576L21.5787 27.5769C21.3731 27.5769 21.1503 27.3456 21.1503 27.1408C21.1503 26.9001 21.3602 26.7125 21.5787 26.7125H24.2832C25.2298 26.7125 26.2895 26.0957 26.2895 24.9992C26.2895 23.8341 25.2298 23.2859 24.2832 23.2859C22.6409 23.2859 22.3214 23.2927 22.3214 23.2927C21.6635 23.2927 20.9961 23.2533 20.4822 22.9783C19.7968 22.6271 19.007 22.4283 18.164 22.4283C16.7488 22.4283 15.4818 22.9929 14.6131 23.8829L10 28.425L15.14 33.565L16.8533 31.8516H25.5288C26.3923 31.8516 27.2258 31.5347 27.8709 30.9616L33.745 24.3721C34.104 24.0534 34.0834 23.4675 33.6868 23.1976Z"
			fill={props?.color ?? "#3683EF"}
		  />
		  <path
			d="M20.2792 12.6747C20.9948 12.6747 21.5749 12.0946 21.5749 11.379C21.5749 10.6634 20.9948 10.0833 20.2792 10.0833C19.5636 10.0833 18.9835 10.6634 18.9835 11.379C18.9835 12.0946 19.5636 12.6747 20.2792 12.6747Z"
			fill={props?.color ?? "#3683EF"}
		  />
		  <path
			d="M23.631 16.0395C23.631 16.085 23.6267 16.1305 23.6159 16.176C23.6007 16.2432 23.5747 16.3038 23.5379 16.358C23.4902 16.4338 23.423 16.4967 23.345 16.5422C23.2714 16.5855 23.1912 16.6115 23.111 16.6202H23.1024C23.0937 16.6202 23.085 16.6202 23.0764 16.6223C23.072 16.6223 23.0655 16.6223 23.0612 16.6245C22.8922 16.6267 22.7254 16.5573 22.6084 16.4208L22.6062 16.4187L22.3462 16.1132L22.4654 17.0318L22.5759 17.8855C22.5845 17.9483 22.565 18.0112 22.5239 18.0567C22.5065 18.0762 22.487 18.0913 22.4654 18.1022C22.435 18.1217 22.3982 18.1303 22.3614 18.1303H21.8825L21.7504 20.3252C21.7309 20.6567 21.4557 20.9167 21.122 20.9167C20.7905 20.9167 20.5132 20.6567 20.4937 20.3252L20.3637 18.1303H20.1969L20.0647 20.3252C20.0452 20.6567 19.7679 20.9167 19.4364 20.9167C19.1049 20.9167 18.8275 20.6567 18.808 20.3252L18.6759 18.1303H18.197C18.1342 18.1303 18.0757 18.1043 18.0345 18.0567C17.9934 18.0112 17.9739 17.9483 17.9825 17.8855L18.2122 16.1132L17.9522 16.4187C17.768 16.6353 17.4582 16.6873 17.2134 16.5422C17.0769 16.462 16.9794 16.332 16.9425 16.176C16.9057 16.0222 16.9317 15.864 17.0184 15.7297L18.4635 13.4525C18.6802 13.1102 19.0507 12.9065 19.4559 12.9065H21.1025C21.5077 12.9065 21.8782 13.1102 22.097 13.4525L23.54 15.7297C23.6007 15.825 23.631 15.9312 23.631 16.0395Z"
			fill={props?.color ?? "#3683EF"}
		  />
		  <path
			d="M23.0612 16.6245C22.968 16.644 22.8987 16.7242 22.8987 16.8238V20.7C22.8987 20.8192 22.8012 20.9167 22.682 20.9167C22.5628 20.9167 22.4653 20.8192 22.4653 20.7V18.1022C22.487 18.0913 22.5065 18.0762 22.5238 18.0567C22.565 18.0112 22.5845 17.9483 22.5758 17.8855L22.4653 17.0318V16.8238C22.4653 16.6722 22.5195 16.5313 22.6083 16.4208C22.7253 16.5573 22.8922 16.6267 23.0612 16.6245Z"
			fill={props?.color ?? "#3683EF"}
		  />
		  <path
			d="M23.7393 16.8238V17.2182C23.7393 17.3395 23.644 17.4348 23.5226 17.4348C23.4035 17.4348 23.306 17.3395 23.306 17.2182V16.8238C23.306 16.7133 23.2193 16.6245 23.111 16.6202C23.1911 16.6115 23.2713 16.5855 23.345 16.5422C23.423 16.4967 23.4901 16.4338 23.5378 16.358C23.6613 16.475 23.7393 16.6418 23.7393 16.8238Z"
			fill={props?.color ?? "#3683EF"}
		  />
		  <path
			d="M26.8563 16.3299C27.3735 16.3299 27.7847 15.9188 27.7847 15.4015C27.7847 14.8842 27.3735 14.4731 26.8563 14.4731C26.339 14.4731 25.9279 14.8842 25.9279 15.4015C25.9279 15.9188 26.339 16.3299 26.8563 16.3299Z"
			fill={props?.color ?? "#3683EF"}
		  />
		  <path
			d="M28.3155 17.0859C28.2093 16.887 28.0369 16.7411 27.838 16.6615C27.7053 16.6085 27.5727 16.5819 27.4268 16.5952C27.2013 16.5952 26.7239 16.5952 26.5117 16.5687C26.4984 16.5687 25.8087 16.5687 25.4904 15.9586C25.4108 15.7994 25.2517 15.7198 25.0792 15.7463C24.8803 15.7861 24.7609 15.9851 24.8007 16.184C24.8007 16.2106 24.814 16.2371 24.8272 16.2636C25.0262 16.7278 25.4241 17.0727 25.9148 17.2053V20.4946C25.9148 20.6935 26.0475 20.8659 26.2331 20.9057C26.4453 20.9588 26.6576 20.8129 26.7106 20.6007C26.7239 20.5741 26.7239 20.5343 26.7239 20.5078V18.7173H26.9626V20.4946C26.9626 20.72 27.1483 20.8925 27.3605 20.8925C27.5727 20.8925 27.7584 20.7068 27.7584 20.4946V17.5236C27.7717 17.4706 27.8247 17.4838 27.8512 17.5634C27.9176 17.7358 27.9706 17.895 27.9839 18.399C27.9971 18.5581 28.1298 18.6908 28.2889 18.6908H28.3022C28.4746 18.6775 28.6072 18.5316 28.594 18.3592C28.594 17.895 28.594 17.5767 28.3155 17.0859Z"
			fill={props?.color ?? "#3683EF"}
		  />
		  <circle cx={24.7938} cy={14.6079} r={0.607895} fill={props?.color ?? "#3683EF"} />
		</g>
		<defs>
		  <clipPath id="clip0_489_17139">
			<rect
			  width={24}
			  height={24}
			  fill="white"
			  transform="translate(10 10)"
			/>
		  </clipPath>
		</defs>
	  </svg>
		// <SvgIcon {...props}>
		// 	<g clipPath="url(#clip1_158_19709)">
		// 		<path d="M19.2858 16.7143L19.2859 10.2682C18.3918 11.5745 16.9081 12.3819 15.3709 13.2181C12.4497 14.8068 9.42871 16.4495 9.42871 21.8572V23.5192C11.6649 24.3763 14.18 24.0753 16.1508 22.7148C18.1216 21.3542 19.2946 19.1091 19.2858 16.7143V16.7143Z" />
		// 		<path d="M20.5712 5.85685C20.821 5.637 21.0619 5.42828 21.2916 5.23026C22.9802 3.76796 23.9997 2.88556 23.9997 1.71855C23.9997 0.738838 23.3569 0 22.4998 0C21.8445 0.0302086 21.2558 0.409167 20.9569 0.992988C20.8848 1.14014 20.7351 1.23331 20.5712 1.23299C20.4072 1.23323 20.2574 1.1399 20.1855 0.992551C19.8865 0.408889 19.2977 0.0301291 18.6426 0C17.7876 0 17.1426 0.738838 17.1426 1.71855C17.1426 2.88556 18.1622 3.76796 19.8507 5.23026C20.0804 5.42916 20.3213 5.637 20.5712 5.85685Z" />
		// 		<path d="M9.03903 12.4642C10.0718 12.9949 11.0598 13.6086 11.9932 14.2994C12.9321 13.6096 13.9247 12.9961 14.9614 12.4647C15.2357 12.3155 15.5053 12.1689 15.768 12.0215C15.5385 11.7983 15.2771 11.6105 14.9923 11.4644C13.2046 12.75 10.7954 12.75 9.00771 11.4644C8.72283 11.6102 8.46153 11.798 8.23242 12.0215C8.49516 12.1681 8.76473 12.3151 9.03903 12.4642V12.4642Z" />
		// 		<path d="M19.1138 5.72618C17.4047 4.24289 16.2853 3.2259 16.2853 1.71861C16.2853 1.61574 16.2921 1.51462 16.302 1.41517C15.0576 0.492658 13.5486 -0.00363676 11.9996 2.00641e-05C7.97767 0.0045116 4.71836 3.26382 4.71387 7.28573C4.71387 9.44831 5.88815 10.5939 7.47859 11.5779C7.72523 11.3047 8.00867 11.0671 8.32073 10.872C7.79761 10.3394 7.39774 9.69848 7.14943 8.99443C6.25912 8.92248 5.57326 8.17895 5.57326 7.28573C5.57326 6.39251 6.25912 5.64899 7.14943 5.57704C7.8744 3.51956 9.81852 2.14328 12 2.14328C14.1815 2.14328 16.1256 3.51956 16.8505 5.57704C17.7408 5.64899 18.4267 6.39251 18.4267 7.28573C18.4267 8.17895 17.7408 8.92248 16.8505 8.99443C16.6027 9.69733 16.2037 10.3373 15.6818 10.8694C15.9944 11.0651 16.2786 11.3027 16.5265 11.5757C18.1131 10.5921 19.2853 9.44656 19.2853 7.28573C19.2845 6.76122 19.227 6.23833 19.1138 5.72618Z" />
		// 		<path d="M11.5711 3.8572C11.8078 3.8572 11.9997 4.04906 11.9997 4.28576C11.9997 4.52246 12.1916 4.71433 12.4283 4.71433C12.665 4.71433 12.8568 4.52246 12.8568 4.28576C12.8315 3.71045 12.4654 3.20573 11.9264 3.00305C10.034 3.03676 8.38743 4.30683 7.87428 6.12861C7.81525 6.3341 7.61488 6.46563 7.40287 6.43805C7.07089 6.39074 6.74186 6.54135 6.56068 6.82352C6.37951 7.10569 6.37951 7.46756 6.56068 7.74973C6.74186 8.03191 7.07089 8.18251 7.40287 8.13521C7.61651 8.10401 7.81998 8.23661 7.87774 8.44465C8.39784 10.2926 10.0834 11.5693 12.0032 11.5693C13.9229 11.5693 15.6085 10.2926 16.1286 8.44465C16.1878 8.23768 16.3902 8.1058 16.6034 8.13521C16.9354 8.18251 17.2645 8.03191 17.4456 7.74973C17.6268 7.46756 17.6268 7.10569 17.4456 6.82352C17.2645 6.54135 16.9354 6.39074 16.6034 6.43805C16.3915 6.46543 16.1912 6.33402 16.132 6.12861C15.7567 4.79545 14.7602 3.72583 13.4569 3.2572C13.6297 3.57221 13.7183 3.92648 13.714 4.28576C13.714 4.99582 13.1384 5.57146 12.4283 5.57146C11.7183 5.57146 11.1426 4.99582 11.1426 4.28576C11.1425 4.04906 11.3344 3.8572 11.5711 3.8572V3.8572Z" />
		// 		<path d="M8.57196 23.1309V21.8572C8.57196 18.3798 9.74751 16.3132 11.3225 14.8655C10.4655 14.2524 9.56563 13.7015 8.62984 13.2172C7.09254 12.3811 5.60886 11.5741 4.71484 10.2673V16.7143C4.71484 16.8858 4.72172 17.0572 4.73285 17.2226C4.93688 17.1695 5.14687 17.1427 5.35769 17.1429C6.01385 17.1661 6.63317 17.4521 7.07644 17.9365C7.51971 18.4208 7.74973 19.063 7.71483 19.7186C7.6952 20.5102 7.38667 21.2672 6.84741 21.8469C7.35539 22.358 7.93659 22.7908 8.57196 23.1309V23.1309Z" />
		// 		<path d="M2.7082 23.2285C2.9379 23.4274 3.17961 23.6353 3.42863 23.8551C3.67849 23.6353 3.91936 23.4265 4.14907 23.2285C5.83765 21.768 6.85719 20.8856 6.85719 19.7185C6.85719 18.7388 6.21434 18 5.35717 18C4.70197 18.0302 4.11322 18.4092 3.81431 18.993C3.74221 19.1401 3.59248 19.2333 3.42859 19.233C3.26539 19.2324 3.11669 19.1392 3.04502 18.9925C2.74564 18.4082 2.1559 18.0294 1.50001 18C0.645033 18 0 18.7388 0 19.7185C7.94963e-05 20.8856 1.01962 21.768 2.7082 23.2285V23.2285Z" />
		// 	</g>
		// </SvgIcon>
	)
}

export default BabyIcon;