import React, { useState } from 'react';
import {
	Box,
	Grid,
	Card,
	CardContent,
	CardHeader,
	Typography,
	Button,
	IconButton,
	InputLabel,
	InputBase,
	TextField,
	FormControl,
	FormLabel,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio,
	Select,
	MenuItem,
	ListItemText,
	OutlinedInput,
	Modal,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

import { useSelector } from 'react-redux';
import { IRootState } from '../../interfaces/store';

import theme from '../../themes/theme';

import DietIcon from '../../components/icons/DietIcon';
import HomeIcon from '../../components/icons/HomeIcon';
import EditIcon from '../../components/icons/EditIcon';
import MoneyIcon from '../../components/icons/MoneyIcon';
import HouseIcon from '../../components/icons/HouseIcon';
import TruckIcon from '../../components/icons/TruckIcon';
import MentalHealthIcon from '../../components/icons/MentalHealthIcon';
import GraduationIcon from '../../components/icons/GraduationIcon';
import BabyIcon from '../../components/icons/BabyIcon';
import StopViolenceIcon from '../../components/icons/StopViolenceIcon';
import StarIcon from '../../components/icons/StarIcon';
import MoreHorizontalIcon from '../../components/icons/MoreHorizontalIcon';
import MoreVerticalIcon from '../../components/icons/MoreVerticalIcon';
import InboxIcon from '../../components/icons/InboxIcon';
import PlusIcon from '../../components/icons/PlusIcon';
import Edit2Icon from '../../components/icons/Edit2Icon';
import CheckIcon from '../../components/icons/CheckIcon';
import CopyIcon from '../../components/icons/CopyIcon';
import ConversationIcon from '../../components/icons/ConversationIcon';
import FolderIcon from '../../components/icons/FolderIcon';
import EmailIcon from '../../components/icons/EmailIcon';
import FirstAidKitIcon from '../../components/icons/FirstAidKitIcon';
import PhoneIcon from '../../components/icons/PhoneIcon';
import VirusIcon from '../../components/icons/VirusIcon';
import InternetIcon from '../../components/icons/InternetIcon';
import ClockIcon from '../../components/icons/ClockIcon';
import MapIcon from '../../components/icons/MapIcon';
import UserIcon from '../../components/icons/UserIcon';

import Header from '../../components/shared/Header';
import SearchBar from '../../components/resources/SearchBar';
import ResourceCard from '../../components/shared/resource/ResourceCard';
import PageWrap from '../../components/shared/PageWrap';


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function KitchenSink() {
	const [checkbox, setCheckbox] = useState(true);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

	// const handleOpenModal = () => {
	// 	alert('open!');
	// }

	const email = useSelector((state: IRootState) => state.main.my_user?.email);

	return (

		<PageWrap>
			<Grid 
				container
				columnSpacing={4}
			>
				<Grid item xs={12} md={8}>
					<Box sx={{ pt: 4, }}>
						<Typography variant="h1">H1 Header</Typography>
						<Typography variant="h2">H2 Header</Typography>
						<Typography variant="h3">H3 Header</Typography>
						<Typography variant="h4">H4 Header</Typography>
						<Typography variant="h5">H5 Header</Typography>
						<Typography variant="h6">H6 Header</Typography>
						<Typography variant="body1" sx={{ mb: 4 }}><strong>Body 1:</strong> Lorem ipsum dolor sit amet consectetur, adipisicing, elit. Fuga mollitia tempora maiores, aperiam alias. Omnis, aliquid. Pariatur, impedit voluptas aspernatur odit recusandae, nemo. Cupiditate, molestiae autem labore explicabo perspiciatis, nisi iste quos.</Typography>
						<Typography variant="body2" sx={{ mb: 4 }}><strong>Body 2:</strong> Lorem ipsum dolor sit amet consectetur, adipisicing, elit. Fuga mollitia tempora maiores, aperiam alias. Omnis, aliquid. Pariatur, impedit voluptas aspernatur odit recusandae, nemo. Cupiditate, molestiae autem labore explicabo perspiciatis, nisi iste quos.</Typography>
						
						<Typography variant="h3">Button Styles</Typography>

						<Typography variant="h4">Primary</Typography>
						<Button>Click Me</Button>

						<Typography variant="h4">Primary Disabled</Typography>
						<Button disabled>Click Me</Button>

						<Typography variant="h4">Secondary</Typography>
						<Button variant="secondary">Click Me</Button>

						<Typography variant="h4">Secondary Disabled</Typography>
						<Button variant="secondary" disabled>Click Me</Button>

						<Typography variant="h4">Secondary with Icon</Typography>
						<Button variant="secondary">
							<AccessAlarmIcon sx={{ mr: '10px' }} />
							Click Me
						</Button>

						<Typography variant="h4">Secondary with Icon Disabled</Typography>
						<Button variant="secondary" disabled>
							<AccessAlarmIcon sx={{ mr: '10px' }} />
							Click Me
						</Button>

						<Typography variant="h4">Outline Gradient</Typography>
						<Button variant="outlinedGradient">
							<Typography variant="gradient">Click Me</Typography>
						</Button>

						<Typography variant="h4">Outline Gradient Disabled</Typography>
						<Button variant="outlinedGradient" disabled>
							<Typography variant="gradient">Click Me</Typography>
						</Button>

						<Typography variant="h4">Outline</Typography>
						<Button variant="outlined">Click Me</Button>

						<Typography variant="h4">Outline Disabled</Typography>
						<Button variant="outlined" disabled>Click Me</Button>


						<Box sx={{ mb: 4 }}>
							<Typography variant="h3">Icons</Typography>
							<DietIcon color="primary" sx={{ mr: 2 }}/>
							<HomeIcon color="primary" sx={{ mr: 2 }}/>
							<EditIcon color="primary" sx={{ mr: 2 }}/>
							<MoneyIcon color="primary" sx={{ mr: 2 }}/>
							<HouseIcon color="primary" sx={{ mr: 2 }}/>
							<TruckIcon color="primary" sx={{ mr: 2 }}/>
							<MentalHealthIcon sx={{ mr: 2 }}/>
							<GraduationIcon color="primary" sx={{ mr: 2 }}/>
							<BabyIcon color="primary" sx={{ mr: 2 }}/>
							<StopViolenceIcon color="primary" sx={{ mr: 2 }}/>
							<StarIcon color="primary" sx={{ mr: 2 }}/>
							<MoreHorizontalIcon color="primary" sx={{ mr: 2 }}/>
							<MoreVerticalIcon color="primary" sx={{ mr: 2 }}/>
							<InboxIcon color="primary" sx={{ mr: 2 }}/>
							<PlusIcon color="primary" sx={{ mr: 2 }}/>
							<Edit2Icon color="primary" sx={{ mr: 2 }}/>
							<CheckIcon color="primary" sx={{ mr: 2 }}/>
							<CopyIcon color="primary" sx={{ mr: 2 }}/>
							<ConversationIcon color="primary" sx={{ mr: 2 }}/>
							<FolderIcon color="primary" sx={{ mr: 2 }}/>
							<EmailIcon color="primary" sx={{ mr: 2 }}/>
							<FirstAidKitIcon color="primary" sx={{ mr: 2 }}/>
							<PhoneIcon color="primary" sx={{ mr: 2 }}/>
							<VirusIcon color="primary" sx={{ mr: 2 }}/>
							<InternetIcon color="primary" sx={{ mr: 2 }}/>
							<ClockIcon color="primary" sx={{ mr: 2 }}/>
							<MapIcon color="primary" sx={{ mr: 2 }}/>
							<UserIcon color="primary" sx={{ mr: 2 }}/>
						</Box>

						<Box sx={{ mb: 4 }}>
							<Typography variant="h3">Text Input</Typography>
							<InputLabel>
								Field Label
								<Box sx={{ width: 320 }}>
									<TextField fullWidth size="small" placeholder="Enter Text" />
								</Box>
							</InputLabel>
						</Box>

						<Box sx={{ mb: 4 }}>
							<Typography variant="h3">Search Bar</Typography>
							<SearchBar />
						</Box>

						<Box sx={{ mb: 4 }}>
							<Typography variant="h3">Card</Typography>
							<ResourceCard
								color={theme.palette.violet.main}
								icon="truck"
								title="Lorem Ipsum dolor sit amet consectetur adipisicing elit"
								type="Lorem"
								phase="Ipsum"
								agency="Lorem ipsum"
								language="Spanish"
								text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat similique illo expedita! Consectetur odit iusto aperiam odio veritatis quisquam enim ad atque reiciendis, hic consequuntur iure. Qui voluptatibus doloremque maiores, molestias inventore a nulla temporibus assumenda. Quos, error, quo cumque earum nesciunt eveniet?"
							/>
						</Box>

						<Box sx={{ mb: 4 }}>
							<Typography variant="h3">Checkbox</Typography>
							<FormControlLabel
								label="Checkbox"
								control={
									<Checkbox
										checked={checkbox}
										name="gilad"
										onChange={(e) => setCheckbox(e?.target.checked)}
									/>
								}
							/>
							<FormControlLabel
								label="Checkbox Disabled"
								disabled
								control={
									<Checkbox checked={true} name="gilad" />
								}
							/>
						</Box>

						<Box sx={{ mb: 4 }}>
							<Typography variant="h3">Modal</Typography>
							<Button variant="secondary" onClick={() => handleOpen()}>Open Modal</Button>

							<Modal
								open={open}
								onClose={handleClose}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
								// sx={}
							>
								<Card sx={{
									maxWidth: '400px',
								}}>
									<CardHeader title="Card Title" />
									<CardContent>
										<Typography>
											Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
										</Typography>
									</CardContent>
								</Card>
							</Modal>
						</Box>

						<Box sx={{ mb: 4 }}>
							<Typography variant="h3">Dropdowns</Typography>
							<FormControl>
								<InputLabel id="demo-multiple-checkbox-label">Dropdown</InputLabel>
								
								
								<Select
									size="small"
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value=""
									sx={{ width: '300px', }}
								>
									<MenuItem sx={{ fontSize: '0.875rem', }} value={10}>Category 1</MenuItem>
									<MenuItem sx={{ fontSize: '0.875rem', }} value={20}>Category 2</MenuItem>
									<MenuItem sx={{ fontSize: '0.875rem', }} value={30}>Category 3</MenuItem>
								</Select>
							</FormControl>
						</Box>

					</Box>
				</Grid>
			</Grid>
		</PageWrap>
	)
}

export default KitchenSink;