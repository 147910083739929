import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function HomeIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<g clipPath="url(#clip1_158_19711)">
				<path d="M0.559371 4.71384H5.70175C5.93843 4.71384 6.13026 4.52195 6.13026 4.28532C6.13026 4.04864 5.93838 3.85681 5.70175 3.85681H0.559371C0.322693 3.85681 0.130859 4.04869 0.130859 4.28532C0.130859 4.52195 0.322693 4.71384 0.559371 4.71384Z" />
				<path d="M0.988281 0.428512V2.99973H1.84535V0.428512C1.84535 0.191881 1.65352 0 1.41684 0C1.18016 0 0.988281 0.191881 0.988281 0.428512Z" />
				<path d="M20.7006 7.28503H19.8436C19.6069 7.28503 19.415 7.47692 19.415 7.71355V8.69919L21.1292 10.0323V7.71355C21.1292 7.47687 20.9373 7.28503 20.7006 7.28503Z" />
				<path d="M4.41602 0.428512V2.99973H5.27309V0.428512C5.27309 0.191833 5.08121 0 4.84458 0C4.60794 0 4.41602 0.191881 4.41602 0.428512Z" />
				<path d="M3.13098 9.85623C4.31376 9.8548 5.27225 8.89635 5.27364 7.71358V5.57092H0.988281V7.71358C0.989712 8.89635 1.94821 9.85485 3.13098 9.85623Z" />
				<path d="M6.98746 22.2838H7.41597V21.4267H6.98746C5.09494 21.4246 3.56132 19.8909 3.55917 17.9984V10.6791C3.41734 10.7007 3.27412 10.7121 3.13066 10.7134C2.9872 10.7122 2.84398 10.7007 2.70215 10.6791V17.9984C2.70472 20.3641 4.62182 22.2811 6.98746 22.2838Z" />
				<path d="M21.9865 14.3066L15.13 8.974L8.27344 14.3066V23.7772C8.27344 23.9003 8.37319 24.0001 8.49628 24.0001H21.7637C21.8868 24.0001 21.9865 23.9003 21.9865 23.7772V14.3066ZM17.9339 18.3713L14.5913 22.0464C14.382 22.2795 14.0453 22.3489 13.7608 22.2178C13.4701 22.0845 13.2997 21.7779 13.34 21.4606L13.7587 17.9985H12.8549C12.5675 17.9998 12.3072 17.829 12.1941 17.5648C12.0757 17.2974 12.126 16.9852 12.3227 16.7686L15.6652 13.0935C15.8748 12.8607 16.2115 12.7912 16.4962 12.9221C16.7868 13.0554 16.9571 13.3622 16.9166 13.6793L16.4987 17.1414H17.4012C17.6887 17.14 17.949 17.3108 18.062 17.5751C18.1808 17.8424 18.1305 18.1547 17.9339 18.3713Z" />
				<path d="M15.1297 8.0024C15.225 8.00245 15.3176 8.03427 15.3928 8.09281L22.9479 13.9701C23.1072 14.0985 23.3233 14.1309 23.5132 14.055C23.7031 13.979 23.8373 13.8065 23.8642 13.6038C23.8911 13.401 23.8065 13.1995 23.6429 13.0767L15.4772 6.72412C15.2728 6.56511 14.9866 6.56511 14.7821 6.72412L6.61646 13.0754C6.36938 13.2673 6.32468 13.6232 6.51661 13.8703C6.70853 14.1174 7.06444 14.1621 7.31151 13.9702L14.8666 8.09414C14.9416 8.03508 15.0342 8.00283 15.1297 8.0024Z" />
				<path d="M15.694 17.854C15.6126 17.7621 15.5746 17.6398 15.5894 17.518L16.0179 13.9805L13.1416 17.1414H14.2412C14.3639 17.1414 14.4806 17.1941 14.5619 17.2859C14.6432 17.3777 14.6812 17.5 14.6663 17.6218L14.2378 21.1593L17.1141 17.9984H16.0145C15.892 17.9983 15.7753 17.9458 15.694 17.854Z" />
			</g>
		</SvgIcon>
	)
}

export default HomeIcon;