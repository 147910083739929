import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ArrowDownSmallIcon(props: any) {

	return (
		<SvgIcon viewBox="0 0 9 5" sx={{ width: 9, height: 5 }} {...props}>
			<path d="M8.99996 0.829615C9.00028 0.936345 8.97909 1.0418 8.93793 1.13823C8.89677 1.23466 8.8367 1.31962 8.76212 1.38686L4.90534 4.83748C4.79032 4.94256 4.64605 5 4.49717 5C4.34828 5 4.20401 4.94256 4.08899 4.83748L0.232212 1.26541C0.100942 1.14414 0.0183912 0.96989 0.00271999 0.780981C-0.0129512 0.592071 0.0395408 0.403979 0.148648 0.258083C0.257755 0.112188 0.414541 0.0204401 0.584513 0.00302267C0.754485 -0.0143943 0.923721 0.0439458 1.05499 0.165209L4.50038 3.35864L7.94577 0.272372C8.04012 0.185011 8.15502 0.129518 8.27686 0.112458C8.3987 0.0953979 8.52239 0.117485 8.63329 0.176107C8.74419 0.234729 8.83766 0.327431 8.90264 0.443245C8.96762 0.559059 9.00139 0.693138 8.99996 0.829615Z" />
		</SvgIcon>
	)
}