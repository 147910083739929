import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function HomeIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<g clipPath="url(#clip1_158_19707)">
				<path d="M8.10828 17.5714H9.42828V15.8572H7.23828C7.70933 16.3171 8.01519 16.9197 8.10828 17.5714Z" />
				<path d="M5.14284 15.8572C3.95939 15.8572 3 16.8166 3 18C3 19.1835 3.95939 20.1429 5.14284 20.1429C6.3263 20.1429 7.28569 19.1835 7.28569 18C7.28527 16.8167 6.32616 15.8576 5.14284 15.8572ZM5.14284 19.2858C4.43278 19.2858 3.85711 18.7101 3.85711 18C3.85711 17.2899 4.43273 16.7143 5.14284 16.7143C5.85295 16.7143 6.42858 17.2899 6.42858 18C6.42647 18.7092 5.85206 19.2836 5.14284 19.2858Z" />
				<path d="M5.14245 17.5714C4.90577 17.5714 4.71387 17.7633 4.71387 18C4.71387 18.2367 4.90573 18.4286 5.14245 18.4286C5.37916 18.4286 5.57102 18.2367 5.57102 18C5.57032 17.7636 5.37888 17.5721 5.14245 17.5714Z" />
				<path d="M0.857422 15.8572V17.1429C0.858125 17.3793 1.04956 17.5708 1.286 17.5715H2.17742C2.27052 16.9198 2.57637 16.3171 3.04742 15.8572H0.857422V15.8572Z" />
				<path d="M15.8572 4.28576C15.8565 4.04937 15.665 3.85788 15.4286 3.85718H0.428578C0.192141 3.85783 0.000703125 4.04932 0 4.28576V15H15.8572V4.28576Z" />
				<path d="M13.2861 15.8572H10.2861V17.5714H13.2861V15.8572Z" />
				<path d="M23.889 11.1814L20.9361 7.85566C20.8539 7.76505 20.7371 7.71363 20.6147 7.71424H19.2861V12C19.2868 12.2364 19.4783 12.4278 19.7147 12.4286H24.0004V11.4686C23.9998 11.3624 23.9601 11.2602 23.889 11.1814Z" />
				<path d="M18.8567 15.8572C17.6733 15.8572 16.7139 16.8166 16.7139 18C16.7139 19.1835 17.6733 20.1429 18.8567 20.1429C20.0402 20.1429 20.9996 19.1835 20.9996 18C20.9991 16.8167 20.04 15.8576 18.8567 15.8572ZM18.8567 19.2858C18.1466 19.2858 17.571 18.7101 17.571 18C17.571 17.2899 18.1466 16.7143 18.8567 16.7143C19.5668 16.7143 20.1424 17.2899 20.1424 18C20.1403 18.7092 19.5659 19.2836 18.8567 19.2858Z" />
				<path d="M18.4281 12V7.71423H16.7139V15H18.8567C20.3465 15.0022 21.6091 16.097 21.8224 17.5714H23.571C23.8074 17.5707 23.9989 17.3792 23.9996 17.1428V13.2857H19.7139C19.0046 13.2836 18.4302 12.7092 18.4281 12Z" />
				<path d="M14.1426 15.8572V17.5714H15.8912C15.9843 16.9197 16.2901 16.3171 16.7612 15.8572H14.1426V15.8572Z" />
				<path d="M18.8573 17.5714C18.6206 17.5714 18.4287 17.7633 18.4287 18C18.4287 18.2367 18.6206 18.4286 18.8573 18.4286C19.094 18.4286 19.2859 18.2367 19.2859 18C19.2852 17.7636 19.0937 17.5721 18.8573 17.5714Z" />
			</g>
		</SvgIcon>
	)
}

export default HomeIcon;