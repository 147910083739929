import { useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Typography,
} from '@mui/material';
import theme from '../../../themes/theme';

import IconBlock from '../IconBlock';
import ArrowDownSmallIcon from '../../icons/ArrowDownSmallIcon';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToResourceBoard } from '../../../store/actions/resourceActions';
import Loading from '../../general/Loading';
import { useSnackbar } from 'notistack';
import { useSelector } from '../../../store';
import { IRootState } from '../../../interfaces/store';
import FancyButton from '../Button/Button';

function ResourceCard(props: any) {

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	// const resourceBoardItems: any = useSelector((state: IRootState) => Object.keys(state.main.boardNchipResource ?? {}));
	const resourceBoardItems: any = useSelector((state: IRootState) => {
		return Object.values(state.main.boardNchipResource).reduce((prev:any, curr:any) => {

			const resource = state.main.nchipResource[curr.resource_id];

			if(resource) {
				prev.push(resource.id);
			}

			return prev;
		}, [])
	});
	const isAlreadyAdded = resourceBoardItems.includes(props?.id);

	// console.log(resourceBoardItems, isAlreadyAdded, props.id)

	const [showMore, setShowMore] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);

	const style: any = {
		card: {
			maxWidth: 390
		},
		title: {
			display: 'flex',
		},
		content: {
			mt: 2,
		},
		row: {
			display: 'flex',
			pt: '4px', pb: '4px',
		},
		label: {
			color: '#BDBFC2',
			fontSize: '0.875rem',
		},
		info: {
			fontSize: '0.875rem',
			ml: 'auto',
			textAlign: 'right',
		},
		link: {
			display: 'flex',
			alignItems: 'center',
			fontSize: '0.875rem',
			cursor: 'pointer',
			ml: 'auto',
		},
		linkArrow: {
			ml: '5px',
			width: 9, height: 5,
			transform: (showMore) ? 'rotate(180deg)' : 'rotate(0deg)',
			transition: '0.3s ease all',
		},
		more: {
			display: (showMore) ? 'flex' : 'none',
			pt: 1,
		},
		text: {
			fontSize: '0.875rem',
		}
	};

	const onClickAddToresourceBoard = () => {

		setLoading(true);

		dispatch(addToResourceBoard(
			{ body: {
				"resource_id": props?.id
			} },
			(response) => {
				enqueueSnackbar('Successfully added to board', {variant: 'success'})
				setLoading(false);
			},
			(error) => {
				enqueueSnackbar('Failed to added to board', {variant: 'error'})
				setLoading(false);
			},
		));

	}

	return (
		<>
			<Card variant="outlined" sx={style.card}>
				<CardContent>

					<Box sx={style.title}>
						<IconBlock color={props.color} icon={props.icon} title={props?.category} />
						<Typography variant="body2">
							<Link to={`/resources/${props?.id}`} target="_blank" className="titleText">
								{/* {props.title} */}
								<Typography sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width:"250px"}}>{props?.title}</Typography>
							</Link>
						</Typography>
					</Box>

					<Box sx={style.content}>
						
						<Box sx={style.row}>
							<Typography sx={style.label}>Cancer Type:</Typography>
							<Typography sx={style.info}>{props.type}</Typography>
						</Box>
						<Box sx={style.row}>
							<Typography sx={style.label}>Phase of Treatment:</Typography>
							<Typography sx={style.info}>{props.phase}</Typography>
						</Box>
						<Box sx={style.row}>
							<Typography sx={style.label}>Cancer Center:</Typography>
							<Typography sx={style.info}>{props.cancerCenter}</Typography>
						</Box>
						<Box sx={style.row}>
							<Typography sx={style.label}>Language:</Typography>
							<Typography sx={style.info}>{props.language}</Typography>
						</Box>

						<Box sx={style.row}>
							<Typography sx={style.label}>Description:</Typography>
							<Typography sx={style.link} color="primary" onClick={(e) => setShowMore(!showMore)}>
								{(showMore) ? "Hide" : "See more"}
								<ArrowDownSmallIcon sx={style.linkArrow} color="primary" />
							</Typography>
						</Box>

						<Box sx={style.more}>
							<Typography sx={style.text}>{props.text}</Typography>
						</Box>

						{/* {!isAlreadyAdded && 
						<Button 
							onClick={onClickAddToresourceBoard} 
							sx={{ width: '100%', mt: 1, mb:-1, fontSize: '0.8rem', }}
						>
							Add to Resource Board
						</Button>} */}

						{!isAlreadyAdded && 
							<FancyButton 
								variant="material" 
								style={{width:'100%', marginTop: '8px', fontSize: '0.8rem',}} 
								onClick={onClickAddToresourceBoard}
							>Add to Resource Board
							</FancyButton>
						}
						
						{isAlreadyAdded && 
							<Button disabled variant="outlinedGradient" sx={{ width: '100%', marginTop: '8px',fontSize: '0.8rem', }}>
								Already Added To Resource Board
							</Button>
						}

						{/* {isAlreadyAdded && <Typography sx={{ width: '100%', mt: 1,mb:-1,fontSize: '0.8rem', display:'block', textAlign:'center' }} variant="gradient">Already Added To Resource Board</Typography>} */}

					</Box>

				</CardContent>
			</Card>
			<Loading loading={loading} />
		</>
	)
}

export default ResourceCard;