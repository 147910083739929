import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function EyeHiddenIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path d="M15.8983 7.6888C15.7554 7.49326 12.3496 2.9011 7.99992 2.9011C3.65019 2.9011 0.244313 7.49326 0.101531 7.68861C-0.0338438 7.87411 -0.0338438 8.1257 0.101531 8.3112C0.244313 8.50674 3.65019 13.0989 7.99992 13.0989C12.3496 13.0989 15.7554 8.5067 15.8983 8.31136C16.0339 8.12589 16.0339 7.87411 15.8983 7.6888ZM7.99992 12.044C4.79588 12.044 2.02085 8.99605 1.19938 7.99964C2.01979 7.00236 4.78901 3.95604 7.99992 3.95604C11.2038 3.95604 13.9787 7.00342 14.8005 8.00036C13.9801 8.99761 11.2108 12.044 7.99992 12.044Z" />
			<path d="M7.99992 4.83517C6.25486 4.83517 4.83507 6.25495 4.83507 8.00002C4.83507 9.74508 6.25486 11.1649 7.99992 11.1649C9.74499 11.1649 11.1648 9.74508 11.1648 8.00002C11.1648 6.25495 9.74499 4.83517 7.99992 4.83517ZM7.99992 10.1099C6.83648 10.1099 5.89004 9.16342 5.89004 8.00002C5.89004 6.83661 6.83651 5.89014 7.99992 5.89014C9.16333 5.89014 10.1098 6.83661 10.1098 8.00002C10.1098 9.16342 9.16336 10.1099 7.99992 10.1099Z" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M14.3536 1.64645C14.5488 1.84171 14.5488 2.15829 14.3536 2.35355L1.85355 14.8536C1.65829 15.0488 1.34171 15.0488 1.14645 14.8536C0.951184 14.6583 0.951184 14.3417 1.14645 14.1464L13.6464 1.64645C13.8417 1.45118 14.1583 1.45118 14.3536 1.64645Z" />
		</SvgIcon>
	)
}