import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function HouseIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<g clipPath="url(#clip1_158_19706)">
				<path d="M19.3678 1.05273H17.6837C17.4512 1.05273 17.2627 1.24126 17.2627 1.47375V3.27155L19.7889 5.50639V1.47375C19.7889 1.24121 19.6004 1.05273 19.3678 1.05273V1.05273Z" />
				<path d="M13.0487 18.5162C12.6847 18.8068 12.169 18.8096 11.802 18.523L9.4387 16.7125C9.32911 16.7909 9.26368 16.9171 9.2627 17.0519V23.7884H15.1571V17.0519C15.1571 16.8193 14.9686 16.6309 14.7361 16.6309H13.0596L13.3543 17.3045C13.5456 17.7304 13.4191 18.232 13.0487 18.5162V18.5162Z" />
				<path d="M1.82278 11.368L4.1351 9.32008L2.39959 8.85608C2.26329 8.81947 2.11765 8.85364 2.01181 8.94702L0.70369 10.1049C0.356973 10.415 0.324631 10.9465 0.631272 11.2964C0.946819 11.632 1.46931 11.6634 1.82278 11.368V11.368Z" />
				<path d="M8.42102 17.0519C8.42351 16.7341 8.54575 16.4289 8.76333 16.1973L6.94406 14.8078C6.52512 14.4886 6.34883 13.9426 6.50196 13.4387L7.34825 10.6178C7.3796 10.508 7.36568 10.3903 7.30958 10.2909C7.25347 10.1915 7.15991 10.1188 7.04976 10.0889L5.11303 9.58032L2.38011 11.9996C2.17973 12.1727 1.94148 12.2963 1.68457 12.3604V23.3674C1.68457 23.6 1.87309 23.7884 2.10558 23.7884H8.42107L8.42102 17.0519Z" />
				<path d="M22.736 23.3674V12.359C22.4792 12.2962 22.2412 12.1729 22.0417 11.9995L12.2101 3.30139L8.32823 6.91298L11.5083 9.13644C12.0213 9.49548 12.1939 10.1763 11.9137 10.7363L10.3087 13.9463C10.2526 14.0579 10.2495 14.1888 10.3003 14.3029L12.5002 16.5L14.7363 15.7887C15.4339 15.7887 15.9994 16.3542 15.9994 17.0518V23.7884H22.3149C22.5474 23.7884 22.736 23.5999 22.736 23.3674Z" />
				<path d="M11.6495 0.421156L9.40458 2.40842C9.31407 2.48857 9.26242 2.60374 9.2627 2.72462V4.75482L11.9329 2.42356C12.0919 2.28369 12.33 2.28369 12.4891 2.42356L22.5981 11.3679C22.9469 11.6765 23.4798 11.6439 23.7883 11.2951C24.0969 10.9463 24.0643 10.4134 23.7155 10.1049L19.9123 6.74039L12.769 0.421156C12.4481 0.141841 11.9704 0.141841 11.6495 0.421156V0.421156Z" />
				<path d="M5.93199 5.52829L7.0755 4.07617C7.19704 3.92233 7.18115 3.70119 7.03889 3.56629L5.84232 2.42949C5.68042 2.2779 5.42867 2.2779 5.26677 2.42949L4.33161 3.3263C4.20098 3.44437 4.17192 3.63824 4.26215 3.78945L5.25032 5.48704C5.32171 5.60263 5.44484 5.67626 5.58044 5.68447C5.71605 5.69267 5.8472 5.63441 5.93199 5.52829V5.52829Z" />
				<path d="M0.271159 6.07556L2.05467 6.5164C2.18113 6.54063 2.31148 6.50201 2.40438 6.41281C2.49724 6.32361 2.54111 6.1949 2.52203 6.06759L2.27867 4.31188C2.25734 4.12664 2.09559 3.99 1.90941 3.99989L0.373013 4.07653C0.163351 4.08961 4.68727e-05 4.26346 0 4.47354L0.00379669 5.71937C-0.00210927 5.88624 0.10926 6.03464 0.271159 6.07556V6.07556Z" />
			</g>
		</SvgIcon>
	)
}

export default HouseIcon;