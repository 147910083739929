import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function StopViolenceIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<g clipPath="url(#clip1_158_19714)">
				<path d="M12 0C5.38125 0 0 5.38594 0 12C0 18.6141 5.38594 24 12 24C18.6141 24 24 18.6141 24 12C24 5.38594 18.6141 0 12 0ZM12 21.9C9.87656 21.9 7.85625 21.2391 6.15 19.9922C5.95312 19.8516 5.93437 19.5563 6.10312 19.3875L9.00469 16.4859L7.85625 14.6672L4.62187 17.9016C4.45312 18.075 4.1625 18.0516 4.01719 17.8594C2.76562 16.1484 2.10469 14.1234 2.10469 12.0047C2.10469 6.54375 6.54375 2.10469 12 2.10469C14.1234 2.10469 16.1437 2.76562 17.85 4.0125C18.0422 4.15312 18.0656 4.44844 17.8922 4.61719L15.5578 6.95156C15.5906 7.04531 15.6141 7.14375 15.6234 7.24688C16.3453 6.91406 17.1984 7.22344 17.5078 7.97344L19.3781 6.10312C19.5562 5.93437 19.8328 5.94844 19.9828 6.14531C21.2344 7.85156 21.8953 9.87187 21.9 12C21.9 17.4609 17.4609 21.9 12 21.9V21.9Z" />
				<path d="M19.608 7.00317L17.6111 9.00005V15.7407C17.6111 20.1141 11.6205 20.6157 9.67051 17.5407L9.44082 17.175L7.0127 19.6032C8.48926 20.5782 10.2049 21.0938 12.0002 21.0938C17.0158 21.0938 21.0939 17.0157 21.0939 11.9954C21.0939 10.2 20.5783 8.48442 19.608 7.00317Z" />
				<path d="M16.8144 15.7406V8.50315C16.8144 7.72971 15.6332 7.72971 15.6332 8.50315V11.8922C15.6332 12.4125 14.8222 12.4219 14.8222 11.8922V7.3969C14.8222 6.65627 13.716 6.60471 13.641 7.33596V11.8875C13.641 12.4125 12.8394 12.4125 12.8394 11.8875C12.8394 9.88596 12.8394 7.87971 12.8394 5.87815C12.8394 5.10002 11.6582 5.10002 11.6582 5.87815V11.8875C11.6582 12.4125 10.8566 12.4125 10.8566 11.8875V7.30784C10.8566 6.52971 9.67535 6.52971 9.67535 7.30784V12.3938L10.0644 13.05C11.0347 12.6985 12.1035 13.0688 12.6191 13.9688C12.8816 14.4235 12.1925 14.8219 11.93 14.3672C11.5503 13.7203 10.7488 13.5375 10.1113 13.9125C9.9191 14.0203 9.67535 13.9641 9.56754 13.7719C9.13629 13.0453 8.7191 12.3281 8.2691 11.611C7.86128 10.9641 6.85816 11.5828 7.27535 12.2391L10.355 17.1094C11.8691 19.5188 16.8144 19.2985 16.8144 15.7406V15.7406Z" />
				<path d="M8.89199 11.1094V7.32187C8.89199 6.3 9.96543 5.63438 10.8748 6.06094V5.89219C10.8748 4.06406 13.6545 4.06406 13.6545 5.89219V6.14531C14.1186 5.925 14.6764 5.98594 15.0842 6.3L16.9873 4.39688C15.5061 3.42188 13.7904 2.90625 11.9951 2.90625C6.97949 2.90625 2.90137 6.98438 2.90137 12C2.90137 13.7953 3.41699 15.5109 4.39199 16.9875L7.41543 13.9688L6.5998 12.6844C5.64824 11.175 7.85605 9.68906 8.89199 11.1094V11.1094Z" />
			</g>
		</SvgIcon>
	)
}

export default StopViolenceIcon;