import {
	Box,
	Typography,
	Collapse,
	List,
	ListItem,
	ListItemText,
	Checkbox,
	FormControlLabel,
	Accordion,
	AccordionSummary,
	AccordionDetails,
    Grid

} from '@mui/material';
import Item from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCancerCenters, getCancerTypes, getTreatmentPhases, getResourceCategories } from "../../store/actions/resourceActions";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./style.css"
const LookupFilters: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
	const [categoriesOptions, setCategoriesOptions] = useState<any[]>([]);
    const [cancerTypeOptions, setCancerTypeOptions] = useState<any[]>([]);
    const [cancerCenterOptions, setCancerCenterOptions] = useState<any[]>([]);
    const [treatmentPhaseOptions, setTreatmentPhaseOptions] = useState<any[]>([]);
    // can handle all in one function, but filters are very small so it is good to keep it clean and seperated
    const handleCategoreisCheckboxChange = (id: number): void => {
        const updatedCtgListData = categoriesOptions.map((item:any) => {
          if (item.id === id) {
            return { ...item, isSelected: !item.isSelected };
          }
          return item;
        });
        props.setSelectedCategoriesId(updatedCtgListData.filter(function(e){return e.isSelected}).map(function(e){return e.id}))
        setCategoriesOptions(updatedCtgListData);
      };

      const handleCancerTypeCheckboxChange = (id: number, name:string, isSelected:boolean): void => {
        const updatedCtypeListData = cancerTypeOptions.map((item:any) => {
        if(name === "Any"){
            return isSelected ? { ...item, isSelected: false} : { ...item, isSelected: true}
        }
          if (item.id === id) {
            return { ...item, isSelected: !item.isSelected };
          }
          return item;
        });
        props.setSelectedCancerTypeIds(updatedCtypeListData.filter(function(e){return e.isSelected}).map(function(e){return e.id}))
        setCancerTypeOptions(updatedCtypeListData);
      };

      const handleCancerCenterCheckboxChange = (id: number): void => {
        const updatedCCenterOptionsListData = cancerCenterOptions.map((item:any) => {
          if (item.id === id) {
            return { ...item, isSelected: !item.isSelected };
          }
          return item;
        });
        props.setSelectedCancerCenterIds(updatedCCenterOptionsListData.filter(function(e){return e.isSelected}).map(function(e){return e.id}))
        setCancerCenterOptions(updatedCCenterOptionsListData);
      };

      const handleTreatmentPhaseCheckboxChange = (id: number, name:string, isSelected:boolean): void => {
        const updatedTreatmentPhaseOptionsListData = treatmentPhaseOptions.map((item:any) => {
            if(name === "Any"){
                return isSelected ? { ...item, isSelected: false} : { ...item, isSelected: true}
            }
            if (item.id === id) {
                return { ...item, isSelected: !item.isSelected };
            }
            return item;
        });
        props.setSelectedTreatmentPhaseIds(updatedTreatmentPhaseOptionsListData.filter(function(e){return e.isSelected}).map(function(e){return e.id}))
        setTreatmentPhaseOptions(updatedTreatmentPhaseOptionsListData);
      };

      useEffect(() => {
		dispatch(getCancerTypes(
			{},
			(response) => {
                const initialCtypewithPreselct = response.map((obj:any) => props.selectedCancerTypeIds.includes(obj.id) ? {...obj, isSelected: true} : obj);
				setCancerTypeOptions(initialCtypewithPreselct);
			},
			() => {},))
		dispatch(getCancerCenters(
			{},
			(response) => {
                const initialCcenterwithPreselct = response.map((obj:any) => props.selectedCancerCenterIds.includes(obj.id) ? {...obj, isSelected: true} : obj);
				setCancerCenterOptions(initialCcenterwithPreselct);
			},
			() => {},))
		dispatch(getTreatmentPhases(
			{},
			(response) => {
                const initialTPhasewithPreselct = response.map((obj:any) => props.selectedTreatmentPhaseIds.includes(obj.id) ? {...obj, isSelected: true} : obj);
				setTreatmentPhaseOptions([...initialTPhasewithPreselct]);
			},
			() => {},))
        dispatch(getResourceCategories(
            {},
            (response) => {
                const initialCateogireswithPreselct = response.map((obj:any) => props.selectedCategoriesId.includes(obj.id) ? {...obj, isSelected: true} : obj);
                setCategoriesOptions(initialCateogireswithPreselct)
            },
            () => {},
        ))
	}, [])
    const handleResetAll= async () => {
        setCategoriesOptions(categoriesOptions.map(ctg => ({ ...ctg, isSelected: false })));
        setCancerTypeOptions(cancerTypeOptions.map(ctype => ({ ...ctype, isSelected: false })));
        setCancerCenterOptions(cancerCenterOptions.map(cCenter => ({ ...cCenter, isSelected: false })));
        setTreatmentPhaseOptions(treatmentPhaseOptions.map(tPhOp => ({ ...tPhOp, isSelected: false })));
		props?.setSelectedCategoriesId([]);
		props?.setSelectedCancerTypeIds([]);
		props?.setSelectedCancerCenterIds([]);
		props?.setSelectedTreatmentPhaseIds([]);
    }
    useEffect(() => {
        props?.handleSearch();
    }, [props?.selectedCategoriesId, props?.selectedCancerTypeIds, props?.selectedCancerCenterIds, props?.selectedTreatmentPhaseIds]);
	return (
		<>
        <Box ml={-2.5}>
            <Grid container >
                <Grid item xs={6} md={6}>
                    <Item>
                        <Typography sx={{color: "black", fontWeight: 600, cursor: 'pointer', mb:2 }}> Filters </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={6} >
                    <Item onClick={async () => handleResetAll()}>
                        <Typography sx={{ color:"#a6a6a6f2",fontSize:"0.8rem", cursor: 'pointer', mb:2, float:"right" }}> Reset All </Typography>
                    </Item>
                </Grid>
            </Grid>
  
            <Accordion defaultExpanded={props?.selectedCategoriesId?.length > 0 ? true: false}  sx={{background: 'transparent', boxShadow: 'none'}} style={{position: "unset", borderBottom: '1px solid rgba(0, 0, 0, .125)'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{padding:"unset"}}>
                    <Typography sx={{fontWeight: 200, fontSize:'16px'}}>Category</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent', marginTop: '-13px'}}>
                    {categoriesOptions.map((item:any) => (
                        <ListItem key={item.id} disablePadding sx={{ padding: 0}}>
                            <FormControlLabel
                                sx={{marginRight: 0}}
                                control={<Checkbox checked={item.isSelected || false} onChange={() => handleCategoreisCheckboxChange(item.id)} name={item.name} sx={{ padding:'5px', margin: 0,'& .MuiSvgIcon-root': { fontSize: '1.2rem' } }} />}
                                label={<Typography  sx={{ fontSize:'13px', fontWeight: 400}}>{item.name}</Typography>}
                            />
                        </ListItem>
                    ))}
                    </List>
                </AccordionDetails>
            </Accordion>
            
            <Accordion defaultExpanded={props?.selectedCancerTypeIds?.length > 0 ? true: false}  sx={{background: 'transparent',border: 'none', boxShadow: 'none' }} style={{position: "unset", borderBottom: '1px solid rgba(0, 0, 0, .125)'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{padding:"unset"}}>
                    <Typography sx={{fontWeight: 200, fontSize:'16px'}}>Cancer Type</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent', marginTop: '-13px' }}>
                    {cancerTypeOptions.map((item:any) => (
                        <ListItem key={item.id} disablePadding sx={{ padding: 0 }}>
                            <FormControlLabel
                                sx={{marginRight: 0}}
                                control={<Checkbox checked={item.isSelected || false} onChange={() => handleCancerTypeCheckboxChange(item.id,item.name,item?.isSelected)} name={item.name} sx={{ padding:'5px', margin: 0, '& .MuiSvgIcon-root': { fontSize: '1.2rem' }}} />}
                                label={<Typography  sx={{fontSize:'13px', fontWeight: 400}}>{item.name}</Typography>}
                            />
                        </ListItem>
                    ))}
                    </List>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={props?.selectedCancerCenterIds?.length > 0 ? true: false} sx={{background: 'transparent',border: 'none', boxShadow: 'none' }} style={{position: "unset", borderBottom: '1px solid rgba(0, 0, 0, .125)'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{padding:"unset"}}>
                    <Typography sx={{fontWeight: 200, fontSize:'16px'}}>Cancer Center</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent', marginTop: '-13px' }}>
                    {cancerCenterOptions.map((item:any) => (
                        <ListItem key={item.id} disablePadding sx={{ padding: 0}}>
                            <FormControlLabel
                                sx={{marginRight: 0}}
                                control={<Checkbox checked={item.isSelected || false} onChange={() => handleCancerCenterCheckboxChange(item.id)} name={item.name} sx={{ padding:'5px', margin: 0, '& .MuiSvgIcon-root': { fontSize: '1.2rem' } }} />}
                                label={<Typography  sx={{fontSize:'13px', fontWeight: 400}}>{item.name}</Typography>}
                            />
                        </ListItem>
                    ))}
                    </List>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={props?.selectedTreatmentPhaseIds?.length > 0 ? true: false} sx={{background: 'transparent',border: 'none', boxShadow: 'none' }} style={{position: "unset", borderBottom: '1px solid rgba(0, 0, 0, .125)'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{padding:"unset"}}>
                    <Typography sx={{fontWeight: 200, fontSize:'16px'}}>Treatment Phase</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent', marginTop: '-13px' }}>
                    {treatmentPhaseOptions.map((item:any) => (
                        <ListItem key={item.id} disablePadding sx={{ padding: 0 }}>
                            <FormControlLabel
                                sx={{marginRight: 0}}
                                control={<Checkbox checked={item.isSelected || false} onChange={() => handleTreatmentPhaseCheckboxChange(item.id, item.name, item?.isSelected)} name={item.name} sx={{padding:'5px', margin: 0, '& .MuiSvgIcon-root': { fontSize: '1.2rem' } }} />}
                                label={<Typography  sx={{fontSize:'13px', fontWeight: 400}}>{item.name}</Typography>}
                            />
                        </ListItem>
                    ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Box>
		</>
	)
}

export default LookupFilters;