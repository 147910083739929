import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function FirstAidKit(props: any) {

	return (
		<SvgIcon {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M18.3998 5.19964C18.3999 4.97877 18.5789 4.7998 18.7998 4.79966H20.3998C20.6207 4.7998 20.7996 4.97881 20.7998 5.19964V5.59963H18.3998V5.19964ZM7.99981 3.99964C8.0007 3.11633 8.71652 2.40055 9.59979 2.39966H13.9998C14.8831 2.40055 15.5989 3.11638 15.5998 3.99964V5.59963H14.7998V3.99964C14.7992 3.55803 14.4414 3.20024 13.9998 3.19963H9.59979C9.15818 3.20019 8.80038 3.55803 8.79977 3.99964V5.59963H7.99976L7.99981 3.99964ZM13.9998 3.99964V5.59963H9.59979V3.99964H13.9998ZM3.1998 5.19964C3.19995 4.97877 3.37896 4.7998 3.59979 4.79966H5.19977C5.4206 4.7998 5.59962 4.97881 5.59976 5.19964V5.59963H3.19976L3.1998 5.19964ZM21.9998 21.5997C22.6622 21.599 23.1991 21.0621 23.1998 20.3997L23.1998 7.59964C23.1991 6.93721 22.6623 6.40035 21.9998 6.39964H1.9998C1.33737 6.40035 0.800508 6.93721 0.799805 7.59964V20.3997C0.800508 21.0621 1.33737 21.599 1.9998 21.5997H21.9998ZM13.5998 12.0004H16.3998C16.8414 12.001 17.1992 12.3588 17.1998 12.8004V15.2004C17.1992 15.642 16.8414 15.9999 16.3998 16.0005H13.5998V18.8004C13.5992 19.242 13.2414 19.5999 12.7998 19.6005H10.3998C9.9582 19.5999 9.60035 19.242 9.59979 18.8004V16.0005H6.79981C6.3582 15.9998 6.00035 15.642 5.99979 15.2004V12.8004C6.0004 12.3588 6.3582 12.001 6.79981 12.0004H9.59979V9.20044C9.6004 8.75883 9.9582 8.40099 10.3998 8.40042H12.7998C13.2414 8.40103 13.5993 8.75883 13.5998 9.20044V12.0004ZM13.5998 15.2004H16.3998V12.7996L13.5998 12.8004C13.1582 12.7998 12.8004 12.442 12.7998 12.0004V9.20044H10.3998V12.0004C10.3992 12.442 10.0414 12.7999 9.59979 12.8004H6.79981V15.2004H9.59979C10.0414 15.201 10.3992 15.5588 10.3998 16.0005V18.8004H12.7998V16.0005C12.8004 15.5588 13.1582 15.201 13.5998 15.2004Z" />
		</SvgIcon>
	)
}

export default FirstAidKit;