import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CheckIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path d="M21.7071 5.29291C21.3166 4.90236 20.6834 4.90236 20.2928 5.29291L8.31226 17.2736L3.70712 12.6685C3.31662 12.2779 2.68349 12.278 2.29291 12.6685C1.90236 13.059 1.90236 13.6921 2.29291 14.0827L7.60516 19.3948C7.99555 19.7853 8.62914 19.7851 9.01937 19.3948L21.7071 6.70712C22.0976 6.31662 22.0976 5.68346 21.7071 5.29291Z" />
		</SvgIcon>
	)
}

export default CheckIcon;