import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function EmailIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path d="M23.5323 3.58203L14.3779 12.3192L23.6291 20.3036C23.8615 19.968 23.9999 19.562 23.9999 19.1236V4.8792C23.9999 4.38603 23.8203 3.93884 23.5323 3.58203Z" />
			<path d="M10.4851 12.044L10.8039 12.3404C11.4751 12.9652 12.5255 12.9647 13.1955 12.3408L22.9147 3.06436C22.6187 2.90156 22.2835 2.80078 21.9223 2.80078H2.07751C1.6463 2.80078 1.24552 2.9332 0.913086 3.15877L10.4831 12.0427C10.4835 12.0432 10.4843 12.0435 10.4851 12.044Z" />
			<path d="M0.350016 3.72656C0.129609 4.05656 0 4.45219 0 4.87777V19.1221C0 19.5357 0.124781 19.9193 0.333609 20.2433L9.6112 12.3238L0.350016 3.72656Z" />
			<path d="M13.7475 12.9187L13.7443 12.9215L13.7439 12.9219L13.7402 12.9251C13.1303 13.4931 12.3039 13.7063 11.531 13.5635C11.4802 13.5543 11.4318 13.5339 11.3815 13.5215C11.2795 13.4963 11.1762 13.4739 11.0775 13.4359C10.9307 13.3795 10.7882 13.3091 10.6523 13.2247C10.651 13.2239 10.6499 13.2235 10.6487 13.2231C10.5115 13.1379 10.381 13.0383 10.2587 12.9247L10.201 12.8711L0.888672 20.8207C1.22589 21.0575 1.63469 21.1991 2.07747 21.1991H21.9219C22.3395 21.1991 22.7267 21.0731 23.0527 20.8603L13.7971 12.8727L13.7475 12.9187Z" />
		</SvgIcon>
	)
}

export default EmailIcon;