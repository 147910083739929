import {
	Box,
	Button,
	Card,
	CardContent,
	Typography,
} from "@mui/material";
import { useNavigate  } from "react-router";

import LockIcon from '../../../components/icons/LockIcon';

const style = {
	box: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '67px',
		height: '67px',
		background: '#F0F7FF',
		borderRadius: '8px',
		mb: 2
	}
}

const ResetPasswordNextStep: React.FC = () => {

	const history = useNavigate();

	const handleBackToLogin = () => {
		history('/Admin');
	}
	
	return (
		<>
			<Box sx={style.box}>
				<LockIcon color="primary" />
			</Box>
			{/* <Typography variant="h2" sx={{ mb: 4 }}>
			</Typography> */}
			<Typography variant="body2" color="gray">
				The reset password link has been sent.  Please check your email and use the link that was sent to finish the reset.
			</Typography>
			<Box sx={{ mt: 4 }}>
				<Button onClick={() => handleBackToLogin()}>Back to Log in</Button>
			</Box>
		</>
	);
};

export default ResetPasswordNextStep;
