import {
	Box,
	Button,
	Typography,
} from '@mui/material';

import ModalCardSmall from './ModalCardSmall';

export default function SuspendResource(props: any) {

	const style = {
		introText: {
			textAlign: 'center',
			pt: 2, pb: 2,
		},
		imageWrap: {
			pt: 3, pb: 3,
		},
		image: {
			width: 203, height: 'auto',
			display: 'block',
			margin: '0 auto',
		},
		buttonWrap: {
			display: 'flex',
			justifyContent: 'center',
			pb: 3,
		},
		button: {
			ml: 1, mr: 1,
		}
	}
	
	return (

		<ModalCardSmall title={props?.title ? props.title : 'Suspend Resource'}>
			<Box sx={style.imageWrap}>
				<img style={style.image} src={process.env.PUBLIC_URL + '/images/suspend.png'} alt="" />
			</Box>
			<Typography sx={style.introText} color="grey">{props.message ? props.message : 'Do you want to suspend this resource?'}</Typography>
			<Box sx={style.buttonWrap}>
				<Button variant="outlined" sx={style.button} onClick={props.handleSuspendClose}>No</Button>
				<Button variant="secondary" sx={style.button} onClick={props.handleSuspendYes}>Yes</Button>
			</Box>
		</ModalCardSmall>

	)
}