import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LegalIcon(props: any) {
	return (
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="44" height="44" rx="6" fill={props?.color ?? "#3683EF"} fill-opacity="0.1"/>
<g clip-path="url(#clip0_580_16970)">
<g clip-path="url(#clip1_580_16970)">
<path d="M28.3281 33.25C28.3281 32.085 27.3837 31.1406 26.2188 31.1406H17.7812C16.6163 31.1406 15.6719 32.085 15.6719 33.25V34H28.3281V33.25Z" fill={props?.color ?? "#3683EF"}/>
<path d="M31.8906 15.6719C32.279 15.6719 32.5938 15.3571 32.5938 14.9688V14.9688C32.5938 14.5804 32.279 14.2656 31.8906 14.2656H23.9799C23.7674 13.6699 23.2988 13.2013 22.7031 12.9889V10.7031C22.7031 10.3148 22.3883 10 22 10V10C21.6117 10 21.2969 10.3148 21.2969 10.7031V12.9889C20.7012 13.2013 20.2326 13.6699 20.0201 14.2656H12.1094C11.721 14.2656 11.4062 14.5804 11.4062 14.9688V14.9688C11.4062 15.3571 11.721 15.6719 12.1094 15.6719H13.1642L10 23.4062C10 25.3447 11.5772 26.9219 13.5156 26.9219H14.9219C16.8603 26.9219 18.4375 25.3447 18.4375 23.4062L15.259 15.6719H20.0202C20.2326 16.2676 20.7012 16.7362 21.2969 16.9486V26.9989C19.9228 27.2801 18.8358 28.3597 18.5556 29.7344H25.4445C25.1643 28.3597 24.0773 27.2801 22.7032 26.9989V16.9486C23.2989 16.7362 23.7675 16.2676 23.9799 15.6719H28.7268L25.5625 23.4062C25.5625 25.3447 27.1397 26.9219 29.0781 26.9219H30.4844C32.4228 26.9219 34 25.3447 34 23.4062L30.8215 15.6719H31.8906ZM16.6795 22.7031H11.758L14.2188 16.7966L16.6795 22.7031ZM32.242 22.7031H27.3205L29.7812 16.7966L32.242 22.7031Z" fill={props?.color ?? "#3683EF"}/>
</g>
</g>
<defs>
<clipPath id="clip0_580_16970">
<rect width="24" height="24" fill="white" transform="translate(10 10)"/>
</clipPath>
<clipPath id="clip1_580_16970">
<rect width="24" height="24" fill="white" transform="translate(10 10)"/>
</clipPath>
</defs>
</svg>


	)
}

export default LegalIcon;