import {
	Box,
	Card,
	CardContent,
	Typography
} from '@mui/material';

export default function ModalCardSmall(props: any) {

	const style = {
		modal: {
			maxWidth: 500,
			flexGrow: 1,
		},
		headline: {
			fontWeight: 600,
			fontSize: '1.25rem',
			pb: 2,
		},
		buttonWrap: {
			display: 'flex',
			flexDirection: 'row-reverse',
			mt: 3,
		}
	}

	return (

		<Box sx={style.modal}>
			<Card>
				<CardContent>
					<Typography sx={style.headline}>{props.title}</Typography>
					{props.children}
				</CardContent>
			</Card>
		</Box>

	)
}