import { useState } from 'react';
import { Box, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

import { COLORS } from '../../../enums/layout-enums';
import EyeIcon from '../../../components/icons/EyeIcon';
import EyeHiddenIcon from '../../../components/icons/EyeHiddenIcon';

export default function FormTextField({
  type,
  label,
  value,
  error,
  setValue,
  onChange,
  helperText,
  ...rest
}: any) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordVisible = () => {
    setPasswordVisible((prev) => !prev);
  };

  const handleFieldType = () => {
    return type === 'password' && passwordVisible ? 'text' : type;
  };

  return (
    <Box>
      <InputLabel error={error}>
        {label}
        <Box sx={{ position: 'relative' }}>
          {value.length && type === 'password' ? (
            <Box
              sx={{
                position: 'absolute',
                zIndex: 99,
                right: 10,
                bottom: 7,
                display: value.length ? 'block' : 'none',
              }}
            >
              {passwordVisible ? (
                <EyeIcon
                  color={COLORS.GRAY_BORDER}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={handlePasswordVisible}
                />
              ) : (
                <EyeHiddenIcon
                  color={COLORS.GRAY_BORDER}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={handlePasswordVisible}
                />
              )}
            </Box>
          ) : null}
          <OutlinedInput
            fullWidth
            size="small"
            value={value}
            error={error}
            type={handleFieldType()}
            onChange={onChange ? onChange : (e) => setValue(e?.target.value)}
            sx={{
              mt: 1,
            }}
            {...rest}
          />
          {error && helperText ? (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          ) : null}
        </Box>
      </InputLabel>
    </Box>
  );
}
