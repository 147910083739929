import { takeEvery, takeLatest } from "redux-saga/effects"
import { ACTIONS } from "../../enums/actions"
import authSaga from "./authSaga"
import clientOptionSaga from './clientOptionsSaga'
import userSaga from "./userSaga"
import timezoneSaga from "./timezoneSaga"
import resourceSaga from "./resourceSaga"
import awsSaga from './awsSaga'
import genericSaga, { genericDelete, genericGet, genericPost, genericPut } from './genericSaga'

export default function* rootSaga() {

  //Authentication
  yield takeLatest(ACTIONS.LOGIN, authSaga.login)
  yield takeLatest(ACTIONS.LOGOUT, authSaga.logout)
  yield takeLatest(ACTIONS.REQUEST_PASSWORD_RESET, authSaga.requestResetPassword)
  yield takeLatest(ACTIONS.PASSWORD_RESET, authSaga.resetPassword)

  //Navigators
  yield takeLatest(ACTIONS.NAVIGATOR_LOGIN, authSaga.navigatorLogin)

  //Client Options
  yield takeEvery(ACTIONS.SET_CLIENT_OPTIONS, clientOptionSaga.setclient_options)

  //Users
  yield takeLatest(ACTIONS.GET_USER, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/user/get_user')));
  yield takeLatest(ACTIONS.GET_USERS, userSaga.getUsers)
  yield takeLatest(ACTIONS.CREATE_USER, userSaga.createUser)

  yield takeLatest(ACTIONS.UPDATE_USER, userSaga.updateUser)
  yield takeLatest(ACTIONS.USER_ACTIVATION, userSaga.userActivation)
  yield takeLatest(ACTIONS.DOWNLOAD_USERS_REPORT, userSaga.downloadUsersReport)
  yield takeLatest(ACTIONS.GET_USER_ROLES, authSaga.getUserRolesByUser)
  yield takeLatest(ACTIONS.DOWNLOAD_USERS_REPORT, userSaga.downloadUsersReport)
  yield takeLatest(ACTIONS.ADD_REMOVE_ROLE, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/user/add-remove-role')));

  //Timezone
  yield takeLatest(ACTIONS.SET_TIMEZONE, timezoneSaga.setTimeZone)

  //Resources
  yield takeLatest(ACTIONS.CREATE_UPLOAD_RESOURCE, resourceSaga.createUploadResource)
  yield takeLatest(ACTIONS.UPLOAD_RESOURCE_FILE, resourceSaga.uploadResourceFile)
  // yield takeLatest(ACTIONS.GET_RESOURCE, resourceSaga.getResource)
  // yield takeLatest(ACTIONS.GET_RESOURCE, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/nchip-resource')));

  //AWS
  yield takeLatest(ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD, awsSaga.initPresignedUrlMultipartUpload)
  yield takeLatest(ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD, awsSaga.initPresignedUrlSimpleUpload)
  yield takeLatest(ACTIONS.COMPLETE_MULTIPART_UPLOAD, awsSaga.completeMultiPartUpload)
  yield takeLatest(ACTIONS.GET_PRESIGNED_UPLOAD_URL, (action) => 
    genericSaga.genericHandler(action, 
      (data: any) => genericPost(data, 'api/resource/get-upload-url')
    ));
  yield takeLatest(ACTIONS.GET_FILE_LINK, (action) => 
    genericSaga.genericHandler(action, 
      (data: any) => genericPost(data, 'api/resource/get-file')
    ));

  yield takeLatest(ACTIONS.CONFIRM_EMAIL, (action) => genericSaga.genericHandler(action, (data: any) => genericPost(data, 'api/auth/confirm-email')));

  // NCHIP Resource Management Page
  yield takeLatest(ACTIONS.GET_ALL_RESOURSES, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/nchip-resource/get-all')));
  yield takeLatest(ACTIONS.GET_RESOURSE_CATEGORIES, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/nchip-resource/category/get-all')));
  yield takeLatest(ACTIONS.GET_CANCER_TYPE, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/nchip-resource/cancer-type/get-all')));
  yield takeLatest(ACTIONS.GET_CANCER_CENTER, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/nchip-resource/cancer-center/get-all')));
  yield takeLatest(ACTIONS.GET_TREATMENT_PHASE, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/nchip-resource/treatment-phase/get-all')));
  yield takeLatest(ACTIONS.GET_RESOURCE, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body, id } = data;
      return genericGet(body, `api/nchip-resource/${id}`);
    })
  );

  yield takeLatest(ACTIONS.SEARCH_NCHIP_RESOURCES, (action) => genericSaga.genericHandler(action, (data: any) => genericGet(data, 'api/nchip-resource/search')));
  yield takeLatest(ACTIONS.CREATE_RESOURCE, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      return genericPost(data, `api/nchip-resource/create`);
    })
  );
  yield takeLatest(ACTIONS.UPDATE_RESOURCE, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body, id } = data;
      return genericPut(body, `api/nchip-resource/update-resource/${id}`);
    })
  );
  yield takeLatest(ACTIONS.SUSPEND_RESOURCE, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body, id } = data;
      return genericPut(body, `api/nchip-resource/resource-suspention/${id}`);
    })
  );
  yield takeLatest(ACTIONS.GET_RESOURCE_ACTIVITY_LOGS, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body={}, id } = data;
      return genericGet(body, `api/nchip-resource/resource-logs/${id}`);
    })
  );


  // Resource Board
  yield takeLatest(ACTIONS.GET_RESOURCE_BOARD, resourceSaga.getResourceBoard);
  // yield takeLatest(ACTIONS.GET_RESOURCE_BOARD, (action: any) =>
  //   genericSaga.genericHandler(action, (data: any) => {
  //     const { body={}, id } = data;
  //     return genericGet(body, `api/nchip-resource/board/get-all-resources`);
  //   })
  // );
  yield takeLatest(ACTIONS.ADD_TO_RESOURCE_BOARD, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body={} } = data;
      return genericPost(body, `api/nchip-resource/board/add`);
    })
  );
  yield takeLatest(ACTIONS.DELETE_RESOURCE_BOARD_ITEM, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body={} } = data;
      return genericDelete(body, `api/nchip-resource/board/delete-resource`);
    })
  );
  yield takeLatest(ACTIONS.COPY_RESOURCE_BOARD, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body={} } = data;
      return genericPost(body, `api/nchip-resource/board/copy`);
    })
  );
  yield takeLatest(ACTIONS.CLEAR_RESOURCE_BOARD, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body={} } = data;
      return genericDelete(body, `api/nchip-resource/board/delete-resource?removeAll=true`);
    })
  );

  // TRUSTED EMAIL DOMAINS
  yield takeLatest(ACTIONS.GET_ALL_EMAIL_DOMAINS, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body={} } = data;
      return genericGet(body, `api/allowed-domains/query`);
    })
  );
  yield takeLatest(ACTIONS.CREATE_EMAIL_DOMAIN, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body={} } = data;
      return genericPost(body, `api/allowed-domains/create`);
    })
  );
  yield takeLatest(ACTIONS.UPDATE_EMAIL_DOMAIN, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body={}, id } = data;
      return genericPut(body, `api/allowed-domains/${id}`);
    })
  );
  yield takeLatest(ACTIONS.DELETE_EMAIL_DOMAIN, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      const { body={} } = data;
      return genericDelete(body, `api/allowed-domains`);
    })
  );


  // Stats
  yield takeLatest(ACTIONS.GET_DASHBOARD_STATS, (action: any) =>
    genericSaga.genericHandler(action, (data: any) => {
      return genericGet(data, `api/stats/dashboard`);
    })
  );

}
