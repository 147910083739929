import React, { useState } from 'react';
import {
	Box,
	Typography,
} from '@mui/material';
import {
	CCarousel,
	CCarouselItem,
} from "@coreui/react";

import { COLORS } from '../../enums/layout-enums';

const style = {
	image: {
		backgroundSize: 'cover',
		backgroundRepeat: 'none',
		backgroundPosition: 'center center',
		position: 'relative',
		width: '100%',
		height: 372,
		borderRadius: '20px',
	},
	content: {
		position: 'absolute',
		zIndex: 99,
		top: 90,
		paddingLeft: '80px',
	},
	subTitle: {
		fontSize: '1rem',
		display: 'flex', alignItems: 'center',
		opacity: 0.6,

		'&:before': {
			content: '""',
			display: 'block',
			width: 26, height: 2,
			background: COLORS.BLUE_BRAND_PRIMARY_DARK,
			opacity: 0.6,
			mr: '14px',
		}
	},
	title: {
		mt: 1,
		fontSize: {
			xl: '4rem',
		}
	}
}

export default function SearchCarousel(props: any) {
	return (

		<>
			<Box sx={{ mt: 2, position: 'relative', }}>
				<Box sx={style.content}>
					{/* <Typography color={COLORS.BLUE_BRAND_PRIMARY_DARK} sx={style.subTitle}>Lorem ipsum dolor sit amet, consetetur</Typography>
					<Typography variant="h1" sx={style.title}>Search NCHIP Resources</Typography> */}
				</Box>
				<Box sx={style.image} style={{
					backgroundImage: 'url(/images/search-slides/search-1.png)',
				}}></Box>
			</Box>
		</>

	)
}