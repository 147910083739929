import {
	Box,
	Button,
	Typography
} from '@mui/material';

import Suspended from './Suspended';
import EditIcon from '../icons/EditIcon';

export default function CardHeader(props: any) {

	return (

		<Box sx={{ pl: 4, pr: 8, pt: 2, pb: 2, display: 'flex', alignItems: 'center' }}>
			<Box sx={{ mr:8, display: 'flex', lineBreak:"anywhere"}}>
				<Typography>{props.title}</Typography>
			</Box>
			<Box sx={{ ml: 'auto' }}>
				{props.children}
			</Box>
		</Box>

	)
}