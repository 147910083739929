import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, InputLabel, TextField } from '@mui/material';

import { useDispatch } from 'react-redux';
import { createUser, updateUser } from '../../store/actions/userActions';
import { useSnackbar } from 'notistack';
// import { IRootState } from '../../interfaces/store';

const AddUserSchema = Yup.object().shape({
  // role: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export default function AddEditUserForm({
  user,
  // roles,
  isEdit,
  onSuccess,
  handleCloseModal,
}: any) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // const userRoles = useSelector((state: IRootState) => state.main.user_roles);

  // function getUserRole() {
  //   const currentUserRoleId = Object.keys(userRoles).find(
  //     (roleId) => userRoles[roleId].user_id === user.id,
  //   );

  //   if (currentUserRoleId) {
  //     const currentUserRole = userRoles[currentUserRoleId].role_id as number;
  //     return roles[currentUserRole].name;
  //   }
  //   return '';
  // }

  const handleSubmit = (values: any) => {
    if (isEdit) {
      dispatch(
        updateUser(
          {
            id: user.id,
            // role: 'Admin',
            email: values.email,
            last_name: values.lastName,
            first_name: values.firstName,
          },
          () => {
            enqueueSnackbar('User Updated Successfully', {
              variant: 'success',
            });
            onSuccess();
            handleCloseModal();
          },
          () => {
            enqueueSnackbar('Unable to update User', {
              variant: 'error',
            });
          },
        ),
      );
    } else {
      dispatch(
        createUser(
          {
            role: 'Admin',
            email: values.email,
            last_name: values.lastName,
            first_name: values.firstName,
          },
          () => {
            enqueueSnackbar('User Created Successfully', {
              variant: 'success',
            });
            onSuccess();
            handleCloseModal();
          },
          () => {
            enqueueSnackbar('Unable to create User', {
              variant: 'error',
            });
          },
        ),
      );
    }
  };

  const style = {
    modal: {
      maxWidth: 500,
      flexGrow: 1,
    },
    headline: {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    buttonWrap: {
      display: 'flex',
      flexDirection: 'row-reverse',
      mt: 3,
    },
  };

  return (
    <Formik
      initialValues={
        isEdit
          ? {
              ...user,
              // role: getUserRole(),
              lastName: user.last_name,
              firstName: user.first_name,
            }
          : {
              role: '',
              email: '',
              lastName: '',
              firstName: '',
            }
      }
      validationSchema={AddUserSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <InputLabel>First Name</InputLabel>
          <TextField
            fullWidth
            type="text"
            size="small"
            name="firstName"
            variant="outlined"
            // label="First Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstName}
            placeholder="Enter First Name"
            {...(errors.firstName && touched.firstName
              ? {
                  error: true,
                  helperText: errors.firstName as string,
                }
              : {})}
          />

          <div style={{ marginTop: '1rem' }}>
            <InputLabel>Last Name</InputLabel>
            <TextField
              fullWidth
              type="text"
              size="small"
              name="lastName"
              // label="Last Name"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              placeholder="Enter Last Name"
              {...(errors.lastName && touched.lastName
                ? {
                    error: true,
                    helperText: errors.lastName as string,
                  }
                : {})}
            />
          </div>

          <div style={{ marginTop: '1rem' }}>
            <InputLabel>Email</InputLabel>
            <TextField
              fullWidth
              type="text"
              name="email"
              size="small"
              // label="Email"
              variant="outlined"
              onBlur={handleBlur}
              value={values.email}
              onChange={handleChange}
              placeholder="Enter Email"
              {...(errors.email && touched.email
                ? {
                    error: true,
                    helperText: errors.email as string,
                  }
                : {})}
            />
          </div>

          {/* <FormSelectField
              fullWidth
              name="role"
              label="Role"
              onBlur={handleBlur}
              value={values.role}
              onChange={handleChange}
              placeholder="Select Role"
              {...(errors.role && touched.role
                ? {
                    error: true,
                    helperText: errors.role,
                  }
                : {})}
            >
              {Object.keys(roles).map((roleId: string) => (
                <MenuItem key={`role-${roleId}`} value={roles[roleId].name}>
                  {roles[roleId].name}
                </MenuItem>
              ))}
            </FormSelectField> */}

          <Box sx={style.buttonWrap}>
            <Button type="submit" variant="contained">
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}
