import React, { useState, useEffect } from 'react';
import {
	Card,
	CardContent,
	Toolbar,
	AppBar,
	Avatar,
	MenuList,
	MenuItem,
	Box,
	Button,
	Typography,
} from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../interfaces/store';
import { logout } from "../../store/actions/authActions";
import { useSnackbar } from "notistack";

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';

import Badge, { BadgeProps } from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '../icons/NotificationIcon';
import AccountIcon from '../icons/AccountIcon';
import TrayIcon from '../icons/TrayIcon';

import ResourceBoard from './resource-board/ResourceBoard';

import { useNavigate } from 'react-router-dom';

const settings = ['My Profile', 'User Management', 'Resource Management', 'Logout'];

function Header() {
	
	const dispatch = useDispatch();
	const history = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [isAdmin, setIsAdmin] = useState(false);
	const user = useSelector((state: IRootState) => state.main.my_user);
	const user_roles = useSelector((state: IRootState) => state.main.user_roles);
	const roles = useSelector((state: IRootState) => state.main.roles);
	const clientOptions = useSelector((state: IRootState) => state.main.client_options);

	const resourceBoardItems: any = useSelector((state: IRootState) => Object.keys(state.main.boardNchipResource));

	const [openMenu, setOpenMenu] = useState(false);
	const handleOpenMenu = () => {
		setOpenMenu((prev) => !prev);
	}
	const handleMenuClickAway = () => {
		setOpenMenu(false);
	};

	const [showResourceBoard, setShowResourceBoard] = useState(false);
	const handleResourceBoardClick = () => {
		setShowResourceBoard((showResourceBoard) => !showResourceBoard);
	};
	const handleResourceBoardClickAway = () => {
		setShowResourceBoard(false);
	};

	const handleMenuMyProfile = () => {
		setOpenMenu(false);
		history('/profile');
	};

	const handleMenuUserManagement = () => {
		setOpenMenu(false);
		history('/users');
	};

	const handleMenuResourceManagement = () => {
		setOpenMenu(false);
		history('/resources');
	}

	const handleLogout = () => {
		dispatch(
			logout({},
				() => {
					isAdmin ? history('/admin') : history('/navigatorLogin')
				},
				() => {
					enqueueSnackbar("Could not log out.", {
						variant: "error",
					});
				},
			)
		);
	};

	useEffect(() => {
		let userRolesArr = Object.values(user_roles);
		let rolesArr = Object.values(roles);
		if (!isAdmin && user?.id && userRolesArr.length && rolesArr.length) {

			const adminRole = rolesArr.find((x) => x.name === "Admin");
			if (adminRole) {
				const userAdminRole = userRolesArr.find((x) => x.user_id === user.id && x.role_id === adminRole.id)
				if (userAdminRole) {
					setIsAdmin(true);
				}
			}

		}
	}, [user, user_roles, roles, isAdmin]);

	const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
		'& .MuiBadge-badge': {
			fontSize: '10px',
			fontWeight: '600',
			right: 5,
			top: 5,
			padding: '0px 4px',
			height: '13px',
			minWidth: '13px',
		},
	}));

	const style = {
		menuItem: {
			color:"#332f2fde",
			fontSize: '0.875rem',
			borderBottom: 'solid 1px #DDDDDD',
			'&:hover': {
				background: '#F5F9FE',
				color:"black"
			}
		}
	}
	
	return (

		<Grid container columnSpacing={4}>
			<Grid item xs={12}>
				<AppBar position="static">
					<Toolbar disableGutters={true}>
						<Box sx={{ display: 'flex', flexGrow: 1, pt: 1, pb: 1, pl:3, pr:2 }}>
							<img onClick={() => {
								history('/')
							}} alt={'logo'} width={140} height={40} style={{ alignSelf: 'center', cursor: 'pointer', display: 'block' }} src={process.env.PUBLIC_URL + '/images/ternLogo.png'}></img>
						
							<Box sx={{ flexGrow: 1 }}></Box>
							
							<Box sx={{ display: 'flex', alignItems: 'center', }}>
								<ClickAwayListener onClickAway={handleResourceBoardClickAway}>
									<Box sx={{ position: 'relative', }}>
										<IconButton
											sx={{ ml: { xs: 2, lg: 4 } }}
											aria-label="show 2 new mails"
											onClick={() => handleResourceBoardClick()}
										>
											{resourceBoardItems.length > 0 && <StyledBadge badgeContent={resourceBoardItems.length ?? 0} color="error" sx={{width:"20px", height:"20px"}}>
												<TrayIcon style={{ color: '#BDBFC2',width:"22px", height:"22px" }} />
											</StyledBadge>}
											{resourceBoardItems.length === 0 &&
												<TrayIcon style={{ color: '#BDBFC2',width:"22px", height:"22px" }} />
											}

										</IconButton>
										{showResourceBoard ? (
											<ResourceBoard showResourceBoard={setShowResourceBoard} />
										) : null }
									</Box>
								</ClickAwayListener>

								<Box sx={{ position: 'relative', cursor: 'pointer', display: 'flex', flexGrow: 0, ml: { xs: 2, lg: 3 } }}>

									<IconButton sx={{ p: 0 }}>
										<Avatar alt="Remy Sharp" sx={{ background: "#EBF3FD",width:"25px", height:"25px" }}>
											<AccountIcon color="primary" sx={{width:"17px", height:"17px"}}/>
										</Avatar>
									</IconButton>
									<Typography onClick={handleOpenMenu} sx={{ display: 'flex', color: 'primary', marginLeft: '15px', alignItems: 'center', fontSize: '0.9rem', }}>
										{/* {isAdmin ? 'Admin' : 'Navigator'} */}
										{user?.email}
										<KeyboardArrowDownIcon sx={{ color: '#ddd', marginLeft: '5px', }} />
									</Typography>
									{openMenu  ? (
										<ClickAwayListener
											onClickAway={handleMenuClickAway}
										>
											<Box sx={{
												position: 'absolute',
												zIndex: 101,
												right: 0, top: 60,
												width: 180,
											}}>
												<Card>
													<MenuList>

														{isAdmin ? (
															<>
																{/* <MenuItem sx={style.menuItem} onClick={handleMenuMyProfile}>My Profile</MenuItem> */}
																<MenuItem sx={style.menuItem} onClick={handleMenuUserManagement}>User Management</MenuItem>
																<MenuItem sx={style.menuItem} onClick={handleMenuResourceManagement}>Resource Management</MenuItem>
															</>

														) : null }

														<MenuItem
															sx={style.menuItem}
															style={{borderBottom:"none"}}
															onClick={handleLogout}
														>Logout</MenuItem>
													</MenuList>
												</Card>
											</Box>
										</ClickAwayListener>
									) : null }

								</Box>
							</Box>
						</Box>
					</Toolbar>
				</AppBar>
			</Grid>
		</Grid>
		
	)
}

export default Header;