import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";

import {
	Typography,
	Box,
	InputLabel,
	TextField,
	Button,
} from '@mui/material';

import Loading from "../../../components/general/Loading";
import { logout, resetPassword } from "../../../store/actions/authActions";
import { useNavigate  } from "react-router-dom";
import { getUrlParam } from "../../../utils/general";
import { IRootState } from "../../../interfaces/store";
import BackToLogin from '../../../components/shared/BackToLogin';
import FormTextField from '../../../components/shared/form/FormTextField';
import { useFormik } from "formik";
import FancyButton from "../../../components/shared/Button/Button";

interface IResetPasswordProps {}
const validPasswordRegEx = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{6,}$");


const ResetPassword: React.FC<IResetPasswordProps> = () => {

  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [passwordFieldDirty, setPasswordFieldDirty] = useState<boolean>(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmError, setConfirmError] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [processing, setProcessing] = useState(false);
  // const recaptchaRef = useRef<ReCAPTCHA>(null);

  
  const onChangePassword = (value: string) => {
    !passwordFieldDirty && setPasswordFieldDirty(true);
    setPassword(value);
  }

  const user = useSelector(
    (state: IRootState) => state.main.my_user
  );

  useEffect(() => {
    if (user?.id){
      dispatch(logout({}))
    }
  }, [user])


  const token = getUrlParam('token')

  const handleCaptchaChange = (value?: string | null) => {
    setCaptcha(value as string);
  };

  const validatePassword = () => {
    const isValid = validPasswordRegEx.test(password)
    setPasswordError(!isValid)
  }

  const validateConfirm = () => {
    const hasConfirmError = confirmPassword !== password
    setConfirmError(hasConfirmError)
  }

  const onSubmit = () => {
    // if (!captcha){
    //   enqueueSnackbar(
    //     "reCAPTCHA is required.",
    //     { variant: "error" }
    //   );
    // } else
    if (!password.length){
      enqueueSnackbar(
        "Invalid Password.",
        { variant: "error" }
      );  
    }
    else if (password !== confirmPassword){
      enqueueSnackbar(
        "Password and confirm password must match.",
        { variant: "error" }
      );
    } else {
      setProcessing(true);
      dispatch(
        resetPassword(
          { password, token },
          (response: any) => {
            setProcessing(false);
            enqueueSnackbar(
              "Password has been reset",
              { variant: "success" }
            );

            const adminRole = response.user_roles.find((x: any) => x.name === 'Admin');
            // direct admins to login
            if (adminRole){
			        history("/Admin")
            }
            else{ // direct regular users to page instructing them to login on their phone
			        history("/NavigatorLogin")
            }

          },
          () => {
            enqueueSnackbar(
              "Something went wrong, please request another reset token.",
              { variant: "error" }
            );  
            setProcessing(false);
          }
        )
      );
    }

  };

	return (

		<>
			<BackToLogin />
			<Typography variant="h2" sx={{ mb: 4 }}>Set new password</Typography>
			<Typography variant="body2" color="gray" sx={{ mb: 4 }}>Your new password must be different to previously used passwords</Typography>

			<Box sx={{ mb: 2 }}>
        <Box sx={{ pt: 1, pb: 1, }}>
          <FormTextField
            label="Password"
            placeholder="Enter your new password"
            id="password"
            fullWidth
            type="password"
            value={password}
            setValue={onChangePassword}
          />
          <p className="error-message">{passwordFieldDirty && password.length < 8 && `Password length must be 8 characters`}</p>
        </Box>
        <Box sx={{ pt: 1, pb: 1, }}>
          <FormTextField
            label="Confirm Password"
            placeholder="Enter your new password"
            id="confirm_password"
            fullWidth
            type="password"
            value={confirmPassword}
            setValue={setConfirmPassword}
          />
          <p className="error-message">{confirmPassword && password !== confirmPassword && `Must be same as password`}</p>
        </Box>
      </Box>

			<Box>
				<Typography variant="body2" color="gray" sx={{ fontSize: '0.875rem', }}>Must be at least 8 characters</Typography>
			</Box>

			<Box sx={{ mt: 4 }}>
				{/* <Button disabled={!((password.length >= 8 && confirmPassword.length >= 8) && (password === confirmPassword))} onClick={() => onSubmit()}>Set Password</Button> */}
        <FancyButton
          disabled={password.length < 8 || password !== confirmPassword}
          onClick={() => onSubmit()}
          style={{width:"100%"}}
        >Set Password</FancyButton>
			</Box>		
		</>

	)
}

export default ResetPassword;