import {
	Box,
	Card,
	CardContent,
	Typography,
	Pagination,
	Grid,
	Checkbox,
	Select,
	MenuItem
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import Item from '@mui/material/Grid';
import ResourceCard from './resource/ResourceCard';
import theme from '../../themes/theme';
import { categorytoIcon } from '../../shared/constants/category-icons';


interface IROption {
	value: string;
	label: string;
  }
const sortOptions:IROption[] = [
	{ value: "s.title", label: "Title" },
	{ value: "category.name", label: "Category" },
	{ value: "cancerCenter.name", label: "Cancer Center" },
	{ value: "cancerType.name", label: "Cancer Type" },
	{ value: "treatmentPhase.name", label: "Treatment Phase" },
	{ value: "s.language", label: "Language" },
	{ value: "s.created_at", label: "Most Recent" }
  ];

export default function SearchResultBlocks(props: any) {
	const handleSortSelectChange = (event: SelectChangeEvent<string>) => {
		props.handleSearch(undefined, event.target.value)
	  };
	return (

		<Box sx={{ pt: 3 }} justifyContent="center" alignItems="center">
			<Grid container spacing={2} style={{border:"none", position:"relative"}}>
                <Grid item xs={6} md={6} >
                    <Item>
                        <Typography sx={{ pl:1, fontWeight: 600, fontSize:"0.8rem" }}> {props?.totalResult}
						{props?.totalResult === 1 ? (
							<span> card</span>):(
							<span> cards</span>
						 )}  found </Typography>
                    </Item>
                </Grid>
                <Grid item xs={6} md={6} >
                    <Item>
						<Select sx={{ color:'#a6a6a6f2','.MuiSelect-select':{padding:0}, paddingBottom:0, float:"right", boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0, }}}
							// multiple
							value={props.selectedSortOption}
							onChange={handleSortSelectChange}
							labelId="my-select-label"
							>
							{sortOptions.map((sortOption: IROption) => (
								<MenuItem  sx={{ pt: 0, pb: 0, }} key={sortOption.value} value={sortOption.value}>
									{(props.selectedSortOption === sortOption.value) ?(
										<Typography sx={{ '.MuiTypography-root':{color:"#a6a6a6f2"}, fontSize: '0.8rem'}}>{sortOption.label}</Typography>
									 ):( 
										<Typography sx={{fontSize: '0.8rem', color:'#a6a6a6f2'}}>{sortOption.label}</Typography>
									)}
								</MenuItem>
							))}
						</Select>
						<Typography sx={{color: "black", fontSize:"0.8rem", float:"right", mr:2, fontWeight: 600, }}> Sort by:</Typography>
                    </Item>
                </Grid>
            </Grid>
			<Grid container spacing={2}>
				{props?.searchResult?.data?.map((block:any, i:number) => {
					return (
						<Grid xs={12} md={4} key={i} pl={3} pt={3}>
							<Box>
							<ResourceCard
								id={block?.id}
								color={categorytoIcon(block?.category.name)?.color as string}
								icon={categorytoIcon(block?.category.name)?.icon as string}
								category={block?.category.name}
								title={block?.title}
								type={block?.cancerType?.name}
								phase={block?.treatmentPhase?.name}
								cancerCenter={block?.cancerCenter?.name}
								language={block?.language}
								text={block?.description}
							/>
							</Box>
						</Grid>
					)
				})}
			</Grid>
			<Grid sx={{mt:3,mb:5}} container justifyContent="center" alignItems="center">
				<Pagination shape="rounded" count={props.pageCount} page={props.page} onChange={(event, value) => props.handleSearch(value)} />
			</Grid>
		</Box>

	)
}