import { Shadows } from "@mui/material";
import {
	createTheme,
} from "@mui/material/styles";

import { COLORS } from "../enums/layout-enums";

declare module '@mui/material/styles' {

  interface TypographyVariants {
    gradient: React.CSSProperties;
    slider: React.CSSProperties;
  }

  interface TypographyColors {
  	gray: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    gradient?: React.CSSProperties;
    slider?: React.CSSProperties;
  }

  interface TypographyColorsOptions {
  	gray?: React.CSSProperties;
  }

  interface Palette {
    violet: Palette['primary'];
    violetLight: Palette['primary'];
    blue: Palette['primary'];
    orange: Palette['primary'];
    yellow: Palette['primary'];
    green: Palette['primary'];
    pink: Palette['primary'];
    red: Palette['primary'];
    gradient: Palette['primary'];
    gray: Palette['primary'];
  }

  interface PaletteOptions {
    violet: PaletteOptions['primary'];
    violetLight: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    orange: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    pink: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
    gradient: PaletteOptions['primary'];
    gray: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    gradient: true;
    slider: true;
  }

  interface TypographyPropsColorOverrides {
  	gray: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    outlinedGradient: true;
    white: true;
    icon: true;
    transparent: true;
  }
}

const fontFamily = "'DM Sans', sans-serif";
const gradient = 'linear-gradient(to left, #29EAC4, #2C87FF)';
const boxShadow1 = '0px 12px 32px -2px rgba(54, 131, 239, 0.06)';
const boxShadow2 = '5px 9px 30px -2px rgba(54, 131, 239, 0.12)';

let theme = createTheme({
	spacing: 10,
	palette: {
		primary: {
			main: COLORS.BLUE_BRAND_PRIMARY,
			dark: COLORS.BLUE_BRAND_PRIMARY_DARK,
		},
		error: {
			main: COLORS.ERROR,
			contrastText: COLORS.WHITE,
		},
		success: {
			main: COLORS.SUCCESS,
		},
		violet: {
			main: COLORS.VIOLET,
		},
		violetLight: {
			main:COLORS.VIOLET_LIGHT
		},
		blue: {
			main: COLORS.BLUE,
			light: COLORS.BLUE_LIGHT,
		},
		orange: {
			main: COLORS.ORANGE,
		},
		yellow: {
			main: COLORS.YELLOW,
		},
		green: {
			main: COLORS.GREEN,
			dark: COLORS.GREEN_DARK,
		},
		pink: {
			main: COLORS.PINK,
		},
		red: {
			main: COLORS.RED_BRAND_PRIMARY,
		},
		gray: {
			main: COLORS.GRAY,
		},
		gradient: {
			main: gradient,
		},
	},
	shadows: [
    'none',
    boxShadow1,
    boxShadow2,
    boxShadow2,
    boxShadow2,
    boxShadow2,
    boxShadow2,
    boxShadow2,
    boxShadow2,
    boxShadow2,
    boxShadow2,
		...Array(15).fill("none"),
  ] as Shadows,
	typography: {
		fontFamily: fontFamily
	},
});

theme = createTheme(theme, {
	typography: {
		gradient: {
			background: gradient,
			backgroundClip: 'text',
			WebkitTextFillColor: 'transparent',
		},
		h1: {
			color: COLORS.BLUE_DARK,
			fontSize: '3.5rem',
			fontWeight: '600',
			lineHeight: '1.2',
		},
		h2: {
			color: COLORS.BLUE_DARK,
			fontSize: '3rem',
			fontWeight: '600',
			lineHeight: '1.5',
		},
		h3: {
			color: COLORS.BLUE_DARK,
			fontSize: '1.875rem',
			fontWeight: '600',
			lineHeight: '1.5',
		},
		h4: {
			color: COLORS.BLUE_DARK,
			fontSize: '1.25rem',
			fontWeight: '400',
			lineHeight: '1.5',
		},
		h5: {
			color: COLORS.BLUE_DARK,
			fontSize: '1.125rem',
			fontWeight: '400',
			lineHeight: '1.5',
		},
		h6: {
			color: COLORS.BLUE_DARK,
			fontSize: '1rem',
			fontWeight: '400',
			lineHeight: '1.5',
		},
		body1: {
			color: COLORS.BLUE_DARK,
			fontSize: '1.25rem',
		},
		body2: {
			color: COLORS.BLUE_DARK,
			fontSize: '1.125rem',
			lineHeight: '1.25em',
		},
		button: {
			textTransform: 'none',
			cursor: 'pointer',
		},

		slider: {
			fontWeight: 700,
			fontSize: '1.25rem',

			[theme.breakpoints.up('md')]: {
				fontSize: '2.5rem',
			}
		},
	},
	components: {
		MuiModal: {
			styleOverrides: {
				root: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {

					'&:hover': {
						color: COLORS.BLUE_BRAND_PRIMARY,
					}
				}
			}
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					paddingLeft: 0,
					paddingRight: 0,
				}
			}
		},
		MuiAppBar: {
			styleOverrides: {
				colorPrimary: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
				}
			}
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: '10px',
					boxShadow: 2,
					overflow: 'visible',
				}
			},
			variants: [
				{
					props: { variant: 'outlined', },
					style: {
						borderColor: COLORS.GRAY_BORDER,
					}
				},
			]
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: 30,
				}
			}
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					borderBottom: 'solid 1px ' + COLORS.GRAY_BORDER,
				}
			}
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					boxShadow: 1,
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: '0.875rem',

					'&:hover': {
						background: COLORS.BLUE_BACKGROUND,
					}
				}
			}
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: '#F8F8F8',
					paddingTop: '20px',
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					border: 'none',
					padding: '0px',

					'&:first-of-type': {
						paddingLeft: '40px',
					},

					'&:last-child': {
						paddingRight: '40px',
					},
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: '#1F2937',
					fontSize: '1rem',
				},
			}
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					paddingTop: '0px',
					paddingBottom: '0px',
					paddingRight: '15px',

					'.MuiSelect-icon': {
						right: 18, top: 18,
					},
				}
			},
			variants: [
				{
					props: { variant: 'standard' },
					style: {
						fontSize: '1rem',

						'&:before': { display: 'none', },
						'&:after': { display: 'none', }
					}					
				}
			]
		},
		MuiInputBase: {
			styleOverrides: {
				root: {

					'.MuiSelect-select': {
						background: 'transparent !important',
					}
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					fontSize: '0.875rem',

					fieldset: {
						borderColor: COLORS.GRAY_LIGHT,
					},

					'.MuiOutlinedInput-input': {
						paddingTop: '12px',
						paddingBottom: '12px',
					},

					'.MuiInputBase-inputMultiline': {
						paddingTop: '0px',
						paddingBottom: '0px',
					},

					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: COLORS.GRAY,
					},

					'&.Mui-focused fieldset': {
						borderColor: '#C52328',
						borderWidth: '2px'
					},

					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						border: 'solid 1px ' + COLORS.GRAY_BORDER,
					}
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					color: '#fff',
					width: '100%',
					background: gradient,
					boxShadow: 'none',
					fontWeight: '600',
					fontSize: '1.25rem',
					textTransform: 'none',
					paddingTop: '12px',
					paddingBottom: '12px',
					paddingLeft: '30px',
					paddingRight: '30px',
					borderRadius: '10px',

					"&:hover": {
						boxShadow: boxShadow1,
					},
					
					"&.Mui-disabled": {
						color: '#fff',
						opacity: '0.5',
					}
				},
				outlined: {
					background: 'transparent',
					color: '#BDBFC2',
					border: 'solid 1px #BDBFC2',
					width: 'auto',
					fontSize: '1.125rem',
					paddingTop: '10px',
					paddingBottom: '9px',

					'&:hover': {
						borderColor: '#999',
						background: 'transparent',
					},

					'&.Mui-disabled': {
						color: '#BDBFC2',
					}
				},
			},
			variants: [
				{
					props: { variant: 'icon', },
					style: {
						paddingLeft: '0px', paddingRight: '0px',
						width: '50px',
						background: 'transparent',
						border: 'solid 1px ' + COLORS.GRAY_LIGHT ,
						color: '#BDBFC2',
						minWidth: 46,
					},
				},
				{
					props: { variant: 'transparent', },
					style: {
						background: 'transparent',
						color: COLORS.GRAY
					}
				},
				{
					props: { variant: 'white' },
					style: {
						background: 'white',
						boxShadow: boxShadow1,

						'&:hover': {
							background: 'white',
							opacity: 0.95,
						}
					}
				},
				{
					props: { variant: 'secondary' },
					style: {
						width: 'auto',
						marginRight: '0',
						fontSize: '1.125rem',
						paddingTop: '9px',
						paddingBottom: '9px',
					}
				},
				{
					props: { variant: 'outlinedGradient' },
					style: {
						background: gradient,
						border: 'none',
						color: '#2C87FF',
						zIndex: '0',
						width: 'auto',
						fontSize: '1.125rem',
						paddingTop: '12px',
						paddingBottom: '12px',

						"&:hover": {
							border: 'none',
						},

						"&:before": {
							content: '""',
							position: 'absolute',
							left: 1,
							top: 1,
							display: 'block',
							width: 'calc(100% - 2px)',
							height: 'calc(100% - 2px)',
							background: '#fff',
							borderRadius: '9px',
							zIndex: '-1',
						}
					}
				}
			]
		},
		MuiPaginationItem: {
			styleOverrides: {
				root: {
					background: COLORS.WHITE,
					borderColor: COLORS.GRAY_BORDER,
					minWidth: 44, height: 44,

					'&.Mui-selected': {
						background: COLORS.BLUE_BRAND_PRIMARY,
						borderColor: COLORS.BLUE_BRAND_PRIMARY,
						color: COLORS.WHITE,

						'&:hover': {
							background: COLORS.BLUE_BRAND_PRIMARY_DARK,
							borderColor: COLORS.BLUE_BRAND_PRIMARY_DARK,
						}
					},

					'&.MuiPaginationItem-previousNext': {
						borderColor: COLORS.WHITE,
					}
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				// tooltip: {
				// 	backgroundColor: '#fff',
				// 	boxShadow: boxShadow2,
				// },
				// arrow: {
				// 	color: '#fff',
				// }
			}
		},
	},
});

export default theme;