import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function AccountIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path d="M7.26367 7.21541C7.26367 4.45158 9.50419 2.21106 12.268 2.21106C15.0318 2.21106 17.2724 4.45158 17.2724 7.21541C17.2724 9.97923 15.0318 12.2198 12.268 12.2198C9.50419 12.2198 7.26367 9.97923 7.26367 7.21541Z" />
			<path d="M5.90644 14.6537C7.59082 13.9628 9.76036 13.3594 11.8385 13.3594C13.9166 13.3594 16.0861 13.9628 17.7705 14.6537C18.6164 15.0007 19.3584 15.3772 19.9226 15.7262C20.2039 15.9002 20.452 16.074 20.65 16.2408C20.8283 16.391 21.0365 16.5926 21.1514 16.8325C21.2677 17.0756 21.3484 17.3919 21.4075 17.6777C21.4696 17.9785 21.5197 18.3064 21.5586 18.6046C21.5977 18.9041 21.6267 19.1816 21.6459 19.3838C21.6555 19.4851 21.6627 19.568 21.6676 19.626L21.673 19.6937L21.6745 19.712L21.6748 19.717L21.675 19.7185L20.9771 19.7709C21.675 19.7192 21.675 19.7185 21.675 19.7185C21.6762 19.7357 21.6769 19.7536 21.6769 19.7709C21.6769 20.9078 20.7267 21.789 19.6088 21.789H4.06812C2.95024 21.789 2 20.9078 2 19.7709C2 19.7536 2.00064 19.7364 2.00191 19.7192L2.00246 19.712L2.00388 19.6937L2.00936 19.626C2.01419 19.568 2.02139 19.4851 2.03101 19.3838C2.0502 19.1816 2.07918 18.9041 2.11832 18.6046C2.15727 18.3065 2.2073 17.9785 2.26947 17.6777C2.32855 17.3919 2.40921 17.0756 2.52555 16.8325C2.64043 16.5926 2.84865 16.391 3.02695 16.2408C3.22497 16.074 3.47297 15.9002 3.75429 15.7262C4.31853 15.3772 5.06049 15.0007 5.90644 14.6537Z" />
		</SvgIcon>
	)
}

export default AccountIcon;