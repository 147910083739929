import { isNil } from "lodash";

export interface IValidation {
	name: string;
    options: any
}

const findValidation = (validation: string, validations: IValidation[]) => {
	if (!validation || !validations){
		return null
	}
	return validations.find((x) => x.name === validation)
}

export enum VALIDATIONS {
	REQUIRED = 'required',
	LESS_THAN = 'less_than',
	LESS_THAN_EQUAL_TO = 'less_than_equal_to',
	MORE_THAN_EQUAL_TO = 'more_than_equal_to',	
}

export const validateAttribute = (attribute: string, val: any, validations: IValidation[], errors: any, setErrors: Function) => {
	//console.log("called?", attribute, val, validations, errors)
	//console.log("the val", val);
	let errStrings = [];
	const required = findValidation('required', validations)
	if (required && val === '' || isNil(val)) {
		errStrings.push('Required')
	}
	const lessThanEqualTo = findValidation(VALIDATIONS.LESS_THAN_EQUAL_TO, validations)
	if (lessThanEqualTo && val > lessThanEqualTo.options.max) {
		errStrings.push('Must be less than or equal to ' + lessThanEqualTo.options.max)
	}
	const moreThanOrEqualTo = findValidation(VALIDATIONS.MORE_THAN_EQUAL_TO, validations)
	if (moreThanOrEqualTo && val < moreThanOrEqualTo.options.min) {
		errStrings.push('Must be more than or equal to ' + moreThanOrEqualTo.options.min)
	}
	let errString = ''
	if (errStrings.length) {
		errString = errStrings.join(', ')
	}
	let setVal = {
		...errors,
		[attribute]: errString
	}
	setErrors(setVal)
	if (errString !== ''){
		return {
			isValid: false,
			errors: setVal
		}
	}
	return {
		isValid: true,
		errors: setVal
	}
}

export const validateForm = (obj: any, validations: any, errors: any, setErrors: Function) => {

	let isValid = true;
	let errObj = errors;
	for (const [key, value] of Object.entries(obj)) {
		const valIsValid = validateAttribute(key, value, validations[key], errObj, setErrors);
	    errObj = valIsValid.errors
		if (!valIsValid.isValid){
			isValid = false
		}
	}
	return isValid;
}

export const phoneNumberFormat = (value: string, previousValue: string) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {

      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
};