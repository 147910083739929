import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, CardContent, Chip, FormControlLabel, FormGroup, IconButton, Switch, Tooltip, Typography, } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import Breadcrumbs from '../../../components/shared/Breadcrumbs';
import { useDispatch } from 'react-redux';
import { getAllResources, getCancerCenters, getCancerTypes, getResourceCategories, getTreatmentPhases } from '../../../store/actions/resourceActions';
import MaterialTable, { MTableBodyRow, Query, QueryResult } from '@material-table/core';
import { tableIcons } from '../../../components/shared/MaterialTableShared';
import Edit from '@mui/icons-material/Edit';
import { COLORS } from '../../../enums/layout-enums';
import Loading from '../../../components/general/Loading';
import TrustedEmailDomainForm from '../../../components/shared/TrustedEmailDomainForm';
import { deleteTrustedEmailDomains, getTrustedEmailDomains } from '../../../store/actions/trustedDomainsActions';
import { format } from 'date-fns'
import { Delete } from '@mui/icons-material';
import SuspendResource from '../../../components/shared/SuspendResource';
import { Modal } from '@mui/material';
import { useSnackbar } from 'notistack';
import { DEFAULT_DATE_FORMATE } from '../../../shared/constants/app-constants';

const iconStyle = {
	color: COLORS.BLUE_BRAND_PRIMARY,
	cursor: 'pointer',
};

export default function EmailManagementPage(props: any) {

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
  	const tableRef =  useRef<any>(null);
	  
	const [fetching, setFetching] = useState<boolean>(false);
	const [openModal, setModalOpen] = useState<boolean>(false);
	const [editableData, setEditableData] = useState<any>(null);

	const [recordId, setRecordId] = useState<number | null>(null);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

	const columns = [
		{
			title: 'Domain',
			field: 'domain',
			sorting: false,
		},
		{
			title: 'Created At',
			render: (row:any) => format(new Date(row?.created_at), DEFAULT_DATE_FORMATE),
			sorting: false,
		},
		{
			title: 'Actions',
			render: (row: any) => {
			  return (
				<>
					{/* {row.totalCount > 1 &&  */}
					<Tooltip title={'Delete'} placement="top">
						<IconButton onClick={() => {
							setRecordId(row.id);
							setShowConfirmModal(true);
						}}>
							<Delete style={iconStyle} />
						</IconButton>
					</Tooltip>
					 {/* } */}
					<Tooltip title={'Edit'} placement="top">
						<IconButton onClick={() => {onClickEdit(row)}}>
							<Edit style={iconStyle} />
						</IconButton>
					</Tooltip>
				</>
			  );
			},
		  },
	];

	// const components =  {
	// 	Action: (props:any) => {
	// 	  return (<FormGroup>
	// 		<FormControlLabel
	// 		  control={<Switch defaultChecked onChange={(event) => setShowSuspended(event?.target?.checked)} checked={showSuspended} />}
	// 		  label="Include suspended"
	// 		  labelPlacement="start"
	// 		  sx={{ textTransform:'capitalize' }}
	// 		/>
	// 	  </FormGroup>)
	// 	},
	// }

	const actions: any = [
		{
		  icon: Edit,
		  tooltip: 'Refresh Data',
		  isFreeAction: true,
		  onClick: () => alert("HeHe")
		}
	]

	const handleTableDataQuery  = async (query: Query<object>): Promise<any> =>
		new Promise((resolve, reject) => {

			const {page, pageSize, search} = query;

			dispatch(getTrustedEmailDomains(
				{
					body:{
						skip: (page) * pageSize,
						take: pageSize,
						search: search,
					}
				},
				(data) => {
					resolve({
						data: data.data.map(((item: any) => ({...item, totalCount: data.total}))),
						page: page,
						totalCount: data.total,
					});
				},
				(error) => {
				return error
				},
			))
	})

	const updateTable = () => {
		// Custom table
		// tableRef?.current && tableRef?.current.fetch();

		// Material table
		tableRef?.current && tableRef.current.onQueryChange();
	}

	const onClickAdd = () => {
		setModalOpen(true)
	}

	const onClickEdit = (row: any) => {
		setEditableData(row);
		setModalOpen(true);
	}

	const closeModal = () => {
		setModalOpen(false);
		setEditableData(null);
	}

	const deleteRecordNo = () => {
		setRecordId(null);
		setShowConfirmModal(false);
	}

	const deleteRecordYes = () => {
		dispatch(deleteTrustedEmailDomains(
			{ body: {id:recordId}},
			() => {
				enqueueSnackbar("Domain deleted successfully", { variant: 'success' });
				updateTable();
				setRecordId(null);
				setShowConfirmModal(false);
			},
			(exception) => {
				enqueueSnackbar(exception.message ??"Failed to remove domain", { variant: 'error' });
				setShowConfirmModal(false);
			},
		))
	}

	return (

		<>
			{fetching && <Loading loading={fetching} />}

			<Breadcrumbs title={"Trusted Email Domains"} />
			<Box display={"flex"} alignItems={"center"}>
				<h1 className='pageTitle'>Trusted Email Domains</h1>
				<Button 
					className='pageSubTitle' 
					sx={{ marginLeft: "20px" }} 
					startIcon={<AddBoxOutlinedIcon />}
					onClick={onClickAdd}
				>Add</Button>
			</Box>

			<Box marginTop={"35px"}>

				<MaterialTable
					icons={tableIcons}
					tableRef={tableRef}
					title={"Domains"}
					columns={columns}
					data={handleTableDataQuery}
					options={{
						sorting: false,
						search: true,
						// pageSizeOptions: TABLE_DEFAULT_PAGE_SIZES,
						pageSizeOptions:[10, 15, 20],
						toolbar: true,
						// showTitle: false
					}}
					// actions={actions}
					// components={components}
				/>

				{openModal && <TrustedEmailDomainForm
					data={editableData}
					onSuccess={updateTable}
					openModal={openModal}
					closeModal={closeModal}
				/>}

			</Box>

			{ showConfirmModal && 
				<Modal
					open={true}
					onClose={deleteRecordNo}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<SuspendResource 
						title={'Delete Domain'}
						message={'Do you want to delete this domain?'} 
						handleSuspendYes={deleteRecordYes} 
						handleSuspendClose={deleteRecordNo} 
					/>
				</Modal>}

		</>

	)
}