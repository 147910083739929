import * as React from 'react';
// import {
// 	Typography
// } from "@mui/material";
// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
// import Toolbar from '@mui/material/Toolbar';
import Login from './pages/public/auth/LoginPage';
import Homepage from './pages/public/auth/Homepage';
import KitchenSink from './pages/public/KitchenSink';
import NavigatorLogin from './pages/public/auth/NavigatorLoginPage';
import PhoneLogin from './pages/public/auth/PhoneLoginPage';
import ForgotPassword from './pages/public/auth/ForgotPasswordPage';
import ResetPassword from './pages/public/auth/ResetPasswordPage';
import ResetPasswordNextStep from './pages/public/auth/ResetPasswordNextStepPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import Privacy from './pages/public/privacy/PrivacyPage';
import Terms from './pages/public/terms/Terms';
import VerifyEmail from './pages/private/site/VerifyEmail';

import AuthWrap from './components/shared/AuthWrap';
import LocusLogin from './pages/public/auth/LocusLoginPage';

function AppPublic(props: any) {

	return (
		<Box>
			<CssBaseline />

			<Box component="main" sx={{ width: '100%' }}>

				<Routes>
					<Route path="/" element={<AuthWrap><NavigatorLogin /></AuthWrap>} />
					<Route path="/LocusLogin" element={<LocusLogin />} />
					<Route path="UI-Kit" element={<KitchenSink />} />

					<Route path="NavigatorLogin" element={
						<AuthWrap>
							<NavigatorLogin />
						</AuthWrap>
					} />
					
					<Route path="Admin" element={
						<AuthWrap>
							<Login />
						</AuthWrap>
					}/>

					<Route path="ForgotPassword" element={
						<AuthWrap>
							<ForgotPassword />
						</AuthWrap>
					} />

					<Route path="ResetPassword" element={ 
						<AuthWrap>
							<ResetPassword />
						</AuthWrap>
					} />

					<Route path="ResetPasswordNextStep" element={
						<AuthWrap>
							<ResetPasswordNextStep />
						</AuthWrap>
					} />
					<Route path="PhoneLogin" element={
						<PhoneLogin />
					} />
					<Route path="VerifyEmail" element={
						<VerifyEmail />
					} />
					<Route path="TestPhone" element={
						<div>
							<div onClick={() => window.open("acensahealth://open", "_blank")} style={{ cursor: 'pointer', width: '100%', textAlign: 'center' }}>
								LAUNCH APP 1
							</div>
							<br /><br />
							<a href="acensahealth://open">LAUNCH APP 2</a>
						</div>} />
					<Route path="Terms" element={<Terms />} />

					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</Box>
		</Box>
	)

}

export default AppPublic