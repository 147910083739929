import React, { useMemo, useState } from 'react';
import {
	Box,
	Button,
	Card,
	Typography,
} from '@mui/material';

import theme from '../../../themes/theme';

import IconBlock from '../IconBlock';
import CloseButton from '../CloseButton';
import ResourceBoardBlock from './ResourceBoardBlock';

import { COLORS } from '../../../enums/layout-enums';

import { useNavigate } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { useSelector } from '../../../store';
import { IRootState } from '../../../interfaces/store';
import { categorytoIcon } from '../../../shared/constants/category-icons';
// @ts-ignore
import CopyToClipboard from "react-copy-html-to-clipboard";
import { copyResourceBoard } from '../../../store/actions/resourceActions';
import { useDispatch } from 'react-redux';
import FancyButton from '../Button/Button';
import { convertResourcesToCopyHtml } from '../../../utils/general';

export default function ResourceBoard(props: any) {
	
	const history = useNavigate();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const resourceBoardItems: any = useSelector((state: IRootState) => {
		return Object.values(state.main.boardNchipResource).reduce((prev:any, curr:any) => {

			const {boardCancerType, boardTreatmentPhase, boardCancerCenter, boardCategory} = state.main;
			const resource = state.main.nchipResource[curr.resource_id];

			if(resource) {
				curr['resource'] = resource;
				if(boardCategory) curr['category'] = boardCategory?.[resource?.category_id];
				if(boardCancerType) curr['cancerType'] = boardCancerType?.[resource?.cancer_type_id];
				if(boardTreatmentPhase) curr['treatmentPhase'] = boardTreatmentPhase?.[resource?.treatment_phase_id];
				if(boardCancerCenter) curr['cancerCenter'] = boardCancerCenter?.[resource?.cancer_center_id];
				prev.push(curr);
			}

			return prev;
		}, [])
	});

	const [resources, setResources] = useState([
		{
			id: 1,
			category: 'house',
			color: COLORS.ORANGE,
			title: 'Lorem ipsum dolor sit amet, dolor consetetur elitr',
		},
		{
			id: 2,
			category: 'baby',
			color: COLORS.PINK,
			title: 'Sulempur docnor let, dolor consetetur elitr',
		},
		{
			id: 3,
			category: 'diet',
			color: COLORS.GREEN,
			title: 'Dolor consetetur elitr, ipsum dolor sit amet, elitr',
		},
	]);

	const handleLinkResourceBoard = () => {
		history('/resource-board');
		props.showResourceBoard(false);
	}

	const html2clipboard = (html: any, cb: any) => {
		// var tmpEl = document.createElement("div");
		// tmpEl.innerHTML = html;
		// document.body.appendChild(tmpEl);

		// var range = document.createRange();
		// range.selectNode(tmpEl);

		// const selection = window.getSelection();

		// console.log({ selection })
		// if(selection !== null) {
		// 	selection.addRange(range);
		// 	document.execCommand("copy");
		// 	document.body.removeChild(tmpEl);
		// }

		if(cb) cb?.();
	}

	const handleCopyResourceBoard = () => {
		let copyString = '<div style="border-radius: 10px; background: #F5F9FE; padding: 20px;"><p style="font-size: 0.875rem; margin: 0; padding: 0;">These resources were gathered by your navigator from the <b> Tern Cancer Resource Database</b>.</p></div>';
		resources.map((resource, index) => {
			let category = (resource.category[0].toUpperCase()) + (resource.category.slice(1));

			if(index === 0) { copyString += '<div style="padding: 20px; border-radius: 10px; border: solid 1px #dddddd; margin-top: 10px;">'; }
			copyString += '<div><a href="/resources/' + resource.id + '" style="color: #3683EF; text-decoration: none; font-weight: bold; font-size: 1.125rem;">' + resource.title + '</a><br /><span style="font-size: 0.875rem"><strong>Category:</strong> ' + category + '</span></div>';
			if(resources.length > (index + 1)) { copyString += '<hr style="height: 1px; background: #ddd;" />'; }
		});
		if(resources.length) { copyString += '</div>'; }


		html2clipboard(copyString, () => enqueueSnackbar("Resources Copied to Clipboard", { variant: "success" }));
	}

	const dataToBeCopy = useMemo(() => {
		return convertResourcesToCopyHtml(resourceBoardItems);
	}, [resourceBoardItems])

	const onCopyToClicpBoard = (text:any, result:any) => {
		enqueueSnackbar("Resources Copied to Clipboard", { variant: "success" })
		dispatch(copyResourceBoard({}))
	}

	return (

		<Box sx={{
			position: 'absolute',
			zIndex: 101,
			right: 0,
			top: 46,
			boxShadow: 2,
			minWidth:"300px",
			maxWidth:"500px"
		}}>
			<Card>
				<Box sx={{ p: 2, }}>


					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						maxHeight: '300px',
						overflow: 'auto'
					}}>
						{resourceBoardItems.map((boardItem: any) => {
							return (
								<ResourceBoardBlock
									title={boardItem?.resource?.title}
									key={boardItem.id}
									id={boardItem?.resource?.id}
									icon={categorytoIcon(boardItem?.category?.name)?.icon as string}
									color={categorytoIcon(boardItem?.category?.name)?.color as string}
								/>
							)
						})}
					</Box>
					
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
					}}>
						
						{resourceBoardItems.length > 0 && 
							<CopyToClipboard text={`${dataToBeCopy}`} onCopy={onCopyToClicpBoard} options={{ asHtml: true }} >
								<FancyButton
									variant="contained"
									style={{ width: '100%', marginBottom:'20px' }}
								>Copy</FancyButton>
							</CopyToClipboard>
						}
						<FancyButton
							variant="outlined"
							onClick={handleLinkResourceBoard}
							style={{ width: '100%' }}
						>Take Me To Resource Board</FancyButton>

					</Box>
				</Box>
			</Card>
		</Box>

	)
}