import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SuspendIcon(props: any) {

	return (
		<SvgIcon {...props} sx={{ width: 14, height: 14, }} viewBox="0 0 14 14">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M2.44444 1.5C1.92284 1.5 1.5 1.92284 1.5 2.44444V12.5556C1.5 13.0772 1.92284 13.5 2.44444 13.5H12.5556C13.0772 13.5 13.5 13.0772 13.5 12.5556V2.44444C13.5 1.92284 13.0772 1.5 12.5556 1.5H2.44444ZM0.5 2.44444C0.5 1.37056 1.37056 0.5 2.44444 0.5H12.5556C13.6294 0.5 14.5 1.37056 14.5 2.44444V12.5556C14.5 13.6294 13.6294 14.5 12.5556 14.5H2.44444C1.37056 14.5 0.5 13.6294 0.5 12.5556V2.44444ZM4.11111 7.5C4.11111 7.22386 4.33497 7 4.61111 7H10.3889C10.665 7 10.8889 7.22386 10.8889 7.5C10.8889 7.77614 10.665 8 10.3889 8H4.61111C4.33497 8 4.11111 7.77614 4.11111 7.5Z" />
		</SvgIcon>
	)
}

export default SuspendIcon;