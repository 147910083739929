import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function EditIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path d="M20 2.87866C19.7026 2.87866 19.4174 2.9968 19.2071 3.20709L9.90296 12.5112L9.37437 14.6256L11.4888 14.097L20.7929 4.79288C21.0032 4.58259 21.1213 4.29737 21.1213 3.99998C21.1213 3.70259 21.0032 3.41738 20.7929 3.20709C20.5826 2.9968 20.2974 2.87866 20 2.87866ZM17.7929 1.79288C18.3783 1.20751 19.1722 0.878662 20 0.878662C20.8278 0.878662 21.6217 1.20751 22.2071 1.79288C22.7925 2.37824 23.1213 3.17216 23.1213 3.99998C23.1213 4.82781 22.7925 5.62173 22.2071 6.20709L12.7071 15.7071C12.5789 15.8352 12.4184 15.9262 12.2425 15.9701L8.24254 16.9701C7.90176 17.0553 7.54127 16.9555 7.29289 16.7071C7.04451 16.4587 6.94466 16.0982 7.02986 15.7574L8.02986 11.7574C8.07382 11.5816 8.16473 11.421 8.29289 11.2929L17.7929 1.79288ZM1.87868 3.87866C2.44129 3.31605 3.20435 2.99998 4 2.99998H11C11.5523 2.99998 12 3.4477 12 3.99998C12 4.55227 11.5523 4.99998 11 4.99998H4C3.73478 4.99998 3.48043 5.10534 3.29289 5.29288C3.10536 5.48041 3 5.73477 3 5.99998V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V20C21 20.7956 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H4C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7956 1 20V5.99998C1 5.20433 1.31607 4.44127 1.87868 3.87866Z" />
		</SvgIcon>
	)
}

export default EditIcon;