import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ClockIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M7.30421 2.98877C4.93042 5.36217 4.63811 9.83 6.678 12.5502L8.19958 14.7475L12.0694 20.3359L17.4527 12.562C19.5016 9.83039 19.2093 5.36217 16.8355 2.98877C15.5623 1.71595 13.87 1.0148 12.0698 1.0148C10.2697 1.0148 8.57703 1.71595 7.30421 2.98877ZM7.78839 15.5322H4.79309L1.243 22.9871H22.757L19.2069 15.5322H16.3501L12.0694 21.7146L7.78839 15.5322ZM14.8856 7.68403C14.8856 9.19816 13.6532 10.4306 12.1391 10.4306C10.6246 10.4306 9.39258 9.19816 9.39258 7.68403C9.39258 6.16991 10.625 4.9375 12.1391 4.9375C13.6532 4.9375 14.8856 6.16991 14.8856 7.68403ZM14.1009 7.68403C14.1009 6.60229 13.2209 5.72222 12.1391 5.72222C11.0574 5.72222 10.1773 6.60229 10.1773 7.68403C10.1773 8.76578 11.0574 9.64584 12.1391 9.64584C13.2209 9.64584 14.1009 8.76578 14.1009 7.68403Z" />
		</SvgIcon>
	)
}

export default ClockIcon;