import React, { ChangeEvent, useState } from 'react';
import {
	Box,
	Input,
	InputLabel,
	Select,
	Typography,
} from '@mui/material';

import ArrowDownSmallIcon from '../../icons/ArrowDownSmallIcon';
import PaperclipIcon from '../../icons/PaperclipIcon';

export default function FormUploadField(props: any) {
	const [fileData, setfileData] = useState("");
	const [filename, setFilename] = useState("");

	const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}
	};

	return (

		<Box>
			<InputLabel>{props.label}
				<Box sx={{ mt: 1, }}>
					<Typography sx={{
						fontSize: '1rem',
					}}>
						<PaperclipIcon />
						Attachments:
						<Typography
							sx={{
								cursor: 'pointer',
								ml: 1,
							}}
							variant="gradient"
						>
							Choose file
							<input type="file" hidden onChange={handleFileUpload} />
						</Typography>
					</Typography>
				</Box>
			</InputLabel>
		</Box>

	)
}