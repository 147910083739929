import { FC } from "react";
import PropTypes from "prop-types";
import classes from './Button.module.css';
import { Button } from "@mui/material";

enum BUTTON_TYPES {
    CONTAINED = 'contained',
    OUTLINED = 'outlined',
    MATERIAL = 'material',
}

const FancyButton: FC<any> = ({ children, variant, onClick, style, disabled, ...rest }) => {
    return (
        <>
            {variant !== BUTTON_TYPES.MATERIAL && 
                <button disabled={disabled} className={`${classes.base} ${variant === BUTTON_TYPES.CONTAINED ? classes.contained : classes.outline}`} onClick={onClick} style={style}>
                    {variant === BUTTON_TYPES.CONTAINED && children}
                    {variant === BUTTON_TYPES.OUTLINED && <span className={classes.text}>{children}</span>}
                </button>
            }

            {variant === BUTTON_TYPES.MATERIAL && 
                <Button 
                    onClick={onClick} 
                    style={style} 
                    disabled={disabled} 
                    classes={{ root: classes.outlineHoverOnly }}
                    {...rest}
                ><span className={classes.outlineTextHoverOnly}>{children}</span></Button>
            }

        </>
    )
}
FancyButton.propTypes = {
    variant: PropTypes.oneOf([BUTTON_TYPES.CONTAINED, BUTTON_TYPES.OUTLINED]),
    disables: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
}

FancyButton.defaultProps = {
    variant: BUTTON_TYPES.CONTAINED,
    disabled: false,
    onClick: () => {},
    style: {}
}

export default FancyButton;