import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function InboxIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M7.24 3L16.76 3C17.3181 3.0003 17.8656 3.15628 18.34 3.45042C18.8139 3.7443 19.1965 4.16444 19.4449 4.66368C19.4451 4.66412 19.4453 4.66456 19.4455 4.665L22.8942 11.5523C22.9638 11.6913 23 11.8446 23 12V18C23 18.7957 22.6839 19.5587 22.1213 20.1213C21.5587 20.6839 20.7957 21 20 21H4C3.20435 21 2.44129 20.6839 1.87868 20.1213C1.31607 19.5587 1 18.7956 1 18V12C1 11.8446 1.03624 11.6913 1.10583 11.5523L4.55447 4.665C4.55471 4.66451 4.55495 4.66403 4.5552 4.66354C4.80358 4.16436 5.18617 3.74427 5.66004 3.45042C6.13436 3.15628 6.68187 3.0003 7.24 3ZM7.24034 5C7.05436 5.00013 6.87211 5.05213 6.71405 5.15014C6.55594 5.24819 6.42832 5.38839 6.34553 5.555L6.34417 5.55773L3.61908 11H8C8.33435 11 8.64658 11.1671 8.83205 11.4453L10.5352 14H13.4648L15.1679 11.4453C15.3534 11.1671 15.6656 11 16 11H20.3809L17.6558 5.55773L17.6545 5.555C17.5717 5.38839 17.4441 5.24818 17.2859 5.15014C17.1279 5.05213 16.9456 5.00013 16.7597 5H7.24034ZM21 13H16.5352L14.8321 15.5547C14.6466 15.8329 14.3344 16 14 16H10C9.66565 16 9.35342 15.8329 9.16795 15.5547L7.46482 13H3V18C3 18.2652 3.10536 18.5196 3.29289 18.7071C3.48043 18.8946 3.73478 19 4 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V13Z" />
		</SvgIcon>
	)
}

export default InboxIcon;