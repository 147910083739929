import * as React from 'react';
import {
	Box,
	Breadcrumbs,
	Link,
	Grid,
	Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
	event.preventDefault();
	// console.info('You clicked a breadcrumb.');
}

export default function BasicBreadcrumbs(props: any) {
	const history = useNavigate();

	const style = {
		link: {
			fontSize: '0.875rem',
			color: '#BDBFC2',
			textDecoration: 'none',
			cursor: 'pointer',
		},
		current: {
			fontSize: '0.875rem',
			textDecoration: 'none',
			cursor: 'default',
		},
	};

	const handleClickHome = () => {
		history('/');
	}

	return (
		
		<Grid container sx={{ pt: '22px', pb: '10px', }}>
			<Grid item>
				<Box role="presentation" onClick={handleClick}>
				  <Breadcrumbs separator="›" color="#BDBFC2" aria-label="breadcrumb" sx={{ alignItems: 'center', }}>
					<Link sx={style.link} onClick={handleClickHome}>Home</Link>
					{props?.pages?.map(function(page: any) {
						return (

							<Link sx={style.link} href={page?.url} key={page?.url}>{page?.title}</Link>

						)
					})}
					{props?.title && <Link sx={style.current} color="primary"> <Typography sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width:"350px"}}>{props?.title}</Typography></Link>}
				  </Breadcrumbs>
				</Box>
			</Grid>
		</Grid>

	)
}