import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function ArrowLeftIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M7.70711 7.29289C8.09763 7.68342 8.09763 8.31658 7.70711 8.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L7.70711 15.2929C8.09763 15.6834 8.09763 16.3166 7.70711 16.7071C7.31658 17.0976 6.68342 17.0976 6.29289 16.7071L2.29329 12.7075C2.29316 12.7074 2.29303 12.7072 2.29289 12.7071C2.29219 12.7064 2.29148 12.7057 2.29078 12.705C2.19595 12.6096 2.12432 12.4999 2.07588 12.3828C2.02699 12.2649 2 12.1356 2 12C2 11.8644 2.02699 11.7351 2.07588 11.6172C2.12468 11.4993 2.19702 11.3888 2.29289 11.2929L6.29289 7.29289C6.68342 6.90237 7.31658 6.90237 7.70711 7.29289Z" />
		</SvgIcon>
	)
}

export default ArrowLeftIcon;