import React, { forwardRef, useRef } from 'react';
import {
	Box,
	MenuItem,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	Pagination,
	PaginationItem,
	TableSortLabel,
	Typography,
} from '@mui/material';
import MaterialTable, { Query } from '@material-table/core';
import { tableIcons } from '../MaterialTableShared';
import { useDispatch } from 'react-redux';
import { getResourceActivityLogs } from '../../../store/actions/resourceActions';
import { format } from 'date-fns'
import { DEFAULT_DATE_FORMATE } from '../../../shared/constants/app-constants';
import { capitalize } from '../../../utils/general';

const ResourceActivityTable = forwardRef(({ resourceId }: any, ref:any=null) => {

	const dispatch = useDispatch();
	
	const columns = [
		{
			title: 'Date & Time',
			field: 'id',
			render: (row:any) => format(new Date(row?.created_at), `${DEFAULT_DATE_FORMATE} hh:mma`)
		},
		{
			title: 'Account',
			render: (row:any) => (row?.user?.email)
		},
		{
			title: 'Changes',
			render: (row: any) => {
			  return (
				<>
					{Object.entries(row?.data).map(([module, update]: any) => {
						return(
							<div style={{ maxHeight:'150px', maxWidth:'800px', overflowX:'auto', overflowY:'auto' }}>
								{module === 'copied' && <div>Copied</div>}
								{module === 'suspended' && <div>Suspended</div>}
								{module === 'reactivated' && <div>Reactivated</div>}
								{module === 'fileName' && <div>New file uploaded {update}</div>}
								{module !== 'copied' && 
								 module !== 'suspended' && 
								 module !== 'reactivated' && 
								 module !== 'fileName' && <div>
									<p style={{margin:0}}>{capitalize(module)} <b>changed to:</b> {update.new}</p>
									{/* <p style={{marginBottom: '5px'}}>{update.old} <b>to</b> {update.new}</p> */}
								</div>}
							</div>
						)
					})}
				</>
			  );
			},
		  },
	];

	const handleTableDataQuery  = async (query: Query<object>): Promise<any> =>
		new Promise((resolve, reject) => {

			const {page, pageSize, search} = query;

			dispatch(getResourceActivityLogs(
				{
					body:{
						skip: (page) * pageSize,
						take: pageSize,
						search: search,
					},
					id: resourceId
				},
				(data) => {
					resolve({
						data: data.data,
						page: page,
						totalCount: data.total,
					});
				},
				(error) => {
					return error
				},
			))
	});

	return (

		<Box>
			<MaterialTable
				tableRef={ref}
				icons={tableIcons}
				title={"Resources"}
				columns={columns}
				data={handleTableDataQuery}
				options={{
					sorting: false,
					search: false,
					// pageSizeOptions: TABLE_DEFAULT_PAGE_SIZES,
					pageSizeOptions:[10, 15, 20],
					toolbar: false,
					// showTitle: false
					rowStyle: {
						fontSize: 14,
					},
				}}
			/>
		</Box>

	)
})

export default ResourceActivityTable;