import React, { useState } from 'react';
import { Box, Card, Modal, Typography, } from '@mui/material';
import { ClickAwayListener } from '@mui/base';

import EditUserForm from '../EditUserForm';
import SuspendResource from '../SuspendResource';

import MoreHorizontalIcon from '../../icons/MoreHorizontalIcon';
import TrashIcon from '../../icons/TrashIcon';
import SuspendIcon from '../../icons/SuspendIcon';
import { useDispatch } from 'react-redux';
import { suspendResource } from '../../../store/actions/resourceActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export default function ResourceEditMenu(props: any) {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation()
	const { enqueueSnackbar } = useSnackbar();

	const [open, setOpen] = useState(false);
	const [openSuspend, setOpenSuspend] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);

	const handleSuspendYes = () => {
		dispatch(suspendResource(
			{ body:{active: !props.currentValue }, id:props.resourceId },
			(response) => {

				enqueueSnackbar(!props.currentValue ? 'Resource reactivated successfully' : 'Resource suspended successfully', { variant: "success" });

				if(location.pathname === '/resources'){
					handleSuspendClose();
					props.onSuspendResource?.();
					return;
				}

				return navigate('/resources');
			},
			(error) => {
				enqueueSnackbar(!props.currentValue ? 'Failed to reactive resource' : 'Failed to suspended resource', { variant: "error" });
			},
		))
	}

	const handleToggleMenu = () => {
		setOpen((prev) => !prev);
	}

	const handleCloseMenu = () => {
		setOpen(false);
	}

	const handleSuspendClose = () => {
		setOpenSuspend(false);
	}
	const handleSuspendOpen = () => {
		setOpenSuspend(true);
	}

	const style = {
		menu: {
			position: 'absolute',
			top: 30, right: -8,
			zIndex: 101,
			width: 120,
		},
		card: {
			paddingTop: '10px',
			paddingBottom: '10px',
		},
		link: {
			display: 'flex',
			alignItems: 'center',
			padding: '5px 20px',
			cursor: 'pointer',
			transition: '0.3s ease background',

			'&:hover': {
				background: '#F5F9FE',
			}
		},
		linkText: {
			fontSize: '0.875rem',
			ml: 1,
		}
	};

	return (

		<Box sx={{ position: 'relative', }}>
			<MoreHorizontalIcon sx={{ cursor: 'pointer', }} color={(open) ? "primary" : "gray"} onClick={handleToggleMenu} />
			{(open) ? (

				<ClickAwayListener onClickAway={handleCloseMenu}>
					<Box sx={style.menu}>
						<Card sx={style.card}>

							<Box sx={style.link} onClick={handleSuspendOpen}>
								<SuspendIcon color="gray" />
								<Typography sx={style.linkText}>{props.currentValue === true ? 'Suspend' : 'Reactivate'}</Typography>
							</Box>
							<Modal
								open={openSuspend}
								onClose={handleSuspendClose}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<SuspendResource message={props.currentValue === true ? 'Do you want to suspend this resource?' : 'Do you want to reactivate this resource?'} user={props.users} handleSuspendYes={handleSuspendYes} handleSuspendClose={handleSuspendClose} />
							</Modal>

							{/* <Box sx={style.link} onClick={handleEditOpen}>
								<TrashIcon color="gray" />
								<Typography sx={style.linkText}>Delete</Typography>
							</Box> */}

						</Card>
					</Box>
				</ClickAwayListener>
			): ''}
		</Box>

	)
}