import { normalize, schema } from 'normalizr';
import { all, call, put } from 'redux-saga/effects';
import HttpClient from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import GetFriendlyError from '../../utils/general';
import { roleSchema, userRoleSchema } from './schema';
const client = new HttpClient();

async function getUserRolesByUserAPI(
  data: any,
): Promise<HttpResponse<{ x: string }>> {
  return client.get('api/auth/user_roles', data);
}

async function loginAPI(
  data: any,
): Promise<HttpResponse<{ x: string }>> {
  return client.post('api/auth/login', data);
}

async function requestPasswordResetAPI(
  data: any,
): Promise<HttpResponse<{ x: string }>> {
  return client.post('api/auth/request-password-reset', data);
}

async function resetPasswordAPI(
  data: any,
): Promise<HttpResponse<{ x: string }>> {
  return client.post('api/auth/reset-password', data);
}

async function navigatorLoginAPI(
  data: any,
): Promise<HttpResponse<{ x: string }>> {
  return client.post('api/auth/navigator-login', data);
}

const authSaga = {

  * requestResetPassword(action: IDispatchAction): Generator {
    try {
      const response = (yield call(requestPasswordResetAPI, action.payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({ type: "REQUEST_RESET_PASSWORD_SUCCESS" });
        if (action.onSuccess) {
          action.onSuccess();
        }
      }
    }
    catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  * resetPassword(action: IDispatchAction): Generator {
    try {
      const response = (yield call(resetPasswordAPI, action.payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({ type: "RESET_PASSWORD_SUCCESS" });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    }
    catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  * getUserRolesByUser(action: IDispatchAction): Generator {
    try {
      const response = (yield call(getUserRolesByUserAPI, action.payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: 'GET_ROLES_SUCCESS',
            normalized: normalize(response.data.roles, [roleSchema]),
          }),
          put({
            type: 'GET_USER_ROLES_SUCCESS',
            normalized: normalize(response.data.user_roles, [userRoleSchema])
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    }
    catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  * navigatorLogin(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(navigatorLoginAPI, payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        //admins only
        const roles = response.data?.roles || []
        const adminRole = roles.find((x: any) => x.name === 'Navigator');
        if (adminRole) {
          yield put({
            type: 'NAVIGATOR_LOGIN_SUCCESS',
            data: response.data
          })
          if (action.onSuccess) {
            action.onSuccess();
          }
        }
      }
    }
    catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  * login(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(loginAPI, payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        //admins only
        const roles = response.data?.roles || []
        const adminRole = roles.find((x: any) => x.name === 'Admin' || x.name === 'StoreTester');
        if (adminRole) {
          yield put({
            type: 'LOGIN_SUCCESS',
            data: response.data
          })
          if (action.onSuccess) {
            action.onSuccess();
          }
        }
        else {
          throw new Error('Forbidden');
        }

      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  * logout(action: IDispatchAction): Generator {
    try {
      yield put({
        type: 'LOGOUT_SUCCESS',
        data: {}
      })
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default authSaga;
