import { Box } from '@mui/material';

import theme from '../../themes/theme';

export default function CloseButton({ onClick }: any) {

	const style = {
		button: {
			cursor: 'pointer',
			position: 'absolute',
			right: 0, top: 0,
			width: 44, height: 44,

			'&:before': {
				content: '""',
				position: 'absolute',
				top: '50%', left: '70%',
				display: 'block',
				width: 2, height: 18,
				background: theme.palette.gray.main,
				transition: '0.3s ease all',
				transform: 'translate(-50%, -50%) rotate(-45deg)',
			},

			'&:after': {
				content: '""',
				position: 'absolute',
				top: '50%', left: '70%',
				display: 'block',
				width: 2, height: 18,
				background: theme.palette.gray.main,
				transition: '0.3s ease all',
				transform: 'translate(-50%, -50%) rotate(45deg)',
			},

			'&:hover': {
				'&:before': { background: theme.palette.error.main },
				'&:after': { background: theme.palette.error.main },
			}
		},
	};

	return (

		<Box sx={style.button} onClick={() => onClick?.()}></Box>

	)
}