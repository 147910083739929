import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function EyeIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<path d="M15.8983 7.68882C15.7554 7.49329 12.3496 2.90112 7.99992 2.90112C3.65019 2.90112 0.244313 7.49329 0.101531 7.68863C-0.0338438 7.87413 -0.0338438 8.12573 0.101531 8.31123C0.244313 8.50676 3.65019 13.0989 7.99992 13.0989C12.3496 13.0989 15.7554 8.50673 15.8983 8.31138C16.0339 8.12592 16.0339 7.87413 15.8983 7.68882ZM7.99992 12.044C4.79588 12.044 2.02085 8.99607 1.19938 7.99966C2.01979 7.00238 4.78901 3.95606 7.99992 3.95606C11.2038 3.95606 13.9787 7.00344 14.8005 8.00038C13.9801 8.99763 11.2108 12.044 7.99992 12.044Z" />
			<path d="M7.99981 4.83521C6.25474 4.83521 4.83496 6.25499 4.83496 8.00005C4.83496 9.74512 6.25474 11.1649 7.99981 11.1649C9.74488 11.1649 11.1647 9.74512 11.1647 8.00005C11.1647 6.25499 9.74488 4.83521 7.99981 4.83521ZM7.99981 10.1099C6.83637 10.1099 5.88993 9.16346 5.88993 8.00005C5.88993 6.83665 6.8364 5.89018 7.99981 5.89018C9.16322 5.89018 10.1097 6.83665 10.1097 8.00005C10.1097 9.16346 9.16325 10.1099 7.99981 10.1099Z" />
		</SvgIcon>
	)
}