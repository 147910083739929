import React, { useRef } from 'react';
import initializeReduxStorage from './store';
import { Provider } from 'react-redux';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const store = initializeReduxStorage();

const CloseSnackbarAction = ({ id }: any) => {
  const { closeSnackbar } = useSnackbar()
  return (
      <IconButton  style={{color:'#fff', cursor:'pointer', padding: '3px'}} onClick={() => {closeSnackbar(id)}}>
        <HighlightOffIcon style={{width: '16px', height: '16px'}} />
      </IconButton>
    )
}

const Main: React.FC = () => {
  document.body.style.backgroundColor = '#FFFFFF';
  const notistackRef = useRef<any>();
  // const { closeSnackbar } = useSnackbar();

  return (
    <Provider store={store}>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={3}
        autoHideDuration={3000}
        action={key => <CloseSnackbarAction id={key} />}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  );
};

export default Main;
