import React, { useEffect } from "react";
import '@coreui/coreui/dist/css/coreui.min.css';
import Logo from "../../../components/shared/Logo";
import { useDispatch } from "react-redux";
import { IRootState } from "../../../interfaces/store";
import { useSelector } from "../../../store";
import { navigatorLogin } from "../../../store/actions/authActions";
import { useSnackbar } from "notistack";
import { useNavigate  } from "react-router";
import { useSearchParams } from "react-router-dom";

interface ILoginProps {}

const LocusLogin: React.FC<ILoginProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const dispatch = useDispatch();
  const user = useSelector(
    (state: IRootState) => state.main.my_user
  );
  const navigate = useNavigate();
  useEffect(() => {
    if(user.email){
      searchParams.delete('email');
      navigate(`/search?${searchParams.toString()}`);
    }else{
      dispatch(
        navigatorLogin({ email }, () => {
            enqueueSnackbar("Login Success", { variant: "success" });
            searchParams.delete('email');
            navigate(`/search?${searchParams.toString()}`);
          },
          () => {
            enqueueSnackbar("unable to perform SSO, please login manually", {
              variant: "error",
            })
            navigate("/NavigatorLogin");
          }
        )
      );
    }

  }, []);

  return (

    <> 
        <div className="login-page__content">
          <div className="login-page__content-block">
            <h1>Authentocating, Please Wait ...</h1>
          </div>
        </div>
    </>
  );
};

export default LocusLogin;