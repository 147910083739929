import {
	Box,
	Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import IconBlock from '../IconBlock';
import CloseButton from '../CloseButton';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getResourceBoard, removeResourceBoardItem } from '../../../store/actions/resourceActions';

const style = {
	resource: {
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		mb: 3,
		pr: 10,
	},
	title: {
		textAlign: 'left',
		fontSize: '1rem',
		fontWeight: 500,
		lineHeight: '1.325em',
	}
};

export default function ResourceBoardBlock(props: any) {

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const onClickRemoveBoardItem = () => {
		dispatch(removeResourceBoardItem(
			{ body:{
				id: props.id
			} },
			(response) => {
				dispatch(getResourceBoard({}))
				enqueueSnackbar('Successfully removed from board', {variant:'success'})
			},
			(error) => {
				enqueueSnackbar('Failed to remove from board', {variant:'error'})
			},
		))
	}

	return (

		<Box sx={style.resource}>
			<IconBlock icon={props.icon} color={props.color} />
			<Link to={`/resources/${props?.id}`} target="_blank" className="titleText">
				{/* {props.title} */}
				<Typography sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width:"285px"}}>{props?.title}</Typography>
			</Link>
			{/* <Typography sx={style.title}>{props.title}</Typography> */}
			<CloseButton onClick={onClickRemoveBoardItem} />
		</Box>

	)
}