import {
	Box,
	Button,
	Grid,
	useMediaQuery,
	useTheme,

} from '@mui/material';
import Item from '@mui/material/Grid';

import { useDispatch } from 'react-redux';
import SearchCarousel from '../../../components/resources/SearchCarousel';
import SearchForm from '../../../components/shared/form/SearchForm';
import Breadcrumbs from '../../../components/shared/Breadcrumbs';
import { useSearchParams } from 'react-router-dom';
import SearchResultBlocks from '../../../components/shared/SearchResultBlocks';
import LookupFilters from "../../../components/resources/LookupFilters"
import { useState, useEffect } from 'react';
import { searcNchipResource , getResourceCategories} from "../../../store/actions/resourceActions";
import StaticSearchResources from "../../../components/resources/StaticSearchResources";

import Loading from "../../../components/general/Loading"
import ResourceForm from '../../../components/shared/ResourceForm';
import { COLORS } from '../../../enums/layout-enums';

const pageSize = 9;
export default function	SearchPage(props: any) {
    
	const dispatch = useDispatch();

	const theme = useTheme();
	const isScreenSM = useMediaQuery(theme.breakpoints.down('md'));
	// for initiating search from url paramaters or from locus
	const [searchParams, setSearchParams] = useSearchParams();
	const [openCreateModal, setCreateModal] = useState(false);	
	const [showSearchKeywordText, setShowSearchKeywordText] = useState<boolean>(false);
	const [searchKeywordTextString, setSearchKeywordTextString] = useState<string>("false");

	let initRd:any = decodeURIComponent(searchParams.get("rd") ?? "")
	initRd = (initRd && initRd !== "") ? [initRd] : []

	// search Form
	const [page, setPage] = useState(Number(decodeURIComponent(searchParams.get("page") ?? "1")));
	const [totalResult, setToalResult] = useState<number>(0);
	const [selectedSortOption, setSelectedSortOption] = useState<string>(decodeURIComponent(searchParams.get("orderBy") ?? "s.created_at"));
	const [pageCount, setPageCount] = useState(1);
	const [search, setSearch] = useState(decodeURIComponent(searchParams.get("kw") ?? ""));
	const [address, setAddress] = useState(decodeURIComponent(searchParams.get("address") ?? ""));
	const [latLng, setlatLng] = useState(searchParams.get("lat") && searchParams.get("lng") ? {lat: Number(decodeURIComponent(searchParams.get("lat")!)),lng: Number(decodeURIComponent(searchParams.get("lng")!))} : {} as any);
	const [selectedRadiusOptions, setSelectedRadiusOptions] = useState<string[]>(initRd);
	const [selectedCategoriesId, setSelectedCategoriesId] = useState<number[]>((searchParams.get("ctgIds") ? decodeURIComponent(searchParams.get("ctgIds") as string).split(",").map(id => Number(id)) : []));
	const [selectedCancerTypeIds, setSelectedCancerTypeIds] = useState<number[]>((searchParams.get("ctypeIds") ? decodeURIComponent(searchParams.get("ctypeIds") as string).split(",").map(id => Number(id)) : []));
	const [selectedCancerCenterIds, setSelectedCancerCenterIds] = useState<number[]>((searchParams.get("ctCIds") ? decodeURIComponent(searchParams.get("ctCIds") as string).split(",").map(id => Number(id)) : []));
	const [selectedTreatmentPhaseIds, setSelectedTreatmentPhaseIds] = useState<number[]>((searchParams.get("tPIds") ? decodeURIComponent(searchParams.get("tPIds") as string).split(",").map(id => Number(id)) : []));
	const [searchResult, setSearchResult] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);

	if(address !== "" && selectedRadiusOptions.length === 0 ){
		setSelectedRadiusOptions(["10"])
	}

	useEffect(() => {
		new Promise((resolve, reject) => {
			dispatch(searcNchipResource(
				{
					skip: (page-1) * pageSize,
					take: pageSize,
					orderBy: selectedSortOption,
					address,
					lat: (address && latLng.lat) ? latLng.lat : 0,
					lng: (address && latLng.lng) ? latLng.lng : 0,
					radius: Number(selectedRadiusOptions[0]),
					keyword: search,
					category_ids: selectedCategoriesId,
					cancer_type_ids: selectedCancerTypeIds,
					cancer_center_ids: selectedCancerCenterIds,
					treatment_phase_ids: selectedTreatmentPhaseIds
				},
				(data) => {					
					resolve(
						setSearchResult({
							data: data.data,
							page: 0,
							totalCount: data.total,
						})
					)
					setIsLoading(false)
					setToalResult(data.total)
					setPageCount(Math.ceil(data.total/pageSize))
					if(search !== "") {
						setSearchKeywordTextString("Showing results for: "+ search)
						setShowSearchKeywordText(true)
					} else{
						setShowSearchKeywordText(false)
					}
				},
				(error) => {
					setSearchResult({
						data:[],
						error: true
					})
					setIsLoading(false)
					setPage(1)
					return error
				},
			))
		})
	  }, []);

	const handleSearch = async (page:number = 1, orderBy:string = selectedSortOption) => {
		setIsLoading(true)
			new Promise((resolve, reject) => {
			dispatch(searcNchipResource(
				{
					skip: (page-1) * pageSize,
					take: pageSize,
					orderBy: selectedSortOption,
					address,
					lat: (address && latLng.lat) ? latLng.lat : 0,
					lng: (address && latLng.lng) ? latLng.lng : 0,
					radius: Number(selectedRadiusOptions[0]),
					keyword: search,
					category_ids: selectedCategoriesId,
					cancer_type_ids: selectedCancerTypeIds,
					cancer_center_ids: selectedCancerCenterIds,
					treatment_phase_ids: selectedTreatmentPhaseIds
				},
				(data) => {					
					resolve(
						setSearchResult({
							data: data.data,
							page: 0,
							totalCount: data.total,
						})
					)
					setIsLoading(false)
					setToalResult(data.total)
					setPageCount(Math.ceil(data.total/pageSize))
					setPage(page)
					setSelectedSortOption(orderBy)
						const params = {
							page,
							orderBy:orderBy,
							address:address,
							lat: latLng?.lat?.toString(),
							lng: latLng?.lng?.toString(),
							rd: selectedRadiusOptions[0],
							ctgIds: selectedCategoriesId.join(","),
							ctypeIds: selectedCancerTypeIds.join(","),
							ctCIds: selectedCancerCenterIds.join(",") ,
							tPIds: selectedTreatmentPhaseIds.join(","),
							kw:search
						  };
						  const filteredParams = Object.fromEntries(
							Object.entries(params).filter(([_, value]) => Boolean(value)).map(([key, value]) => [key, encodeURIComponent(value)])
							);
						setSearchParams(filteredParams, { replace: true });
					if(search !== "") {
						setSearchKeywordTextString("Showing results for: "+ search)
						setShowSearchKeywordText(true)
					} else{
						setShowSearchKeywordText(false)
					}
				},
				(error) => {
					setSearchResult({
						data:[],
						error: true
					})
					setIsLoading(false)
					setPage(1)
					return error
				},
			))
		})
	}

	const onCloseModal = () => {
		setCreateModal(false);
	}
	return (
		<Box justifyContent="center">
			<Breadcrumbs title="Search results" />
			<Loading loading={isLoading}></Loading> 
			
			<Box sx={{
				maxWidth: isScreenSM ? '100%' : "80%",
				padding: isScreenSM ? '0' : "0 40px",
				margin: "25px auto 0 auto"
			}}>
				<SearchForm 
					setAddress={setAddress}
					setlatLng={setlatLng}
					latLng={latLng}
					address={address}
					selectedRadiusOptions={selectedRadiusOptions}
					setSelectedRadiusOptions={setSelectedRadiusOptions}
					search={search}
					setSearch={setSearch}
					handleSearch={() => {handleSearch()}}
					showSearchResult={true}
				/>
			</Box>
			
			{(showSearchKeywordText) ?(
				<Grid item container pt={3} pl={5} xs={12} md={12}>
					<Item>
						<h3 className=''> {searchKeywordTextString}</h3>
					</Item>
				</Grid>
			):('')}

			<Grid container mt={4} spacing={2} padding={2} sx={{marginLeft:0, width:'100%'}}>
				
					<Grid item xs={12} md={2.5}>
						<LookupFilters  
							setSelectedCategoriesId = {setSelectedCategoriesId}
							setSelectedCancerTypeIds = {setSelectedCancerTypeIds}
							setSelectedCancerCenterIds = {setSelectedCancerCenterIds}
							setSelectedTreatmentPhaseIds = {setSelectedTreatmentPhaseIds}
							handleSearch={handleSearch}
							selectedCategoriesId = {selectedCategoriesId}
							selectedCancerTypeIds = {selectedCancerTypeIds}
							selectedCancerCenterIds = {selectedCancerCenterIds}
							selectedTreatmentPhaseIds = {selectedTreatmentPhaseIds}

						/>
						<Button onClick={() => setCreateModal(true)} sx={{ marginTop: '20px', textDecoration: 'underline' }}>Add New Resource</Button>
						{openCreateModal && <ResourceForm openModal={openCreateModal} closeModal={onCloseModal} />}
					</Grid>
					
					<Grid item justifyContent="center" alignItems="center" xs={12} md={9.5}>
						<SearchResultBlocks 
							searchResult={searchResult}
							handleSearch={handleSearch}
							setPage={setPage}
							page={page}
							pageCount={pageCount}
							selectedSortOption = {selectedSortOption}
							totalResult= {totalResult}
							color={COLORS.GREEN}
						/>
					</Grid>

			</Grid>

		</Box>

	)
}