import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { IRootState } from './interfaces/store';
import { getResourceBoard } from "./store/actions/resourceActions";



const ProtectedRoutes = ({ allowedRoles }: { allowedRoles: string[]}) => {

	const user = useSelector((state: IRootState) => state.main.my_user);
	const user_roles = useSelector((state: IRootState) => state.main.user_roles);
	const roles = useSelector((state: IRootState) => state.main.roles);

	let userRolesArr = Object.values(user_roles);
	let rolesArr = Object.values(roles);

	const dispatch = useDispatch();
    const location = useLocation();

	// To get resource board initially
	useEffect(() => {
		dispatch(getResourceBoard({}));
	}, []);

	
	// const accessAllowed = userRolesArr.find((ur) => rolesArr.find(r) => r.)
	const allowedRolesToLowerCase = allowedRoles.map(x => x.toLowerCase());
	const accessAllowed = rolesArr.filter(role => userRolesArr.find(user_role => user_role.role_id === role.id))
		.map(x => x.name?.toLowerCase())
		.some(userRoleName => allowedRolesToLowerCase.includes(userRoleName as string));

    return (
        accessAllowed
            ? <Outlet />
            : user?.email
                ? <Navigate to="/search" state={{ from: location }} replace />
                : <Navigate to="/NavigatorLogin" state={{ from: location }} replace />
    );
}

export default ProtectedRoutes;