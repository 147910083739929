import { isArray } from 'lodash'
import { normalize, schema } from 'normalizr'
import { put } from 'redux-saga/effects'

export const roleSchema = new schema.Entity('roles')
export const userRoleSchema = new schema.Entity('user_roles')
export const userSchema = new schema.Entity('users')
export const resourceSchema = new schema.Entity('resources');

export const boardNchipResourceSchema = new schema.Entity('boardNchipResource');
export const nchipResourceSchema = new schema.Entity('nchipResource');
export const cancerCenterSchema = new schema.Entity('boardCancerCenter');
export const cancerTypeSchema = new schema.Entity('boardCancerType');
export const categorySchema = new schema.Entity('boardCategory');
export const treatmentPhaseSchema = new schema.Entity('boardTreatmentPhase');

export function autoYields (data: any): any[]{
    const yields = [] as any[]
    const pData = {
      resource: resourceSchema,
      resources: [resourceSchema],
      role: roleSchema,
      roles: [roleSchema],
      user: userSchema,
      users: [userSchema],
      user_role: userRoleSchema,
      user_roles: [userRoleSchema],

      boardNchipResource: [boardNchipResourceSchema],
      nchipResource: [nchipResourceSchema],
      boardCancerCenter: [cancerCenterSchema],
      boardCancerType: [cancerTypeSchema],
      boardCategory: [categorySchema],
      boardTreatmentPhase: [treatmentPhaseSchema],

    } as any

    Object.keys(data).forEach((property: string) => {
      if (pData[property]) {
        yields.push(put({ type: 'REDUCE_MERGE_' + property + '_SUCCESS', normalized: normalize(data[property], pData[property])}));
      }
      else if (property.startsWith('deleted_')){ // check for deleted keys
        const compareString = property.replace('deleted_', '')
        if (pData[compareString]) {
          let deleteIds = data[property];
          let stateKey = '';
          let idAttribute = '';
          if (!isArray(deleteIds)){
            deleteIds = [deleteIds]
          }
          if (isArray(pData[compareString])){
            stateKey = pData[compareString][0]._key
            idAttribute = pData[compareString][0]._idAttribute
          }
          else{
            stateKey = pData[compareString]._key
            idAttribute = pData[compareString]._idAttribute
          }
          deleteIds = deleteIds.map((obj: any) => obj[idAttribute])
          const removeKeys = [{ entities: stateKey, ids: deleteIds }];
          yields.push(put({ type: 'REDUCE_DELETE_' + property + '_SUCCESS', removeKeys, forceStateRefresh: true}));
        }
      }
    });
    return yields
}