import * as Yup from 'yup';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button, Typography } from '@mui/material';
import { requestResetPassword } from '../../../store/actions/authActions';
import { useNavigate } from 'react-router-dom';

import BackToLogin from '../../../components/shared/BackToLogin';
import { Formik } from 'formik';
import FormTextField from '../../../components/shared/form/FormTextField';

interface IForgotPasswordProps {}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const ForgotPassword: React.FC<IForgotPasswordProps> = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleCompletion = () => {
    enqueueSnackbar('An email was sent with password reset instructions.', {
      variant: 'success',
    });
    history('/ResetPasswordNextStep');
  };

  const handleSubmit = (values: any) => {
    if (values.email) {
      dispatch(
        requestResetPassword(
          { email: values.email, captcha: 'captcha' },
          () => {
            handleCompletion();
          },
          () => {
            handleCompletion();
          },
        ),
      );
    } else {
      enqueueSnackbar('Email and captcha are required', { variant: 'error' });
    }
  };

  return (
    <>
      <BackToLogin />
      <Typography variant="h2" sx={{ mb: 4 }}>
        Forgot Password?
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: '1.125rem', color: '#9CA3AF' }}
      >
        No worries, we'll send you reset instructions
      </Typography>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={ForgotPasswordSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div style={{ margin: '1rem 0' }}>
              <FormTextField
                type="text"
                name="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                {...(errors.email && touched.email
                  ? {
                      error: true,
                      helperText: errors.email,
                    }
                  : {})}
              />
            </div>
            <Button sx={{ m: 0 }} type="submit">
              Reset Password
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;
