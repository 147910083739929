import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Logo() {

	const history = useNavigate();

	const style = {
		logo: {
			position: 'absolute',
			left: {
				xs: 32,
				lg: 80,
			},
			top: {
				xs: 32,
				lg: 50,
			},
			zIndex: 2,
		},
		image: {
			cursor: 'pointer',
			display: 'block',
		}
	}
	
	return (

		<Box sx={style.logo}>
			<img style={style.image} className="logo__image" src={process.env.PUBLIC_URL + '/images/ternLogo.png'} width={201} alt="NCHIP Logo" onClick={() => { history('/') }} />
		</Box>
	)
}

export default Logo;