export enum APP_BREAKPOINT {
    EXTRA_SMALL,
    SMALL,
    MEDIUM,
    LARGE,
    EXTRA_LARGE
}

export enum COLORS {
    WHITE = '#FFFFFF',
    PINK = '#EF5DA8',
    GREEN = '#219653',
    GREEN_DARK = '#218196',
    VIOLET = '#5D5FEF',
    VIOLET_LIGHT = '#BB6BD9',
    BLUE = '#003EDF',
    BLUE_LIGHT = '#2D9CDB',
    BLUE_BACKGROUND = '#F5F9FE',
    BLUE_DARK = '#111827',
    ORANGE = '#F2994A',
    YELLOW = '#FAC628',

    GRAY = '#BDBFC2',
    GRAY_LIGHT = '#EFF0F2',
    GRAY_BORDER = '#DDDDDD',

    ERROR = '#FF6D6D',
    SUCCESS = '#219653',

    RED_BRAND_PRIMARY = '#EB5757',
    BLUE_BRAND_PRIMARY = '#3683EF',
    BLUE_BRAND_PRIMARY_DARK = '#1F2937',
    GREEN_BRAND_PRIMARY = '#3fa57e',

    WHITE_PAPER = '#EDEDED',
    GREY_DARK = '#555555',
    WHEEL_2 = '#0099D1',
    WHEEL_3 = '#00BAC9',
    WHEEL_4 = '#00D6AA',
    WHEEL_5 = '#93EC85',
    WHEEL_6 = '#F9F871'
}

export const MARGIN_SMALL = 8
export const MARGIN_MEDIUM = 16
export const MARGIN_LARGE = 32