export enum APP_BREAKPOINT {
    EXTRA_SMALL,
    SMALL,
    MEDIUM,
    LARGE,
    EXTRA_LARGE,
  }
  
  export enum COLORS {
    WHITE_BACKGROUND = 'white',
    RED_BRAND_PRIMARY = '#ed1c24',
    BLUE_BRAND_PRIMARY = '#1c75bc',
    BLUE_BRAND_PRIMARY_OPACITY20 = '#1c75bc33',
    WHITE_PAPER = '#EDEDED',
    GREY_DARK = '#555555',
    WHEEL_2 = '#0099D1',
    WHEEL_3 = '#00BAC9',
    WHEEL_4 = '#00D6AA',
    WHEEL_5 = '#93EC85',
    WHEEL_6 = '#F9F871',
    MODAL_BACKGROUND = "rgba(248, 250, 251, 0.6)",
    WHITE = '#FFFFFF',
    PINK = '#EF5DA8',
    GREEN = '#219653',
    GREEN_DARK = '#218196',
    RED = '#EB5757',
    VIOLET_LIGHT = '#BB6BD9',
    BLUE = '#003EDF',
    BLUE_LIGHT = '#2D9CDB',
    BLUE_BACKGROUND = '#F5F9FE',
    BLUE_DARK = '#111827',
    ORANGE = '#F2994A',
    YELLOW = '#FAC628',
    PURPLE='#5D5FEF',
    DARK_PURPLE='#7548D4'
  }
  
  export const MARGIN_SMALL = 8;
  export const MARGIN_MEDIUM = 16;
  export const MARGIN_LARGE = 32;
  
  export const errorStyle = {
    color: 'red'
  }
  
  export const TABLE_DEFAULT_PAGE_SIZE = 15;
  export const TABLE_DEFAULT_PAGE_SIZES = [5, 15, 30, 50, 100]