import { ACTIONS } from "../../enums/actions";
import { genericAction } from "./actionFunctions";

export const getUser = genericAction(ACTIONS.GET_USER);
export const getUsers = genericAction(ACTIONS.GET_USERS);
export const createUser = genericAction(ACTIONS.CREATE_USER);
export const updateUser = genericAction(ACTIONS.UPDATE_USER);
export const userActivation = genericAction(ACTIONS.USER_ACTIVATION);
export const downloadUsersReport = genericAction(ACTIONS.DOWNLOAD_USERS_REPORT);
export const addRemoveRole = genericAction(ACTIONS.ADD_REMOVE_ROLE);
export const getResource = genericAction(ACTIONS.GET_RESOURCE);