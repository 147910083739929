import React, { useState, useEffect, } from 'react';
import {
	Box,
	OutlinedInput,
	InputLabel,
	TextField,
	Typography,
} from '@mui/material';

import EyeIcon from '../../../components/icons/EyeIcon';
import EyeHiddenIcon from '../../../components/icons/EyeHiddenIcon';

import { COLORS } from '../../../enums/layout-enums'

export default function FormHoursField(props: any) {
	const [passwordVisible, setPasswordVisible] = useState(false);

	return (

		<Box>
			<InputLabel>{props.label}
				<Box sx={{ display: 'flex', alignItems: 'center', }}>
					<OutlinedInput
						id={props.id}
						type="time"
						fullWidth
						value={props.startValue}
						onChange={(e) => props.setStartValue(e?.target.value)}
						placeholder={props.placeholder}
						sx={{
							mt: 1,
							'.MuiInputBase-input': { textAlign: 'center', }
						}}
					/>
					<Typography sx={{
						fontSize: '1rem',
						pl: 1, pr: 1,
					}}>-</Typography>
					<OutlinedInput
						id={props.id}
						type="time"
						fullWidth
						value={props.endValue}
						onChange={(e) => props.setEndValue(e?.target.value)}
						placeholder={props.placeholder}
						sx={{
							mt: 1,
							'.MuiInputBase-input': { textAlign: 'center', }
						}}
					/>
				</Box>
			</InputLabel>
		</Box>

	)
}