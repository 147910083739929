import DietIcon from '../icons/DietIcon';
import HomeIcon from '../icons/HomeIcon';
import EditIcon from '../icons/EditIcon';
import MoneyIcon from '../icons/MoneyIcon';
import HouseIcon from '../icons/HouseIcon';
import TruckIcon from '../icons/TruckIcon';
import MentalHealthIcon from '../icons/MentalHealthIcon';
import GraduationIcon from '../icons/GraduationIcon';
import BabyIcon from '../icons/BabyIcon';
import StopViolenceIcon from '../icons/StopViolenceIcon';
import StarIcon from '../icons/StarIcon';
import MoreHorizontalIcon from '../icons/MoreHorizontalIcon';
import MoreVerticalIcon from '../icons/MoreVerticalIcon';
import InboxIcon from '../icons/InboxIcon';
import PlusIcon from '../icons/PlusIcon';
import Edit2Icon from '../icons/Edit2Icon';
import CheckIcon from '../icons/CheckIcon';
import CopyIcon from '../icons/CopyIcon';
import ConversationIcon from '../icons/ConversationIcon';
import FolderIcon from '../icons/FolderIcon';
import EmailIcon from '../icons/EmailIcon';
import FirstAidKitIcon from '../icons/FirstAidKitIcon';
import PhoneIcon from '../icons/PhoneIcon';
import VirusIcon from '../icons/VirusIcon';
import InternetIcon from '../icons/InternetIcon';
import ClockIcon from '../icons/ClockIcon';
import MapIcon from '../icons/MapIcon';
import UserIcon from '../icons/UserIcon';
import AccountIcon from '../icons/AccountIcon';
import BuildingIcon from '../icons/BuildingIcon';
import LegalIcon from '../icons/LegalIcon';

function DynamicIcon(props: any) {
	if(props.icon === 'diet') { return <DietIcon /> } else
	if(props.icon === 'home') { return <HomeIcon /> } else
	if(props.icon === 'edit') { return <EditIcon /> } else
	if(props.icon === 'money') { return <MoneyIcon /> } else
	if(props.icon === 'house') { return <HouseIcon /> } else
	if(props.icon === 'truck') { return <TruckIcon /> } else
	if(props.icon === 'mental-health') { return <MentalHealthIcon /> } else
	if(props.icon === 'graduation') { return <GraduationIcon /> } else
	if(props.icon === 'baby') { return <BabyIcon color={props?.color} /> } else
	if(props.icon === 'stop-violence') { return <StopViolenceIcon /> } else
	if(props.icon === 'star') { return <StarIcon /> } else
	if(props.icon === 'more-horizontal') { return <MoreHorizontalIcon /> } else
	if(props.icon === 'more-vertical') { return <MoreVerticalIcon /> } else
	if(props.icon === 'inbox') { return <InboxIcon /> } else
	if(props.icon === 'plus') { return <PlusIcon /> } else
	if(props.icon === 'edit2') { return <Edit2Icon /> } else
	if(props.icon === 'check') { return <CheckIcon /> } else
	if(props.icon === 'copy') { return <CopyIcon /> } else
	if(props.icon === 'language') { return <ConversationIcon /> } else
	if(props.icon === 'folder') { return <FolderIcon /> } else
	if(props.icon === 'email') { return <EmailIcon /> } else
	if(props.icon === 'first-aid-kit') { return <FirstAidKitIcon /> } else
	if(props.icon === 'phone') { return <PhoneIcon /> } else
	if(props.icon === 'virus') { return <VirusIcon /> } else
	if(props.icon === 'internet') { return <InternetIcon /> } else
	if(props.icon === 'clock') { return <ClockIcon /> } else
	if(props.icon === 'map') { return <MapIcon /> } else
	if(props.icon === 'building') { return <BuildingIcon /> } else
	if(props.icon === 'user') { return <UserIcon /> } else
	if(props.icon === 'account') { return <AccountIcon /> }
	if(props.icon === 'Legal') { return <LegalIcon color={props?.color} /> }
	return null;
}

export default DynamicIcon;