import { Box, Button, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, TextFieldProps, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, JSXElementConstructor, ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { createResource, createUploadResource, getCancerCenters, getCancerTypes, getResourceCategories, getTreatmentPhases, updateResource } from "../../store/actions/resourceActions";
import { deepClone, isUserAdmin } from "../../utils/general";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "./Modal/Modal";
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, geocodeByPlaceId, getLatLng, } from 'react-places-autocomplete';
import PaperclipIcon from "../icons/PaperclipIcon";
import ResourceEditMenu from './resource/ResourceEditMenu';
import { RESOURCE_TYPE, UPLOAD_COLLECTION_METHOD } from "../../shared/constants/resource.constants";
import { ICreateUploadResourcePayload } from "../../shared/interfaces/model/resource.interfaces";
import { getFileExtension } from "../../utils/file";
import { getFileLink, getPreSignedUrl, initPresignedUrlSimpleUpload } from "../../store/actions/awsActions";
import Loading from "../general/Loading";
import FancyButton from "./Button/Button";
import { phoneNumberFormat } from "../../utils/validator";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import { DesktopTimePicker } from "@mui/x-date-pickers";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ResourceForm: FC<any> = ({ openModal=true, closeModal, data, onSuccess, categories:categoriesP , cancerTypes:cancerTypesP, cancerCenters:cancerCentersP, treatmentPhases: treatmentPhasesP, onSuspendResource }) => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [fileError, setFileError] = useState<any>(null);
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<any[]>([]);
	const [cancerTypes, setCancerTypes] = useState<any[]>([]);
	const [cancerCenters, setCancerCenters] = useState<any[]>([]);
	const [treatmentPhases, setTreatmentPhases] = useState<any[]>([]);

  useEffect(() => {
    
    // Fetch dependencies 
    fetchData();

  }, []);

  const fetchData = () => {

		const categoriesPromise = new Promise((resolve, reject) => {
			dispatch(getResourceCategories(
				{},
				(response) => {
					resolve(response)
				},
				(error) => { reject(reject) },
			))
		})
		const cancerTypesPromise = new Promise((resolve, reject) => {
			dispatch(getCancerTypes(
				{},
				(response) => {
					resolve(response)
				},
				(error) => { reject(reject) },
			))
		})
		const cancerCentersPromise = new Promise((resolve, reject) => {
			dispatch(getCancerCenters(
				{},
				(response) => {
					// console.log({ response});
					// setCancerCenters(response);
					resolve(response)
				},
				(error) => { reject(reject) },
			))
		})
		const treatmentPromise = new Promise((resolve, reject) => {
			dispatch(getTreatmentPhases(
				{},
				(response) => {
					// console.log({ response});
					// setTreatmentPhases(response);
					resolve(response)
				},
				(error) => { reject(reject) },
			))
		})
	
		Promise.all([categoriesPromise, cancerTypesPromise, cancerCentersPromise, treatmentPromise])
			.then(([categories, cancerTypes, cancerCenters, treatmentTypes]: any[]) => {

				setCategories(categories);
				setCancerTypes(cancerTypes);
				setCancerCenters(cancerCenters);
				setTreatmentPhases(treatmentTypes);

				setLoading(false);

			})
			.catch((error) => {
				
				setLoading(false);

			})
	}


  const formik = useFormik({
    initialValues: {
      id: data?.id as string ?? null,
      title: data?.title as string ?? "",
      description: data?.description as string  ?? "",
      category_id: data?.category_id as string  ?? "",
      address: data?.address as string  ?? "",
      treatment_phase_id: data?.treatment_phase_id as string  ?? "",
      cancer_type_id: data?.cancer_type_id as string  ?? "",
      language: data?.language as string  ?? "",
      cancer_center_id: data?.cancer_center_id as string  ?? "",
      contact_person: data?.contact_person as string  ?? "",
      phone_number: data?.phone_number as string  ?? "",
      email_address: data?.email_address as string  ?? "",
      hours_from: data?.hours_from ? new Date('2023-01-01T' + data?.hours_from ) : "",
      hours_to: data?.hours_to ? new Date('2023-01-01T' + data?.hours_to ) : "",
      cta_url: data?.cta_url as string  ?? "",
      cta_text: data?.cta_text as string  ?? "",
      longitude: data?.longitude as string  ?? 0,
      latitude: data?.latitude as string  ?? 0,
      fileName: data?.files?.[0]?.original_filename ?? null,
      fileUrl: data?.files?.[0]?.signed_url ?? null,
    },
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      title: Yup.string().required('This field is required'),
      description: Yup.string().required('This field is required'),
      category_id: Yup.string().required('This field is required'),
      address: Yup.string().optional(),
      treatment_phase_id: Yup.string().required('This field is required'),
      cancer_type_id: Yup.string().required('This field is required'),
      language: Yup.string().required('This field is required'),
      cancer_center_id: Yup.string().optional(),
      contact_person: Yup.string().optional(),
      phone_number: Yup.string()
        // .required('This field is required')
        .optional()
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(12, "Can not be less than 10 characters")
        .max(12, "Can not be more than 10 characters"),
      email_address: Yup.string().email('Must be a valid email').optional(),
      // hours_from: Yup.string().optional(),
      hours_from: Yup.date().optional(),
      hours_to: Yup.date().optional(),
      cta_url: Yup.string().required('This field is required'),
      cta_text: Yup.string().optional(),
      longitude: Yup.string().optional(),
      latitude: Yup.string().optional()
    }),
    onSubmit: async (values:any) => {
    
      setProcessing(true);
      let submitValues = deepClone(values);
      delete submitValues.fileName;
      delete submitValues.fileUrl;

      // handle time from and to
      if(submitValues.hours_from) submitValues.hours_from = format(new Date(submitValues.hours_from), 'HH:mm');
      if(submitValues.hours_to) submitValues.hours_to = format(new Date(submitValues.hours_to), 'HH:mm');

      // set cancer center to null if empty
      submitValues.cancer_center_id = submitValues.cancer_center_id === '' ? null : submitValues.cancer_center_id;

      let fileUploadObject: ICreateUploadResourcePayload  = {};

      if(values.id){
        delete submitValues.id;
        dispatch(updateResource({
            body:submitValues,
            id:values.id
          }, 
          async () => {

            // update file
            if(file) {
              
              fileUploadObject = {
                uploadCollectionMethod: UPLOAD_COLLECTION_METHOD.REPLACE,
                name: file.name,
                filename: file.name,
                model: 'nchip_resource',
                modelId: values.id,
                extension: getFileExtension(file.name),
                fileSize: file.size,
                type: RESOURCE_TYPE.IMAGE,
        
                rootPath: 'nchip_resource',
                parts: 1,
                primary: true,
                success: true,
              }

              try{
                const fileUploadResponse = await uploadFile(fileUploadObject, file);
              }
              catch(Excp){
                enqueueSnackbar('Failed to upload file, please try again', { variant: "error" })
              }
            }

            setProcessing(false);
            enqueueSnackbar('Resource updated successfully', { variant: "success" });
            // fetch latest
            onSuccess?.()
            formik.resetForm();
            closeModal();

          },
          () => {
            setProcessing(false);
            enqueueSnackbar('Failed to update resource', { variant: "error" });
          }
        ))
      }
      else{
        delete submitValues.id;
        dispatch(createResource(submitValues,
        async (response) =>{
          
          // upload file
          if(file) {
            fileUploadObject= {
              uploadCollectionMethod: UPLOAD_COLLECTION_METHOD.REPLACE,
              name: file.name,
              filename: file.name,
              model: 'nchip_resource',
              modelId: response.id,
              extension: getFileExtension(file.name),
              fileSize: file.size,
              type: RESOURCE_TYPE.IMAGE,
      
              rootPath: 'nchip_resource',
              parts: 1,
              primary: true,
              success: true,
            }
            
            try{
              await uploadFile(fileUploadObject, file);
            }
            catch(Excp){
              enqueueSnackbar('Resource created but failed to upload file, please try again while editing the resource', { variant: "error" })
            }

          }

          setProcessing(false);
          enqueueSnackbar('Resource added successfully', { variant: "success" });
          // fetch latest
          onSuccess?.()
          closeModal();
        },
        (error) =>{
          setProcessing(false);
          enqueueSnackbar('Failed to add resource', { variant: "error" });
        }
      ));
      }
  
    },
  });

  const handleChange = async (address:string)=>{
    formik.setFieldValue("address", address);
    formik.setFieldValue("longitude", 0)
    formik.setFieldValue("latitude", 0)
	}

	const handleSelect = (address:any) => {
    formik.setFieldValue("address", address);
	  geocodeByAddress(address)
		.then(results => getLatLng(results[0]))
		.then(latLng => {
      formik.setFieldValue("longitude", latLng.lng)
      formik.setFieldValue("latitude", latLng.lat)
    })
		.catch(error => console.error('Error', error));
	};

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
		
    // Set file error to null
    setFile(null);
    setFileError(null);

    if (!e.target.files) {
      setFileError("No file is selected");
			return;
		}

    const file = e.target.files[0];

    // Validate file extentions
    if(!validateExtention(file.name)){
      setFileError("File type is not supported");
      return;
    }

    // Validate if image size is valid
    if(!validateFileSize(file)){
      setFileError("File size cannot exceed 5 MBs");
      return;
    }
    
    setFile(file);
    
	};

  const getPresignedUrl = (uploadFileObject: ICreateUploadResourcePayload) => {
    return new Promise((resolve, reject) => {
      dispatch(getPreSignedUrl(
        uploadFileObject,
        (response) => resolve(response),
        (error) => new Error('Failed to fetch upload url'),
      ))
    })
  }

  const uploadFileToAws = (file: File, url: string) => {
    return new Promise((resolve, reject) => {
      dispatch(initPresignedUrlSimpleUpload(
        { file, url},
        (response) => resolve(response),
        (error) => reject(new Error('Failed to upload file to server')),
      ))
    })
  }

  const uploadFileMeta = (uploadFileObject: ICreateUploadResourcePayload) => {
    return new Promise((resolve, reject) => {
      dispatch(createUploadResource(
        uploadFileObject,
        (response) => resolve(response),
        (error) => reject(new Error('Failed to save uploaded file')),
      ))
    })
  }

  const uploadFile = (uploadFileObject: ICreateUploadResourcePayload, file:File) => {
    return new Promise(async (resolve, reject) => {

      let responsePresignedUrl: any, responseUploadedFile: any, reponseUploadFileMeta;

      try {

        // Get presign URL and set previous file to awaiting
        responsePresignedUrl = await getPresignedUrl(uploadFileObject);
        const signed_url = responsePresignedUrl.urls[0];
        const uuid = responsePresignedUrl.uuid;

        // Upload file to AWS server
        responseUploadedFile = await uploadFileToAws(file, signed_url);

        // Upload meta of uploaded file to database
        reponseUploadFileMeta = await uploadFileMeta({...uploadFileObject, signed_url, uuid});

        resolve(reponseUploadFileMeta);

      }
      catch(Excp){


        // No need to update server
        if(!responsePresignedUrl) return reject(new Error('Failed to upload file'));

        // Set previous file to available
        await uploadFileMeta({...uploadFileObject, success:false});
        return reject("Failed to upload file");

      }
      
      
      // dispatch(createUploadResource(
      //   uploadFileObject,
      //   (response: any) => {

      //     console.log({ resource_crate: response });
      //     const {urls} = response;

      //     dispatch(initPresignedUrlSimpleUpload({ file, url:urls[0]},
      //       (fileUploadResponse: any) => {
      //         console.log({ fileUploadResponse });
      //         resolve(response)
      //       },  
      //       (fileErrorResponse: any) => {
      //         console.log({ fileErrorResponse })
      //         reject(fileErrorResponse ?? 'FAiled to upload file');
      //       },  
      //     ))

      //   },
      //   (error: any) => {
      //     console.log({ error })
      //     reject(error)
      //   },
      // ))

    })
  };

  const isAdmin = isUserAdmin();

  const onClickGetFile = () => {
    // name, fileNameAs, model
    const file = data?.files?.[0];
    if(!file) return;

    setFileLoading(true);
    dispatch(getFileLink(
      {
        name: file?.name, 
        fileNameAs: file?.original_filename, 
        model: file?.model,
      },
      (response) => { 
        let link = document.createElement("a");
        link.href = response.url;
        link.download = file?.original_filename;
        link.click();
        setFileLoading(false);
      },
      (error) => {
        setFileLoading(false);
      },
    ))
  }

  return(
    <>
      {loading && <Loading loading={loading} />}
      <Modal isOpen={openModal} onClose={closeModal} showCloseButton={false}>
          <ModalHeader>
            <Box display={"flex"} justifyContent="space-between" style={{width:"100%"}}>
              <>
                <span style={{fontWeight: 500,}}>{!formik.values.id ? "New Resource" : "Edit Resource"}</span>
                {isAdmin && formik.values.id && <ResourceEditMenu currentValue={data?.active} resourceId={formik.values.id} onSuspendResource={onSuspendResource} />}
              </>
            </Box>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                      <InputLabel>Title<sup>*</sup></InputLabel>
                      <TextField
                          error={!!formik.errors.title}
                          placeholder="Enter title"
                          fullWidth
                          size="small"
                          name="title"
                          onChange={formik.handleChange}
                          value={formik.values.title}
                          variant="outlined"
                      />
                      <p className="error-message">{formik?.errors?.title}</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <InputLabel>Category<sup>*</sup></InputLabel>
                      <Select name="category_id" 
                        fullWidth 
                        displayEmpty
                        sx={{
                          '& .MuiSelect-select .notranslate::after': 'Select category'
                              ? {
                                  content: `"Select category"`,
                                  opacity: 0.42,
                                }
                              : {},
                        }}
                        size="small" 
                        error={!!formik.errors.category_id} 
                        value={formik?.values?.category_id} 
                        onChange={formik.handleChange}
                        placeholder={"Choose category"}
                      >
                        {/* <MenuItem value="" disabled>Choose category</MenuItem> */}
                        {categories.map((item:any) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                      </Select>
                      <p className="error-message">{formik?.errors?.category_id}</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <InputLabel>Cancer Type<sup>*</sup></InputLabel>
                      <Select 
                        name="cancer_type_id" 
                        fullWidth 
                        size="small" 
                        error={!!formik.errors.cancer_type_id} 
                        value={formik?.values?.cancer_type_id} 
                        onChange={formik.handleChange}
                        sx={{
                          '& .MuiSelect-select .notranslate::after': 'Choose type'
                              ? {
                                  content: `"Choose type"`,
                                  opacity: 0.42,
                                }
                              : {},
                        }}
                      >
                        {cancerTypes.map((item:any) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                      </Select>
                      <p className="error-message">{formik?.errors?.cancer_type_id}</p>
                  </Grid>
                  
                  
                  <Grid item xs={12} md={6}>
                      <InputLabel>Phase of Treatment<sup>*</sup></InputLabel>
                      <Select 
                        name="treatment_phase_id" 
                        fullWidth size="small" 
                        error={!!formik.errors.treatment_phase_id} 
                        value={formik?.values?.treatment_phase_id} 
                        onChange={formik.handleChange}
                        sx={{
                          '& .MuiSelect-select .notranslate::after': 'Choose phase'
                              ? {
                                  content: `"Choose phase"`,
                                  opacity: 0.42,
                                }
                              : {},
                        }}
                      >
                        {treatmentPhases.map((item:any) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                      </Select>
                      <p className="error-message">{formik?.errors?.treatment_phase_id}</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <InputLabel>Language<sup>*</sup></InputLabel>
                      <Select 
                        name="language" 
                        fullWidth 
                        size="small" 
                        error={!!formik.errors.language} 
                        value={formik.values.language} 
                        onChange={formik.handleChange}
                        sx={{
                          '& .MuiSelect-select .notranslate::after': 'Choose language'
                              ? {
                                  content: `"Choose language"`,
                                  opacity: 0.42,
                                }
                              : {},
                        }}
                      >
                          <MenuItem value="Spanish">Spanish</MenuItem>
                          <MenuItem value="English">English</MenuItem>
                      </Select>
                      <p className="error-message">{formik?.errors?.language}</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <InputLabel>Cancer Center</InputLabel>
                      <Select 
                        name="cancer_center_id" 
                        fullWidth 
                        size="small" 
                        error={!!formik.errors.cancer_center_id} 
                        value={formik?.values?.cancer_center_id} 
                        onChange={formik.handleChange}
                        sx={{
                          '& .MuiSelect-select .notranslate::after': 'Choose center'
                              ? {
                                  content: `"Choose center"`,
                                  opacity: 0.42,
                                }
                              : {},
                        }}
                      >
                        <MenuItem value="">Not selected</MenuItem>
                        {cancerCenters.map((item:any) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                      </Select>
                      <p className="error-message">{formik?.errors?.cancer_center_id}</p>
                  </Grid>
                  
                  
                  <Grid item xs={12} md={6}>
                      <InputLabel>Contact Person</InputLabel>
                      <TextField
                          error={!!formik.errors.contact_person}
                          placeholder="Contact Person name"
                          fullWidth
                          size="small"
                          name="contact_person"
                          onChange={formik.handleChange}
                          value={formik.values.contact_person}
                          variant="outlined"
                      />
                      <p className="error-message">{formik?.errors?.contact_person}</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <InputLabel>Phone</InputLabel>
                      <TextField
                          error={!!formik.errors.phone_number}
                          placeholder="Contact person phone"
                          fullWidth
                          size="small"
                          name="phone_number"
                          // onChange={formik.handleChange}
                          value={formik.values.phone_number}
                          variant="outlined"
                          type={'text'}
                          // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          onChange={(e) => {
                            const newValue = phoneNumberFormat(e.target.value, formik.values.phone_number);
                            formik.setFieldValue('phone_number', newValue);
                        }}
                      />
                      <p className="error-message">{formik?.errors?.phone_number}</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <InputLabel>Email</InputLabel>
                      <TextField
                          error={!!formik.errors.email_address}
                          placeholder="Contact person email"
                          fullWidth
                          size="small"
                          name="email_address"
                          onChange={formik.handleChange}
                          value={formik.values.email_address}
                          variant="outlined"
                      />
                      <p className="error-message">{formik?.errors?.email_address}</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <InputLabel>Website URL<sup>*</sup></InputLabel>
                      <TextField
                          error={!!formik.errors.cta_url}
                          placeholder="Website URL"
                          fullWidth
                          size="small"
                          name="cta_url"
                          onChange={formik.handleChange}
                          value={formik.values.cta_url}
                          variant="outlined"
                      />
                      <p className="error-message">{formik?.errors?.cta_url}</p>

                  </Grid>

                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <InputLabel>Hours Available</InputLabel>
                        <Box display={"flex"} alignItems={"center"}>
                          <Box flex={1}>
                            <DesktopTimePicker
                              value={formik.values.hours_from}
                              onChange={(newValue) => {
                                formik.setFieldValue("hours_from", newValue != null ? newValue : '');
                              }}
                              // ampm={false}
                              renderInput={(params: any) => <TextField {...params} size="small" inputProps={{...params.inputProps, placeholder: "hh:mm am/pm"}} />}
                            />
                            <p className="error-message">{formik?.errors?.hours_from ? 'Must b a valid 12 hour time': ''}</p>
                          </Box>
                          <Box sx={{padding: '0 5px', marginBottom:'15px'}}>-</Box>
                          <Box flex={1}>
                            <DesktopTimePicker
                              value={formik.values.hours_to}
                              onChange={(newValue) => {
                                formik.setFieldValue("hours_to", newValue != null ? newValue : '');
                              }}
                              // ampm={false}
                              renderInput={(params: any) => <TextField {...params} size="small" inputProps={{...params.inputProps, placeholder: "hh:mm am/pm"}} />}
                            />
                            <p className="error-message">{formik?.errors?.hours_to ? 'Must b a valid 12 hour time': ''}</p>
                          </Box>
                        </Box>
                          

                          
                      
                    </LocalizationProvider>
                      {/* <div style={{ display:"flex", alignItems:"center"}}>
                        <OutlinedInput
                              className="placeholder_fix"
                              type="time"
                              fullWidth
                              size="small"
                              name="hours_from"
                              onChange={formik.handleChange}
                              value={formik.values.hours_from}
                              placeholder={"From"}
                              sx={{
                                '.MuiInputBase-input': { textAlign: 'center', }
                              }}
                              inputProps={{
                                'data-placeholder': "00:00 PM"
                              }}
                            />
                        <span style={{padding:"0 5px"}}> - </span>
                        <OutlinedInput
                              className="placeholder_fix"
                              type="time"
                              fullWidth
                              size="small"
                              name="hours_to"
                              onChange={formik.handleChange}
                              value={formik.values.hours_to}
                              placeholder={"To"}
                              sx={{
                                '.MuiInputBase-input': { textAlign: 'center', }
                              }}
                              inputProps={{
                                'data-placeholder': "00:00 AM"
                              }}
                            />
                      </div>
                      <p className="error-message">{formik?.errors?.hours_from}</p> */}

                  </Grid>
                  <Grid item xs={12} md={6}>
                  <InputLabel>Notes (regarding hours)</InputLabel>
                      <TextField
                          error={!!formik.errors.cta_text}
                          placeholder="Notes"
                          fullWidth
                          size="small"
                          name="cta_text"
                          onChange={formik.handleChange}
                          value={formik.values.cta_text}
                          variant="outlined"
                      />
                      <p className="error-message">{formik?.errors?.cta_text}</p>
                  </Grid>

                  <Grid item xs={12} md={12}>
                      <InputLabel>Location</InputLabel>
                      <div style={{position: 'relative'}}>
                        <PlacesAutocomplete 
                              value= {formik.values.address}
                              onChange={(a)=> handleChange(a)}
                              onSelect={(a)=> handleSelect(a)}
                              >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <OutlinedInput
                                fullWidth
                                size="small"
                                sx={{}}
                                {...getInputProps({
                                  placeholder: 'Search Location ...',
                                  className: 'location-search-input',
                                })}

                              />
                              <div className="autocomplete-dropdown-container" style={{position: 'relative', zIndex:900, width:"100%"}}>
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                                );
                              })}
                              </div>
                            </div>
                            )}
                        </PlacesAutocomplete>
                      </div>
                      <p className="error-message">{formik?.errors?.address}</p>
                  </Grid>

                  <Grid item xs={12} md={12}>
                      <InputLabel>Description<sup>*</sup></InputLabel>
                      <TextField
                          error={!!formik.errors.description}
                          placeholder="Brief description"
                          fullWidth
                          multiline
                          rows={3}
                          size="small"
                          name="description"
                          onChange={formik.handleChange}
                          value={formik.values.description}
                          variant="outlined"
                      />
                      <p className="error-message">{formik?.errors?.description}</p>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>
                      <Box sx={{ mt: 1, }}>
                        <Typography sx={{
                          fontSize: '1rem',
                        }}>
                          <PaperclipIcon />
                          Attachments:
                          <Typography sx={{ cursor: 'pointer',  ml: 1, }} className={!formik.values.fileName ? 'color-gradient' : ''} variant="gradient">
                            {file ? file.name : formik.values.fileName ? formik.values.fileName : 'Choose file'}
                            <input type="file" hidden onChange={handleFileUpload} />
                          </Typography>
                          <div className="supportedFileTypes">Supported types: jpg, png, pdf, doc, docx, xlsx</div>
                        </Typography>
                      </Box>
                      {}
                    </InputLabel>
                    {fileError && <p className="error-message">{fileError}</p>}
                    {/* {formik.values.fileName && !fileLoading && !file && <Button onClick={onClickGetFile} variant="text" sx={{ textTransform:'none' }}>{formik.values.fileName}</Button>}
                    {fileLoading && <CircularProgress color="primary" size={24} />} */}
                  </Grid>
              </Grid>
            </form>
          </ModalBody>
          <ModalFooter style={{display:'flex', justifyContent:'end'}}>
            <div style={{display:'flex'}}>
              <FancyButton disabled={processing} onClick={closeModal} style={{marginRight:"10px"}} variant="outlined">Cancel</FancyButton>
              <FancyButton disabled={processing} onClick={formik.submitForm} variant="contained" style={{display:'flex', alignItems:'center'}}>
                  {processing && <CircularProgress color="inherit" size={18} sx={{marginRight:'5px'}}/>}
                  {!formik?.values?.id ? "Create" : "Save"}
              </FancyButton>
            </div>
          </ModalFooter>
      </Modal>
    </>
  )

}

export const validateExtention = (fileName: string) => {
  
  const fileExtension = getFileExtension(fileName);
  const validateExtentions = ['jpg', 'jpeg', 'png', 'pdf', 'csv', 'doc', 'docx', 'xls', 'xlsx'];

  if(validateExtentions.includes(fileExtension.toLowerCase())) return true;
  return false;

};

export const validateFileSize = (file: File) => {

  // Max file size to be upload
  const FILE_MAX_UPLOAD_SIZE_IN_BYTES = 5242880;

  const fileSize = file.size;
  if(fileSize > FILE_MAX_UPLOAD_SIZE_IN_BYTES) return false;
  return true;

}

export default ResourceForm;