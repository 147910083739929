import React, { useMemo, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Typography,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	Modal,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { Link } from 'react-router-dom';

import Breadcrumbs from '../../../components/shared/Breadcrumbs';
import PageTitle from '../../../components/shared/PageTitle';
import Tag from '../../../components/shared/Tag';

import IconBlock from '../../../components/shared/IconBlock';
import IconClose from '../../../components/shared/IconClose';

import { COLORS } from '../../../enums/layout-enums';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import { categorytoIcon } from '../../../shared/constants/category-icons';
import { clearResourceBoard, copyResourceBoard, getResourceBoard, removeResourceBoardItem } from '../../../store/actions/resourceActions';
// @ts-ignore
import CopyToClipboard from "react-copy-html-to-clipboard";
import FancyButton from '../../../components/shared/Button/Button';
import SuspendResource from '../../../components/shared/SuspendResource';
import CloseButton from '../../../components/shared/CloseButton';
import { convertResourcesToCopyHtml } from '../../../utils/general';

const style = {
	row: {
		borderBottom: 'solid 1px ' + COLORS.GRAY_BORDER,

		// '&:last-child': {
		// 	borderBottom: 'none',
		// }
	},
	cell: {
		pt: 3, pb: 3,
	},
	nameContent: {
		minWidth: '300px',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
	}
}

export default function ResourceBoardPage(props: any) {
	
	const history = useNavigate();
	const dispatch = useDispatch();

	const { enqueueSnackbar } = useSnackbar();
	const [title, setTitle] = useState('Resource Board');
	const [showConfirmModal, setConfirmModal] = useState<boolean>(false);

	const resourceBoardItems: any = useSelector((state: IRootState) => {
		return Object.values(state.main.boardNchipResource).reduce((prev:any, curr:any) => {

			const {boardCancerType, boardTreatmentPhase, boardCancerCenter, boardCategory} = state.main;
			const resource = state.main.nchipResource[curr.resource_id];

			if(resource) {
				curr['resource'] = resource;
				if(boardCategory) curr['category'] = boardCategory?.[resource?.category_id];
				if(boardCancerType) curr['cancerType'] = boardCancerType?.[resource?.cancer_type_id];
				if(boardTreatmentPhase) curr['treatmentPhase'] = boardTreatmentPhase?.[resource?.treatment_phase_id];
				if(boardCancerCenter) curr['cancerCenter'] = boardCancerCenter?.[resource?.cancer_center_id];
				prev.push(curr);
			}

			return prev;
		}, [])
	});

	const onClickRemoveBoardItem = (id: number) => {
		dispatch(removeResourceBoardItem(
			{ body:{
				id:id
			} },
			(response) => {
				dispatch(getResourceBoard({}))
				enqueueSnackbar('Successfully removed from board', {variant:'success'})
			},
			(error) => {
				enqueueSnackbar('Failed to remove from board', {variant:'error'})
			},
		))
	}

	const dataToBeCopy = useMemo(() => {
		return convertResourcesToCopyHtml(resourceBoardItems);
	}, [resourceBoardItems])

	const onCopyToClicpBoard = () => {
		enqueueSnackbar("Resources Copied to Clipboard", { variant: "success" })
		dispatch(copyResourceBoard({}))
	}

	const onClickClearResourceBoard = () => {
		setConfirmModal(true)
	}

	const handleYes = () => {
		dispatch(clearResourceBoard(
			{},
			() => {
				enqueueSnackbar("Resources board cleared", { variant: "success" })
				setConfirmModal(false);
				dispatch(getResourceBoard({}))
			},
			() => {
				enqueueSnackbar("Failed to clear resource board", { variant: "error" })
			},
		))
	}
	const handleNo = () => {
		setConfirmModal(false)
	}

	return (

		<>
			<Breadcrumbs
				title={title}
			/>
			<PageTitle title={title}>
				{resourceBoardItems.length
					?
					<Box sx={{
						display: 'flex',
					}}>
						{/* <Button variant="outlinedGradient" onClick={onClickClearResourceBoard}>
							<Typography variant="gradient">Clear Board</Typography>
						</Button> */}
						<FancyButton style={{marginRight:'20px'}} variant="outlined" onClick={onClickClearResourceBoard}>Clear Board</FancyButton>
						<CopyToClipboard text={`${dataToBeCopy}`} onCopy={onCopyToClicpBoard} options={{ asHtml: true }} >
							<FancyButton>Copy All</FancyButton>
							{/* <Button variant="secondary" sx={{ ml: 2 }}>Copy All</Button> */}
						</CopyToClipboard>
					</Box>
					: null
				}
			</PageTitle>

			{resourceBoardItems.length
				?
				<Card sx={{ mt: 4 }} variant="outlined">
					<CardContent sx={{ p: 2, }}>
						<TableContainer sx={{ pt: 1, mb: -2 }}>
							<Table>
								<TableHead sx={{
									background: 'transparent'
								}}>
									<TableRow>
										<TableCell sx={{ width: '50px', pb: 1, }}></TableCell>
										<TableCell sx={{ width: '50%', pb: 1, }}>Name of resource</TableCell>
										<TableCell sx={{ width: '15%', pb: 1, }}>Cancer type</TableCell>
										<TableCell sx={{ width: '15%', pb: 1, }}>Phase of treatment</TableCell>
										<TableCell sx={{ width: '15%', pb: 1, }}>Agency name</TableCell>
										<TableCell sx={{ width: '5%', pb: 1, }}>Language</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{resourceBoardItems.map(function(resourceItem: any, index: number) {

									return (

										<TableRow key={resourceItem.id} sx={{...style.row, ...(resourceBoardItems.length === (index + 1) && { borderBottom: 'none' })}}>
											<TableCell sx={{...style.cell, ...(resourceBoardItems.length === (index + 1) && { borderBottom: 'none' }), maxWidth:'50px' }}>
												<IconClose 
													onClick={() =>  onClickRemoveBoardItem(resourceItem?.resource?.id)}
												/>
												{/* <CloseButton onClick={() => onClickRemoveBoardItem(resourceItem?.resource?.id)} /> */}
											</TableCell>
											<TableCell sx={{...style.cell, ...(resourceBoardItems.length === (index + 1) && { borderBottom: 'none' })}}>
												<Box sx={style.nameContent}>
													<IconBlock
														icon={categorytoIcon(resourceItem?.category?.name)?.icon as string}
														color={categorytoIcon(resourceItem?.category?.name)?.color as string}
													/>
													{/* <Typography sx={{display: 'flex', width:"350px", lineBreak:"anywhere"}}>{resourceItem?.resource?.title}</Typography> */}
													<Link to={`/resources/${resourceItem?.resource?.id}`} className="titleText">
														{/* {props.title} */}
														<Typography sx={{display: 'flex', width:"350px", lineBreak:"anywhere"}}>{resourceItem?.resource?.title}</Typography>
													</Link>
													{/* {resourceItem?.resource?.title} */}
												</Box>
											</TableCell>
											<TableCell sx={{...style.cell, ...(resourceBoardItems.length === (index + 1) && { borderBottom: 'none' })}}>
												<Box sx={{
													minWidth: '120px',
												}}>{resourceItem?.cancerType?.name}</Box>
											</TableCell>
											<TableCell sx={{...style.cell, ...(resourceBoardItems.length === (index + 1) && { borderBottom: 'none' })}}>
												<Box sx={{
													minWidth: '120px',
												}}>{resourceItem?.treatmentPhase?.name}</Box>
											</TableCell>
											<TableCell sx={{...style.cell, ...(resourceBoardItems.length === (index + 1) && { borderBottom: 'none' })}}>
												<Box sx={{
													minWidth: '120px',
												}}>{resourceItem?.cancerCenter?.name}</Box>
											</TableCell>
											<TableCell sx={{...style.cell, ...(resourceBoardItems.length === (index + 1) && { borderBottom: 'none' })}}>
												{resourceItem?.resource?.language && <Box sx={{ minWidth: '80px', }}>
													<Tag>{resourceItem?.resource?.language?.slice(0,3)?.toUpperCase()}</Tag>
												</Box>}
											</TableCell>
										</TableRow>

									)
								})}
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
				:
				<Box sx={{
					width: 350,
					margin: '0 auto',
					textAlign: 'center',
					mt: 4,
				}}>
					<img
						src={process.env.PUBLIC_URL + '/images/search.png'}
						alt="Search Results Icon"
						style={{
							display: 'block',
							width: 248, height: 'auto',
							margin: '0 auto',
						}}
					/>
					<Typography sx={{
						pt: 3, pb: 3,
						fontSize: '1.5rem',
						color: COLORS.GRAY
					}}>Your resource board is empty</Typography>
					{/* <Button onClick={() => history('/search')} sx={{
						width: 305,
					}}>Back to Search Page</Button> */}
					<FancyButton 
						style={{ width: '305px' }}
						onClick={() => history('/search')}
					>Back to Search Page</FancyButton>
				</Box>
			}

			{ showConfirmModal && 
				<Modal
					open={true}
					onClose={handleNo}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<SuspendResource
						title={"Clear Board"}
						message={'Are you sure you’d like to clear your resource board?'} 
						handleSuspendYes={handleYes} 
						handleSuspendClose={handleNo} 
					/>
				</Modal>}
		</>

	)
}