import { COLORS } from "./layout-constants"
export enum CATEGORIES {
    FOOD_INSTABILITY = "Food Instability",
    UTILITY_NEEDS = "Utility Needs",
    FINANCIAL_RESOURCE_STRAIN = "Financial Resource Strain",
    HOUSING_INSTABILITY = "Housing Instability",
    TRANSPORTATION = "Transportation",
    SOCIAL_EMAOTIONAL_HEALTH = "Social/Emotional Health",
    EDUCATION_SUPPORT = "Education Support",
    ELDER_CHILD_CARE = "Elder Care/Child Care",
    EXPOSURE_TO_VIOLENCE = "Exposure to Violence",
    LEGAL = "Legal",
    OTHER ="Other"
}

export const categorytoIcon = (category: string)=> {
    switch (category) {
        case CATEGORIES.FOOD_INSTABILITY:
            return {icon:"diet",color:COLORS.GREEN}
        case CATEGORIES.UTILITY_NEEDS:
            return {icon:"home",color:COLORS.PURPLE}
        case CATEGORIES.FINANCIAL_RESOURCE_STRAIN:
            return {icon:"money",color:COLORS.BLUE_LIGHT}
        case CATEGORIES.HOUSING_INSTABILITY:
            return {icon:"house",color:COLORS.ORANGE}
        case CATEGORIES.TRANSPORTATION:
            return {icon:"truck",color:COLORS.BLUE}
        case CATEGORIES.SOCIAL_EMAOTIONAL_HEALTH:
            return {icon:"mental-health",color:COLORS.YELLOW}
        case CATEGORIES.EDUCATION_SUPPORT:
            return {icon:"graduation",color:COLORS.GREEN_DARK}
        case CATEGORIES.ELDER_CHILD_CARE:
            return {icon:"baby",color:COLORS.GREEN}
        case CATEGORIES.EXPOSURE_TO_VIOLENCE:
            return {icon:"stop-violence",color:COLORS.RED}
        case CATEGORIES.LEGAL:
            return {icon:"Legal",color:COLORS.DARK_PURPLE}
		case CATEGORIES.OTHER:
            return {icon:"star",color:COLORS.VIOLET_LIGHT}
        default:
            return {icon:"",color:""}
    }
}

export const SERVER_APPLICATION_NAME = 'nchip-server'