import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { deepClone } from "../../utils/general";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "./Modal/Modal";
import Loading from "../general/Loading";
import { createTrustedEmailDomains, updateTrustedEmailDomains } from "../../store/actions/trustedDomainsActions";

const patterns = {
  domain: /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
  punycode: /^([A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?)*)(\.?)$/,
  cyrillicDomain: /^((http|https):\/\/)?[a-zа-я0-9]+([\-\.]{1}[a-zа-я0-9]+)*\.[a-zа-я]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
};

Yup.addMethod(Yup.string, 'domain', function pattern(name, message = 'A valid domain is required') {
  const domainRules = [patterns.domain, patterns.punycode, patterns.cyrillicDomain];
  return this.test({
    message,
    test: value => {
      if(value === null || value === '' || value === undefined) return false; 
      return domainRules.every(regex => regex.test(value));
    },
  });
});



const TrustedEmailDomainForm: FC<any> = ({ openModal=true, closeModal, data, onSuccess }) => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [processing, setProcessing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      id: data?.id as string ?? null,
      domain: data?.domain as string ?? "",
    },
    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      domain: Yup.string().domain().required('This field is required'),
    }),
    onSubmit: async (values:any) => {
    
      setProcessing(true);
      let submitValues = deepClone(values);

      if(values.id){
        delete submitValues.id;
        dispatch(updateTrustedEmailDomains({
            body:submitValues,
            id:values.id
          }, 
          async () => {

            setProcessing(false);
            enqueueSnackbar('Domain updated successfully', { variant: "success" });
           
            // fetch latest
            onSuccess?.()
            formik.resetForm();
            closeModal();

          },
          (exception) => {
            setProcessing(false);
            enqueueSnackbar(exception?.message ??'Failed to update domain', { variant: "error" });
          }
        ))
      }
      else{
        delete submitValues.id;
        dispatch(createTrustedEmailDomains({
          body: submitValues
        },
        async (response) =>{

          setProcessing(false);
          enqueueSnackbar('Domain added successfully', { variant: "success" });
          // fetch latest
          onSuccess?.()
          closeModal();
        },
        (exception) =>{
          setProcessing(false);
          enqueueSnackbar(exception.message ?? 'Failed to add domain', { variant: "error" });
        }
      ));
      }
  
    },
  });

  return(
    <>
      {loading && <Loading loading={loading} />}
      <Modal isOpen={openModal} onClose={closeModal} showCloseButton={false} style={{maxWidth:"600px"}}>
          <ModalHeader>
            <Box display={"flex"} justifyContent="space-between" style={{width:"100%"}}>
                <span>{!formik.values.id ? "Add Trusted Email Domain" : "Edit Trusted Email Domain"}</span>
            </Box>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                      <InputLabel>Domain<sup>*</sup></InputLabel>
                      <TextField
                          error={!!formik.errors.domain}
                          placeholder="e.g. google.com"
                          fullWidth
                          size="small"
                          name="domain"
                          onChange={formik.handleChange}
                          value={formik.values.domain}
                          variant="outlined"
                      />
                      <p className="error-message">{formik?.errors?.domain}</p>
                  </Grid>
              </Grid>
            </form>
          </ModalBody>
          <ModalFooter style={{display:'flex', justifyContent:'end'}}>
            <div>
              <Button disabled={processing} variant="outlined" onClick={closeModal} style={{marginRight:"10px"}}>Cancel</Button>
              <Button disabled={processing} onClick={formik.submitForm} variant="contained">
                {processing && <CircularProgress size="sm" />}
                {!formik?.values?.id ? "Save" : "Update"}
              </Button>
            </div>
          </ModalFooter>
      </Modal>
    </>
  )

}

export default TrustedEmailDomainForm;