import { Container } from "@mui/material";
import ScrollToTop from '../../../components/general/ScrollToTop';
import {
	Card,
	CardContent,
	Toolbar,
	AppBar,
	Avatar,
	MenuList,
	MenuItem,
	Box,
	Button,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

function Privacy(props: any) {
const history = useNavigate();

    
    return (
        <>
            <Grid item xs={12}>
				<AppBar position="static">
					<Toolbar disableGutters={true}>
						<Box sx={{ display: 'flex', flexGrow: 1, pt: 1, pb: 1, pl:3, pr:2 }}>
							<img onClick={() => {
								history('/')
							}} alt={'logo'} width={140} height={40} style={{ alignSelf: 'center', cursor: 'pointer', display: 'block' }} src={process.env.PUBLIC_URL + '/images/ternLogo.png'}></img>
						</Box>
					</Toolbar>
				</AppBar>
			</Grid>
        <ScrollToTop />
        <Container maxWidth="lg" style={{marginTop:"25px"}} >
            <>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 26,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        PRIVACY POLICY
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Last updated&nbsp;April 05, 2023
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      This privacy notice for&nbsp;Navigation and Community Health Innovation
      Partners LLC&nbsp;(doing business as&nbsp;Tern Resourcees)&nbsp;("
      <strong>Tern Resourcees</strong>," "<strong>we</strong>," "
      <strong>us</strong>," or "<strong>our</strong>"), describes how and why we
      might collect, store, use, and/or share ("<strong>process</strong>") your
      information when you use our services ("<strong>Services</strong>"), such
      as when you:
    </span>
  </p>
  <ul style={{ marginBottom: "0cm" }}>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Visit our website at&nbsp;
      </span>
      <span style={{ color: "windowtext" }}>
        <a href="http://www.ternresource.com/" target="_blank">
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Arial",sans-serif',
              color: "#3030F1"
            }}
          >
            http://www.ternresource.com
          </span>
        </a>
      </span>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        , or any website of ours that links to this privacy notice
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Engage with us in other related ways, including any sales, marketing, or
        events
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Questions or concerns?&nbsp;
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Reading this privacy notice will help you understand your privacy rights
      and choices. If you do not agree with our policies and practices, please
      do not use our Services. If you still have any questions or concerns,
      please contact us at&nbsp;navcompartners@gmail.com.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        SUMMARY OF KEY POINTS
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our&nbsp;
        </span>
      </em>
    </strong>
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#toc">
      <strong>
        <em>
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Arial",sans-serif',
              color: "#3030F1",
              textDecoration: "none"
            }}
          >
            table of contents
          </span>
        </em>
      </strong>
    </a>
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          &nbsp;below to find the section you are looking for.
        </span>
      </em>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        What personal information do we process?
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;When you visit, use, or navigate our Services, we may process
      personal information depending on how you interact with Tern Resourcees
      and the Services, the choices you make, and the products and features you
      use. Learn more about&nbsp;
    </span>
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#personalinfo">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        personal information you disclose to us
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Do we process any sensitive personal information?
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;We do not process sensitive personal information.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Do we receive any information from third parties?
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;We do not receive any information from third parties.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        How do we process your information?
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;We process your information to provide, improve, and administer our
      Services, communicate with you, for security and fraud prevention, and to
      comply with law. We may also process your information for other purposes
      with your consent. We process your information only when we have a valid
      legal reason to do so. Learn more about&nbsp;
    </span>
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#infouse">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        how we process your information
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        In what situations and with which&nbsp;parties do we share personal
        information?
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;We may share information in specific situations and with specific
      third parties. Learn more about&nbsp;
    </span>
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#whoshare">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        when and with whom we share your personal information
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        How do we keep your information safe?
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;We have organizational and technical processes and procedures in
      place to protect your personal information. However, no electronic
      transmission over the internet or information storage technology can be
      guaranteed to be 100% secure, so we cannot promise or guarantee that
      hackers, cybercriminals, or other unauthorized third parties will not be
      able to defeat our security and improperly collect, access, steal, or
      modify your information. Learn more about&nbsp;
    </span>
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#infosafe">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        how we keep your information safe
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        What are your rights?
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;Depending on where you are located geographically, the applicable
      privacy law may mean you have certain rights regarding your personal
      information. Learn more about&nbsp;
    </span>
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#privacyrights">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        your privacy rights
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        How do you exercise your rights?
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;The easiest way to exercise your rights is by submitting a&nbsp;
    </span>
    <a
      href="https://app.termly.io/notify/6a3fbd08-628c-41e3-a82f-d02e355087a7"
      target="_blank"
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        data subject access request
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      , or by contacting us. We will consider and act upon any request in
      accordance with applicable data protection laws.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Want to learn more about what Tern Resourcees does with any information we
      collect?&nbsp;
    </span>
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#toc">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        Review the privacy notice in full
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        TABLE OF CONTENTS
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#infocollect">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        1. WHAT INFORMATION DO WE COLLECT?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#infouse">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#whoshare">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#inforetain">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        4. HOW LONG DO WE KEEP YOUR INFORMATION?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#infosafe">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        5. HOW DO WE KEEP YOUR INFORMATION SAFE?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#infominors">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        6. DO WE COLLECT INFORMATION FROM MINORS?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#privacyrights">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        7. WHAT ARE YOUR PRIVACY RIGHTS?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#DNT">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        8. CONTROLS FOR DO-NOT-TRACK FEATURES
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#caresidents">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#virginia">
      <span
        style={{
          fontSize: 15,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        10. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#policyupdates">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        11. DO WE MAKE UPDATES TO THIS NOTICE?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#contact">
      <span
        style={{
          fontSize: 15,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#request">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </span>
    </a>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        1. WHAT INFORMATION DO WE COLLECT?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 17,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        Personal information you disclose to us
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:&nbsp;
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        We collect personal information that you provide to us.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We collect personal information that you voluntarily provide to us when
      you&nbsp;express an interest in obtaining information about us or our
      products and Services, when you participate in activities on the Services,
      or otherwise when you contact us.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Personal Information Provided by You.
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;The personal information that we collect depends on the context of
      your interactions with us and the Services, the choices you make, and the
      products and features you use. The personal information we collect may
      include the following:
    </span>
  </p>
  <ul style={{ marginBottom: "0cm" }} >
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        email addresses
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Sensitive Information.
      </span>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;We do not process sensitive information.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      All personal information that you provide to us must be true, complete,
      and accurate, and you must notify us of any changes to such personal
      information.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:&nbsp;
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        We process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and
        to comply with law. We may also process your information for other
        purposes with your consent.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </span>
    </strong>
  </p>
  <ul style={{ marginBottom: "0cm" }}>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <strong>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          To deliver and facilitate delivery of services to the user.&nbsp;
        </span>
      </strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        We may process your information to provide you with the requested
        service.
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <strong>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          To evaluate and improve our Services, products, marketing, and your
          experience.
        </span>
      </strong>
      <span
        style={{
          fontSize: 15,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        &nbsp;We may process your information when we believe it is necessary to
        identify usage trends, determine the effectiveness of our promotional
        campaigns, and to evaluate and improve our Services, products,
        marketing, and your experience.
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <strong>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          To identify usage trends.
        </span>
      </strong>
      <span
        style={{
          fontSize: 15,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        &nbsp;We may process information about how you use our Services to
        better understand how they are being used so we can improve them.
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        &nbsp;We may share information in specific situations described in this
        section and/or with the following third parties.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We&nbsp;may need to share your personal information in the following
      situations:
    </span>
  </p>
  <ul style={{ marginBottom: "0cm" }}>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <strong>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          Business Transfers.
        </span>
      </strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        &nbsp;We may share or transfer your information in connection with, or
        during negotiations of, any merger, sale of company assets, financing,
        or acquisition of all or a portion of our business to another company.
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        4. HOW LONG DO WE KEEP YOUR INFORMATION?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:&nbsp;
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        We keep your information for as long as necessary
        to&nbsp;fulfill&nbsp;the purposes outlined in this privacy notice unless
        otherwise required by law.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We will only keep your personal information for as long as it is necessary
      for the purposes set out in this privacy notice, unless a longer retention
      period is required or permitted by law (such as tax, accounting, or other
      legal requirements).&nbsp;No purpose in this notice will require us
      keeping your personal information for longer than&nbsp;2 years.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      When we have no ongoing legitimate business need to process your personal
      information, we will either delete or&nbsp;anonymize&nbsp;such
      information, or, if this is not possible (for example, because your
      personal information has been stored in backup archives), then we will
      securely store your personal information and isolate it from any further
      processing until deletion is possible.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        5. HOW DO WE KEEP YOUR INFORMATION SAFE?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:&nbsp;
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        We aim to protect your personal information through a system
        of&nbsp;organizational&nbsp;and technical security measures.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We have implemented appropriate and reasonable technical
      and&nbsp;organizational&nbsp;security measures designed to protect the
      security of any personal information we process. However, despite our
      safeguards and efforts to secure your information, no electronic
      transmission over the Internet or information storage technology can be
      guaranteed to be 100% secure, so we cannot promise or guarantee that
      hackers, cybercriminals, or other&nbsp;unauthorized&nbsp;third parties
      will not be able to defeat our security and improperly collect, access,
      steal, or modify your information. Although we will do our best to protect
      your personal information, transmission of personal information to and
      from our Services is at your own risk. You should only access the Services
      within a secure environment.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        6. DO WE COLLECT INFORMATION FROM MINORS?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        &nbsp;We do not knowingly collect data from or market to children under
        18 years of age.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We do not knowingly solicit data from or market to children under 18 years
      of age. By using the Services, you represent that you are at least 18 or
      that you are the parent or guardian of such a minor and consent to such
      minor dependent’s use of the Services. If we learn that personal
      information from users less than 18 years of age has been collected, we
      will deactivate the account and take reasonable measures to promptly
      delete such data from our records. If you become aware of any data we may
      have collected from children under age 18, please contact us
      at&nbsp;info@ternresource.com.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        7. WHAT ARE YOUR PRIVACY RIGHTS?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        &nbsp; You may review, change, or terminate your account at any time.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#595959" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If you are located in the EEA or UK and you believe we are unlawfully
      processing your personal information, you also have the right to complain
      to your&nbsp;
    </span>
    <a
      href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
      target="_blank"
    >
      <span
        style={{
          fontSize: 15,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        Member State data protection authority
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;or&nbsp;
    </span>
    <a
      href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
      target="_blank"
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        UK data protection authority
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If you are located in Switzerland, you may contact the&nbsp;
    </span>
    <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        Federal Data Protection and Information Commissioner
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <u>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          Withdrawing your consent:
        </span>
      </u>
    </strong>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      &nbsp;If we are relying on your consent to process your personal
      information, which may be express and/or implied consent depending on the
      applicable law, you have the right to withdraw your consent at any time.
      You can withdraw your consent at any time by contacting us by using the
      contact details provided in the section "
    </span>
    <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#contact">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      "&nbsp;below.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      However, please note that this will not affect the lawfulness of the
      processing before its withdrawal nor,&nbsp;when applicable law
      allows,&nbsp;will it affect the processing of your personal information
      conducted in reliance on lawful processing grounds other than consent.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 15,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If you have questions or comments about your privacy rights, you may email
      us at&nbsp;info@ternresource.com.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        8. CONTROLS FOR DO-NOT-TRACK FEATURES
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Most web browsers and some mobile operating systems and mobile
      applications include a Do-Not-Track ("DNT") feature or setting you can
      activate to signal your privacy preference not to have data about your
      online browsing activities monitored and collected. At this stage no
      uniform technology standard for&nbsp;recognizing&nbsp;and implementing DNT
      signals has been&nbsp;finalized. As such, we do not currently respond to
      DNT browser signals or any other mechanism that automatically communicates
      your choice not to be tracked online. If a standard for online tracking is
      adopted that we must follow in the future, we will inform you about that
      practice in a revised version of this privacy notice.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:&nbsp;
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Yes, if you are a resident of California, you are granted specific
        rights regarding access to your personal information.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      California Civil Code Section 1798.83, also known as the&nbsp;"Shine The
      Light"&nbsp;law, permits our users who are California residents to request
      and obtain from us, once a year and free of charge, information about
      categories of personal information (if any) we disclosed to third parties
      for direct marketing purposes and the names and addresses of all third
      parties with which we shared personal information in the immediately
      preceding calendar year. If you are a California resident and would like
      to make such a request, please submit your request in writing to us using
      the contact information provided below.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If you are under 18 years of age, reside in California, and have a
      registered account with Services, you have the right to request removal of
      unwanted data that you publicly post on the Services. To request removal
      of such data, please contact us using the contact information provided
      below and include the email address associated with your account and a
      statement that you reside in California. We will make sure the data is not
      publicly displayed on the Services, but please be aware that the data may
      not be completely or comprehensively removed from all our systems
      (e.g.,&nbsp;backups, etc.).
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 17,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        CCPA Privacy Notice
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      The California Code of Regulations defines a&nbsp;"resident"&nbsp;as:
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      (1) every individual who is in the State of California for other than a
      temporary or transitory purpose and
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      (2) every individual who is domiciled in the State of California who is
      outside the State of California for a temporary or transitory purpose
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      All other individuals are defined as&nbsp;"non-residents."
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If this definition of&nbsp;"resident"&nbsp;applies to you, we must adhere
      to certain rights and obligations regarding your personal information.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        What categories of personal information do we collect?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We have collected the following categories of personal information in the
      past twelve (12) months:
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <table style={{ border: "none", width: "864.0pt" }}>
    <tbody>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: 14,
                  fontFamily: '"Arial",sans-serif',
                  color: "#595959"
                }}
              >
                Category
              </span>
            </strong>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: 14,
                  fontFamily: '"Arial",sans-serif',
                  color: "#595959"
                }}
              >
                Examples
              </span>
            </strong>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: 14,
                  fontFamily: '"Arial",sans-serif',
                  color: "#595959"
                }}
              >
                Collected
              </span>
            </strong>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              A. Identifiers
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address, and
              account name
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              YES
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              B. Personal information categories listed in the California
              Customer Records statute
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Name, contact information, education, employment, employment
              history, and financial information
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              C. Protected classification characteristics under California or
              federal law
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Gender and date of birth
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              D. Commercial information
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Transaction information, purchase history, financial details, and
              payment information
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              E. Biometric information
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Fingerprints and voiceprints
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              F. Internet or other similar network activity
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Browsing history, search history, online&nbsp;behavior, interest
              data, and interactions with our and other websites, applications,
              systems, and advertisements
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              G. Geolocation data
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Device location
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Images and audio, video or call recordings created in connection
              with our business activities
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              I. Professional or employment-related information
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Business contact details in order to provide you our Services at a
              business level or job title, work history, and professional
              qualifications if you apply for a job with us
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            borderBottom: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              J. Education Information
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Student records and directory information
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            borderTop: "solid black 1.0pt",
            borderLeft: "none",
            borderBottom: "none",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            width: "290.25pt",
            border: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              K. Inferences drawn from other personal information
            </span>
          </p>
        </td>
        <td
          style={{
            width: "441.35pt",
            border: "solid black 1.0pt",
            borderLeft: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </span>
          </p>
        </td>
        <td
          style={{
            width: "126.4pt",
            border: "solid black 1.0pt",
            borderLeft: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              NO
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style={{
            border: "solid black 1.0pt",
            borderTop: "none",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              L. Sensitive Personal Information
            </span>
          </p>
        </td>
        <td
          style={{
            borderTop: "none",
            borderLeft: "none",
            borderBottom: "solid black 1.0pt",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <br />
        </td>
        <td
          style={{
            borderTop: "none",
            borderLeft: "none",
            borderBottom: "solid black 1.0pt",
            borderRight: "solid black 1.0pt",
            padding: ".75pt .75pt .75pt .75pt"
          }}
        >
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              lineHeight: "normal",
              fontSize: 15,
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center"
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: '"Arial",sans-serif',
                color: "#595959"
              }}
            >
              <br />
              &nbsp;NO
              <br />
              &nbsp;
            </span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We will use and retain the collected personal information as needed to
      provide the Services or for:
    </span>
  </p>
  <ul style={{ marginBottom: "0cm" }} >
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Category A -&nbsp;As long as the user has an account with us
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We may also collect other personal information outside of these categories
      through instances where you interact with us in person, online, or by
      phone or mail in the context of:
    </span>
  </p>
  <ul style={{ marginBottom: "0cm" }} >
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Receiving help through our customer support channels;
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Participation in customer surveys or contests; and
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Facilitation in the delivery of our Services and to respond to your
        inquiries.
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        How do we use and share your personal information?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      More information about our data collection and sharing practices can be
      found in this privacy notice.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      You may contact us&nbsp;by email at&nbsp;info@ternresource.com,&nbsp;or by
      referring to the contact details at the bottom of this document.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If you are using an&nbsp;authorized&nbsp;agent to exercise your right to
      opt out we may deny a request if the&nbsp;authorized&nbsp;agent does not
      submit proof that they have been validly&nbsp;authorized&nbsp;to act on
      your behalf.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Will your information be shared with anyone else?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We may disclose your personal information with our service providers
      pursuant to a written contract between us and each service provider. Each
      service provider is a for-profit entity that processes the information on
      our behalf, following the same strict privacy protection obligations
      mandated by the CCPA.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We may use your personal information for our own business purposes, such
      as for undertaking internal research for technological development and
      demonstration. This is not considered to be&nbsp;"selling"&nbsp;of your
      personal information.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Navigation and Community Health Innovation Partners LLC&nbsp;has not
      disclosed, sold, or shared any personal information to third parties for a
      business or commercial purpose in the preceding twelve (12)
      months.&nbsp;Navigation and Community Health Innovation Partners
      LLC&nbsp;will not sell or share personal information in the future
      belonging to website visitors, users, and other consumers.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Your rights with respect to your personal data
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to request deletion of the data — Request to delete
      </span>
    </u>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      You can ask for the deletion of your personal information. If you ask us
      to delete your personal information, we will respect your request and
      delete your personal information, subject to certain exceptions provided
      by law, such as (but not limited to) the exercise by another consumer of
      his or her right to free speech, our compliance requirements resulting
      from a legal obligation, or any processing that may be required to protect
      against illegal activities.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to be informed — Request to know
      </span>
    </u>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Depending on the circumstances, you have a right to know:
    </span>
  </p>
  <ul style={{ marginBottom: "0cm" }} >
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        whether we collect and use your personal information;
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        the categories of personal information that we collect;
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        the purposes for which the collected personal information is used;
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        whether we sell or share personal information to third parties;
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        the categories of personal information that we sold, shared, or
        disclosed for a business purpose;
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        the categories of third parties to whom the personal information was
        sold, shared, or disclosed for a business purpose;
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        the business or commercial purpose for collecting, selling, or sharing
        personal information; and
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        the specific pieces of personal information we collected about you.
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      In accordance with applicable law, we are not obligated to provide or
      delete consumer information that is de-identified in response to a
      consumer request or to re-identify individual data to verify a consumer
      request.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
        Rights
      </span>
    </u>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We will not discriminate against you if you exercise your privacy rights.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to Limit Use and Disclosure of Sensitive Personal Information
      </span>
    </u>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We do not process consumer's sensitive personal information.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Verification process
      </span>
    </u>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Upon receiving your request, we will need to verify your identity to
      determine you are the same person about whom we have the information in
      our system. These verification efforts require us to ask you to provide
      information so that we can match it with information you have previously
      provided us. For instance, depending on the type of request you submit, we
      may ask you to provide certain information so that we can match the
      information you provide with the information we already have on file, or
      we may contact you through a communication method (e.g.,&nbsp;phone or
      email) that you have previously provided to us. We may also use other
      verification methods as the circumstances dictate.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We will only use personal information provided in your request to verify
      your identity or authority to make the request. To the extent possible, we
      will avoid requesting additional information from you for the purposes of
      verification. However, if we cannot verify your identity from the
      information already maintained by us, we may request that you provide
      additional information for the purposes of verifying your identity and for
      security or fraud-prevention purposes. We will delete such additionally
      provided information as soon as we finish verifying you.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Other privacy rights
      </span>
    </u>
  </p>
  <ul style={{ marginBottom: "0cm" }} >
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        You may object to the processing of your personal information.
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        You may request correction of your personal data if it is incorrect or
        no longer relevant, or ask to restrict the processing of the
        information.
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        You can designate an&nbsp;authorized&nbsp;agent to make a request under
        the CCPA on your behalf. We may deny a request from
        an&nbsp;authorized&nbsp;agent that does not submit proof that they have
        been validly&nbsp;authorized&nbsp;to act on your behalf in accordance
        with the CCPA.
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        You may request to opt out from future selling or sharing of your
        personal information to third parties. Upon receiving an opt-out
        request, we will act upon the request as soon as feasibly possible, but
        no later than fifteen (15) days from the date of the request submission.
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      To exercise these rights, you can contact us&nbsp;by email
      at&nbsp;info@ternresource.com,&nbsp;or by referring to the contact details
      at the bottom of this document. If you have a complaint about how we
      handle your data, we would like to hear from you.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#6C7794" }}>
      <br />
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        10. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#6C7794" }}>
      <br />
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        &nbsp;Yes, if you are a resident of Virginia, you may be granted
        specific rights regarding access to and use of your personal
        information.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#6C7794" }}>
      <br />
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 17,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        Virginia CDPA Privacy Notice
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#6C7794" }}>
      <br />
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Under the Virginia Consumer Data Protection Act (CDPA):
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#6C7794" }}>
      <br />
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      "Consumer"&nbsp;means a natural person who is a resident of the
      Commonwealth acting only in an individual or household context. It does
      not include a natural person acting in a commercial or employment context.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#6C7794" }}>
      <br />
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      "Personal data"&nbsp;means any information that is linked or reasonably
      linkable to an identified or identifiable natural person.&nbsp;"Personal
      data"&nbsp;does not include de-identified data or publicly available
      information.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#6C7794" }}>
      <br />
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      "Sale of personal data"&nbsp;means the exchange of personal data for
      monetary consideration.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#6C7794" }}>
      <br />
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If this definition&nbsp;"consumer"&nbsp;applies to you, we must adhere to
      certain rights and obligations regarding your personal data.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      The information we collect, use, and disclose about you will vary
      depending on how you interact with&nbsp;Navigation and Community Health
      Innovation Partners LLC&nbsp;and our Services. To find out more, please
      visit the following links:
    </span>
  </p>
  <ul style={{ marginBottom: "0cm" }} >
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span style={{ color: "windowtext" }}>
        <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#infocollect">
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Arial",sans-serif',
              color: "#3030F1"
            }}
          >
            Personal data we collect
          </span>
        </a>
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span style={{ color: "windowtext" }}>
        <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#infouse">
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Arial",sans-serif',
              color: "#3030F1"
            }}
          >
            How we use your personal data
          </span>
        </a>
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span style={{ color: "windowtext" }}>
        <a href="https://app.termly.io/dashboard/website/41dca41d-4e0c-49b5-a283-2523f6e9bf16/privacy-policy#whoshare">
          <span
            style={{
              fontSize: 14,
              fontFamily: '"Arial",sans-serif',
              color: "#3030F1"
            }}
          >
            When and with whom we share your personal data
          </span>
        </a>
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Your rights with respect to your personal data
      </span>
    </u>
  </p>
  <ul style={{ marginBottom: "0cm" }}>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to be informed whether or not we are processing your personal data
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to access your personal data
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to correct inaccuracies in your personal data
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to request deletion of your personal data
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to obtain a copy of the personal data you previously shared with
        us
      </span>
    </li>
    <li
      style={{
        marginTop: "0cm",
        marginRight: "0cm",
        marginBottom: "8.0pt",
        marginLeft: "0cm",
        lineHeight: "normal",
        fontSize: 15,
        fontFamily: '"Calibri",sans-serif',
        color: "#6C7794"
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to opt out of the processing of your personal data if it is used
        for targeted advertising, the sale of personal data, or profiling in
        furtherance of decisions that produce legal or similarly significant
        effects ("profiling")
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 15,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Navigation and Community Health Innovation Partners LLC&nbsp;has not sold
      any personal data to third parties for business or commercial
      purposes.&nbsp;Navigation and Community Health Innovation Partners
      LLC&nbsp;will not sell personal data in the future belonging to website
      visitors, users, and other consumers.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 15,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Exercise your rights provided under the Virginia CDPA
      </span>
    </u>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 15,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      More information about our data collection and sharing practices can be
      found in this privacy notice.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      You may contact us by email at info@ternresource.com, by submitting
      a&nbsp;
    </span>
    <a
      href="https://app.termly.io/notify/6a3fbd08-628c-41e3-a82f-d02e355087a7"
      target="_blank"
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        data subject access request
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      , or by referring to the contact details at the bottom of this document.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If you are using an&nbsp;authorized&nbsp;agent to exercise your rights, we
      may deny a request if the&nbsp;authorized&nbsp;agent does not submit proof
      that they have been validly&nbsp;authorized&nbsp;to act on your behalf.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Verification process
      </span>
    </u>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We may request that you provide additional information reasonably
      necessary to verify you and your consumer's request. If you submit the
      request through an&nbsp;authorized&nbsp;agent, we may need to collect
      additional information to verify your identity before processing your
      request.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Upon receiving your request, we will respond without undue delay, but in
      all cases, within forty-five (45) days of receipt. The response period may
      be extended once by forty-five (45) additional days when reasonably
      necessary. We will inform you of any such extension within the initial
      45-day response period, together with the reason for the extension.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <u>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Right to appeal
      </span>
    </u>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 15, fontFamily: "Roboto", color: "#6C7794" }}>
      <br />
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If we decline to take action regarding your request, we will inform you of
      our decision and reasoning behind it. If you wish to appeal our decision,
      please email us at info@ternresource.com. Within sixty (60) days of
      receipt of an appeal, we will inform you in writing of any action taken or
      not taken in response to the appeal, including a written explanation of
      the reasons for the decisions. If your appeal if denied, you may contact
      the&nbsp;
    </span>
    <a
      href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
      target="_blank"
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        Attorney General to submit a complaint
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        11. DO WE MAKE UPDATES TO THIS NOTICE?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <em>
        <span
          style={{
            fontSize: 14,
            fontFamily: '"Arial",sans-serif',
            color: "#595959"
          }}
        >
          In Short:&nbsp;
        </span>
      </em>
    </strong>
    <em>
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#595959"
        }}
      >
        Yes, we will update this notice as necessary to stay compliant with
        relevant laws.
      </span>
    </em>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      We may update this privacy notice from time to time. The updated version
      will be indicated by an updated&nbsp;"Revised"&nbsp;date and the updated
      version will be effective as soon as it is accessible. If we make material
      changes to this privacy notice, we may notify you either by prominently
      posting a notice of such changes or by directly sending you a
      notification. We encourage you to review this privacy notice frequently to
      be informed of how we are protecting your information.
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      If you have questions or comments about this notice, you may&nbsp;email us
      at&nbsp;info@ternresource.com&nbsp;or by post to:
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Navigation and Community Health Innovation Partners LLC
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      PO BOx 1013
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Cheyenne Wells
    </span>
    <span
      style={{
        fontSize: 15,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      ,&nbsp;CO&nbsp;80810
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 15,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      United States
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <strong>
      <span
        style={{
          fontSize: 19,
          fontFamily: '"Arial",sans-serif',
          color: "black"
        }}
      >
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span style={{ fontSize: 16, fontFamily: "Roboto", color: "#6C7794" }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      Based on the applicable laws of your country, you may have the right to
      request access to the personal information we collect from you, change
      that information, or delete it. To request to review, update, or delete
      your personal information, please fill out and submit a&nbsp;
    </span>
    <a
      href="https://app.termly.io/notify/6a3fbd08-628c-41e3-a82f-d02e355087a7"
      target="_blank"
    >
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        data subject access request
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "0cm",
      marginLeft: "0cm",
      lineHeight: "normal",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      This privacy policy was created using Termly's&nbsp;
    </span>
    <a href="https://termly.io/products/privacy-policy-generator/">
      <span
        style={{
          fontSize: 14,
          fontFamily: '"Arial",sans-serif',
          color: "#3030F1"
        }}
      >
        Privacy Policy Generator
      </span>
    </a>
    <span
      style={{
        fontSize: 14,
        fontFamily: '"Arial",sans-serif',
        color: "#595959"
      }}
    >
      .
    </span>
  </p>
  <p
    style={{
      marginTop: "0cm",
      marginRight: "0cm",
      marginBottom: "8.0pt",
      marginLeft: "0cm",
      lineHeight: "107%",
      fontSize: 15,
      fontFamily: '"Calibri",sans-serif'
    }}
  >
    &nbsp;
  </p>
</>

          
        </Container>
        </>
    )

}

export default Privacy