import { genericAction } from './actionFunctions';
import { ACTIONS } from '../../enums/actions';
import { ICreateUploadResourcePayload } from '../../shared/interfaces/model/resource.interfaces';

export const getAllResources = genericAction(ACTIONS.GET_ALL_RESOURSES);
export const getResourceCategories = genericAction(ACTIONS.GET_RESOURSE_CATEGORIES);
export const getCancerTypes = genericAction(ACTIONS.GET_CANCER_TYPE);
export const getCancerCenters = genericAction(ACTIONS.GET_CANCER_CENTER);
export const getTreatmentPhases = genericAction(ACTIONS.GET_TREATMENT_PHASE);
export const createResource = genericAction(ACTIONS.CREATE_RESOURCE);
export const updateResource = genericAction(ACTIONS.UPDATE_RESOURCE);
export const suspendResource = genericAction(ACTIONS.SUSPEND_RESOURCE);
export const getResourceActivityLogs = genericAction(ACTIONS.GET_RESOURCE_ACTIVITY_LOGS);
export const searcNchipResource = genericAction(ACTIONS.SEARCH_NCHIP_RESOURCES);

// Resource Board
export const getResourceBoard = genericAction(ACTIONS.GET_RESOURCE_BOARD);
export const addToResourceBoard = genericAction(ACTIONS.ADD_TO_RESOURCE_BOARD);
export const removeResourceBoardItem = genericAction(ACTIONS.DELETE_RESOURCE_BOARD_ITEM);
export const copyResourceBoard = genericAction(ACTIONS.COPY_RESOURCE_BOARD);
export const clearResourceBoard = genericAction(ACTIONS.CLEAR_RESOURCE_BOARD);

export const createUploadResource = (
  payload: ICreateUploadResourcePayload | any,
  onSuccess?: (data: any) => void,
  onFail?: (data: any) => void
) => ({
  payload,
  type: ACTIONS.CREATE_UPLOAD_RESOURCE,
  onSuccess,
  onFail
});

export const uploadResourceFile = (
	payload: any,
	onSuccess?: () => void,
	onFail?: () => void
  ) => ({
	payload,
	type: ACTIONS.UPLOAD_RESOURCE_FILE,
	onSuccess,
	onFail
  });

export const getUploadUrls = (
	payload: any,
	onSuccess?: (data: any) => void,
	onFail?: () => void,
	onProgress?: (percent: number) => void
) => ({
	payload,
	type: ACTIONS.GET_UPLOAD_URLS,
	onSuccess,
	onFail,
	onProgress
});
