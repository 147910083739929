import { Box } from '@mui/material';

import Header from './Header';

export default function PageWrap(props: any) {

	const style = {
		background: {
			width: {
				xs: '100%',
			},
			display: 'block',
			position: 'absolute',
			left: 0, top: 0, right: 0,
			zIndex: -1,
		},
		image: {
			display: 'block',
			width: '100%', height: 'auto',
		}
	}

	return (
		<>
			<Header />
			<Box sx={{ pl: 2, pr: 2, maxWidth: 1620, margin: '0 auto', minHeight:"85vh" }}>
				<Box sx={style.background}>
					<img src={process.env.PUBLIC_URL + '/images/background.svg'} style={style.image} alt="" />
				</Box>
				{props.children}
			</Box>
		</>

	)
}