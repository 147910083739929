export enum ACTIONS {
	ERROR = "ERROR",
	SUCCESS = "SUCCESS",
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",
	REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
	PASSWORD_RESET = 'PASSWORD_RESET',
	SET_CLIENT_OPTIONS = 'SET_CLIENT_OPTIONS',

	//auth
	CONFIRM_EMAIL = 'CONFIRM_EMAIL',
	NAVIGATOR_LOGIN = 'NAVIGATOR_LOGIN',

	//user
	GET_USER = 'GET_USER',
	GET_USERS = 'GET_USERS',
	CREATE_USER = 'CREATE_USER',
	UPDATE_USER = 'UPDATE_USER',
	GET_USER_NOTES = 'GET_USER_NOTES',
	CREATE_USER_NOTE = 'CREATE_USER_NOTE',
	UPDATE_USER_NOTE = 'UPDATE_USER_NOTE',
	DELETE_USER_NOTE = 'DELETE_USER_NOTE',
	USER_ACTIVATION = 'USER_ACTIVATION',
	ADD_REMOVE_ROLE = 'ADD_REMOVE_ROLE',

	//REPORTING
	DOWNLOAD_USERS_REPORT = 'DOWNLOAD_USERS_REPORT',
	GET_USER_ROLES = 'GET_USER_ROLES',
	GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA',
	GET_DASHBOARD_DETAIL_DATA = 'GET_DASHBOARD_DETAIL_DATA',
	SET_TIMEZONE = 'SET_TIMEZONE',

	//RESOURCES
	CREATE_UPLOAD_RESOURCE = 'CREATE_UPLOAD_RESOURCE',
	UPLOAD_RESOURCE_FILE = 'UPLOAD_RESOURCE_FILE',
	GET_UPLOAD_URLS = 'GET_UPLOAD_URLS',
	GET_RESOURCE = 'GET_RESOURCE',

	GET_RESOURCE_BOARD = 'GET_RESOURCE_BOARD',
	ADD_TO_RESOURCE_BOARD = 'ADD_TO_RESOURCE_BOARD',
	DELETE_RESOURCE_BOARD_ITEM = 'DELETE_RESOURCE_BOARD_ITEM',
	COPY_RESOURCE_BOARD = 'COPY_RESOURCE_BOARD',
	CLEAR_RESOURCE_BOARD = 'CLEAR_RESOURCE_BOARD',

	//AWS
	INIT_PRESIGNED_URL_MULTIPART_UPLOAD = 'INIT_PRESIGNED_URL_MULTIPART_UPLOAD',
	INIT_PRESIGNED_URL_SIMPLE_UPLOAD = 'INIT_PRESIGNED_URL_SIMPLE_UPLOAD',
	COMPLETE_MULTIPART_UPLOAD = 'COMPLETE_MULTIPART_UPLOAD',
	INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS",
	INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS",
	CREATE_UPLOAD_RESOURCE_SUCCESS = "CREATE_UPLOAD_RESOURCE_SUCCESS",
	GET_PRESIGNED_UPLOAD_URLS_SUCCESS = 'GET_PRESIGNED_UPLOAD_URLS_SUCCESS',
	GET_PRESIGNED_UPLOAD_URL = 'GET_PRESIGNED_UPLOAD_URL',
	GET_FILE_LINK = 'GET_FILE_LINK',

	// NCHIP RESOURCES
	GET_ALL_RESOURSES = "GET_ALL_RESOURSES",
	GET_RESOURSE_CATEGORIES = "GET_RESOURSE_CATEGORIES",
	GET_CANCER_TYPE = "GET_CANCER_TYPE",
	GET_TREATMENT_PHASE = "GET_TREATMENT_PHASE",
	GET_CANCER_CENTER = "GET_CANCER_CENTER",
	CREATE_RESOURCE = "CREATE_RESOURCE",
	UPDATE_RESOURCE = "UPDATE_RESOURCE",
	SUSPEND_RESOURCE = "SUSPEND_RESOURCE",
	GET_RESOURCE_ACTIVITY_LOGS = "GET_RESOURCE_ACTIVITY_LOGS",
	SEARCH_NCHIP_RESOURCES = "SEARCH_NCHIP_RESOURCES",

	// TRUSTED EMAIL DOMAINS
	GET_ALL_EMAIL_DOMAINS = 'GET_ALL_EMAIL_DOMAINS',
	CREATE_EMAIL_DOMAIN = 'CREATE_EMAIL_DOMANS',
	UPDATE_EMAIL_DOMAIN = 'UPDATE_EMAIL_DOMAIS',
	DELETE_EMAIL_DOMAIN = 'DELETE_EMAIL_DOMAIN',

	// STATS
	GET_DASHBOARD_STATS = 'GET_DASHBOARD_STATS',
}
