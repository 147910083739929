import {
	Typography
} from '@mui/material';

import theme from '../../../themes/theme';
import ResourceCard from '../../../components/shared/resource/ResourceCard';

export default function ResourceCardPage(props: any) {
	return (

		<>
			<Typography variant="h1" sx={{ mt: 4, mb: 4, }}>Resource Card</Typography>
			<ResourceCard
				color={theme.palette.violet.main}
				icon="truck"
				title="Lorem Ipsum dolor sit amet consectetur adipisicing elit"
				type="Lorem"
				phase="Ipsum"
				agency="Lorem ipsum"
				language="Spanish"
				text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat similique illo expedita! Consectetur odit iusto aperiam odio veritatis quisquam enim ad atque reiciendis, hic consequuntur iure. Qui voluptatibus doloremque maiores, molestias inventore a nulla temporibus assumenda. Quos, error, quo cumque earum nesciunt eveniet?"
			/>
		</>

	)
}