import {
	Box,
	Grid,
	useMediaQuery,
	useTheme,

} from '@mui/material';

import { useDispatch } from 'react-redux';
import SearchCarousel from '../../../components/resources/SearchCarousel';
import SearchForm from '../../../components/shared/form/SearchForm';
import Breadcrumbs from '../../../components/shared/Breadcrumbs';
import { useNavigate, useLocation } from 'react-router';
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { getResourceCategories} from "../../../store/actions/resourceActions";
import StaticSearchResources from "../../../components/resources/StaticSearchResources";

export default function	SearchPage(props: any) {

	const navigate = useNavigate();
	const location = useLocation();
    const dispatch = useDispatch();

	const theme = useTheme();
	const isScreenSM = useMediaQuery(theme.breakpoints.down('md'));

	const [categoriesOptions, setCategoriesOptions] = useState<any[]>([]);
	// search Form
	const [search, setSearch] = useState("");
	const [address, setAddress] = useState("");
	const [latLng, setlatLng] = useState({} as any);
	const [selectedRadiusOptions, setSelectedRadiusOptions] = useState<string[]>([]);
	const [selectedCategoriesId, setSelectedCategoriesId] = useState<number[]>([]);
	
	useEffect(() => {
		dispatch(getResourceCategories(
			{},
			(response) => {
				setCategoriesOptions(response);
			},
			() => {},
		))
	}, []);
	if(address != "" && selectedRadiusOptions.length === 0 ){
		setSelectedRadiusOptions(["10"])
	}
	

	const handleSearch = async () => {
		const params = {
			address,
			lat: latLng?.lat?.toString(),
			lng: latLng?.lng?.toString(),
			rd:selectedRadiusOptions[0],
			ctgIds: selectedCategoriesId.length > 0 ? selectedCategoriesId.join(",") : undefined,
			kw: search
		  };
		const queryString = Object.entries(params)
		.filter(([_, value]) => value)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join("&");
		navigate({
			pathname: location.pathname+'/results',
			search:  queryString ? `?${queryString}` : ""
		})
	}
	return (
		<Box justifyContent="center">
			<Breadcrumbs title="Search" />
			<SearchCarousel />
			<Box sx={{
				maxWidth: isScreenSM ? '100%' : "80%",
				padding: isScreenSM ? '0' : "0 40px",
				margin: "-45px auto 0 auto"
			}}>
				<SearchForm // can use outlet here for keeping only onesearch from
					setAddress={setAddress}
					setlatLng={setlatLng}
					latLng={latLng}
					address={address}
					selectedRadiusOptions={selectedRadiusOptions}
					setSelectedRadiusOptions={setSelectedRadiusOptions}
					search={search}
					setSearch={setSearch}
					handleSearch={() => {handleSearch()}}
					showSearchResult={false}
					categoriesOptions={categoriesOptions}
					setSelectedCategoriesId = {setSelectedCategoriesId}
					selectedCategoriesId = {selectedCategoriesId}
				/>
			</Box>
			
			<Box>
					<StaticSearchResources/>
			</Box>

		</Box>

	)
}