import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	IconButton,
	TextField,
	Typography,
	Modal,
	CircularProgress,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Unstable_Grid2';

import theme from '../../../themes/theme';

import PageWrap from '../../../components/shared/PageWrap';
import Header from '../../../components/shared/Header';
import Breadcrumbs from '../../../components/shared/Breadcrumbs';
import ResourceDetailBlock from '../../../components/shared/resource/ResourceDetailBlock';
import Suspended from '../../../components/shared/Suspended';
import CardHeader from '../../../components/shared/CardHeader';
import ResourceEditForm from '../../../components/shared/resource/ResourceEditForm';
import ResourceActivityTable from '../../../components/shared/resource/ResourceActivityTable';

import EditIcon from '../../../components/icons/Edit2Icon';
import CopyIcon from '../../../components/icons/CopyIcon';
import CheckIcon from '../../../components/icons/CheckIcon';
import { useDispatch } from 'react-redux';
import { getResource } from '../../../store/actions/userActions';
import { useNavigate, useParams } from 'react-router-dom';
import ResourceForm from '../../../components/shared/ResourceForm';
import { addToResourceBoard, getCancerCenters, getCancerTypes, getResourceCategories, getTreatmentPhases } from '../../../store/actions/resourceActions';
import { useSnackbar } from 'notistack';
import { formatTime, isUserAdmin } from '../../../utils/general';
import { categorytoIcon } from '../../../shared/constants/category-icons';
import { getFileLink } from '../../../store/actions/awsActions';
import { useSelector } from '../../../store';
import { IRootState } from '../../../interfaces/store';

function ResourceDetailPage() {

	let { id:resourceId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const resourceBoardItemsIds: any = useSelector((state: IRootState) => {
		return Object.values(state.main.boardNchipResource).reduce((prev:any, curr:any) => {

			const resource = state.main.nchipResource[curr.resource_id];

			if(resource) {
				prev.push(resource.id);
			}

			return prev;
		}, [])
	});
	const isAlreadyAdded = resourceBoardItemsIds.includes(resourceId ? +resourceId : resourceId);

	const [openModal, setModalOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [resourceData, setResourceData] = useState<any>(null);
	const [categories, setCategories] = useState<any[]>([]);
	const [cancerTypes, setCancerTypes] = useState<any[]>([]);
	const [cancerCenters, setCancerCenters] = useState<any[]>([]);
	const [treatmentPhases, setTreatmentPhases] = useState<any[]>([]);
	const [fileLoading, setFileLoading] = useState<boolean>(false);

	const [descriptionDisabled, setDescriptionDisabled] = useState(true);

	const tableRef = useRef<any>(null);

	const isAdmin = isUserAdmin();
	
	useEffect(() => {
		fetchData();
	}, [])

	const fetchData = () => {
		const resourcesPromise = new Promise((resolve, reject) => {
			dispatch(getResource(
				{body:{}, id:resourceId},
				(res) => {
					resolve(res);
				},
				(error) => {
					reject(error)
				},
			));
		})
		const categoriesPromise = new Promise((resolve, reject) => {
			dispatch(getResourceCategories(
				{},
				(response) => {
					resolve(response)
				},
				(error) => { reject(reject) },
			))
		})
		const cancerTypesPromise = new Promise((resolve, reject) => {
			dispatch(getCancerTypes(
				{},
				(response) => {
					resolve(response)
				},
				(error) => { reject(reject) },
			))
		})
		const cancerCentersPromise = new Promise((resolve, reject) => {
			dispatch(getCancerCenters(
				{},
				(response) => {
					// console.log({ response});
					// setCancerCenters(response);
					resolve(response)
				},
				(error) => { reject(reject) },
			))
		})
		const treatmentPromise = new Promise((resolve, reject) => {
			dispatch(getTreatmentPhases(
				{},
				(response) => {
					// console.log({ response});
					// setTreatmentPhases(response);
					resolve(response)
				},
				(error) => { reject(reject) },
			))
		})
	
		Promise.all([resourcesPromise, categoriesPromise, cancerTypesPromise, cancerCentersPromise, treatmentPromise])
			.then(([resource, categories, cancerTypes, cancerCenters, treatmentTypes]: any[]) => {

				setCategories(categories);
				setCancerTypes(cancerTypes);
				setCancerCenters(cancerCenters);
				setTreatmentPhases(treatmentTypes);

				setResourceData(resource)
				setLoading(false);

			})
			.catch((error) => {
				
				setLoading(false);
				enqueueSnackbar('Resource not found', { variant: "error" });

				if(isAdmin) return navigate("/resources");
				return navigate("/search");

			})
	}

	const onClickEdit = () => {
		setModalOpen(true);
	}

	const onEditSuccess = () => {
		fetchData();
		if(tableRef.current) tableRef.current.onQueryChange();
	}

	const closeModal = () => {
		setModalOpen(false);
	}
	
	const handleEditDescription = () => {
		setDescriptionDisabled(prev => !prev);
	}

	const [openEditModal, setOpenEditModal] = useState(false);
	const handleOpenEditModal = () => {
		setOpenEditModal(true);
	}
	const handleCloseEditModal = () => {
		setOpenEditModal(false);
	}

	const file = resourceData?.files?.[0];
	const fileName = file?.original_filename; 
	const fileUrl = file?.signed_url?.split('?')?.[0];

	const onClickGetFile = () => {
		
		if(!file) return;
	
		setFileLoading(true);
		dispatch(getFileLink(
		  {
			name: file?.name, 
			fileNameAs: file?.original_filename, 
			model: file?.model,
		  },
		  (response) => { 
			let link = document.createElement("a");
			link.href = response.url;
			link.download = file?.original_filename;
			link.click();
			setFileLoading(false);
		  },
		  (error) => {
			setFileLoading(false);
		  },
		))
	}

	const onClickAddToresourceBoard = () => {

		setLoading(true);

		dispatch(addToResourceBoard(
			{ body: {
				"resource_id": resourceId ? +resourceId : resourceId
			  } 
			},
			(response) => {
				enqueueSnackbar('Successfully added to board', {variant: 'success'})
				setLoading(false);
			},
			(error) => {
				enqueueSnackbar('Failed to added to board', {variant: 'error'})
				setLoading(false);
			},
		));

	}

	return (

		<>
			<Breadcrumbs
				title={resourceData?.title} 
				pages={[]}
			/>

			{resourceData != null &&
				<>
					<Card variant="outlined" sx={{ flexGrow: 1 }}>
						
						<CardHeader title={resourceData?.title}>
							<Button
								color="primary"
								variant="icon"
								onClick={onClickEdit}
							>
								<EditIcon />
							</Button>
							<ResourceEditForm
								openEditModal={openEditModal}
								handleCloseEditModal={handleCloseEditModal}
							/>
							<Button disabled={loading || isAlreadyAdded} onClick={onClickAddToresourceBoard} variant="secondary" sx={{ ml: 2 }}>
								{loading && <CircularProgress color="inherit" size={18} sx={{marginRight:'5px'}}/>}
								Add to Board
								</Button>
						</CardHeader>

						<Box sx={{ p: 4 }}>
							<Grid container spacing={4} sx={{ alignItems: 'flex-start', }}>
								<Grid xs={12} lg={3} xl={4}>
									<Box>
										<Box sx={{ display: 'flex', mb: '3px', }}>
											<Typography sx={{ fontSize: '1rem', }}>Description</Typography>
											{/* <Typography
												sx={{ ml: 'auto', cursor: 'pointer', fontSize: '0.875rem', }}
												color="primary"
												onClick={() => handleEditDescription()}
											>
												{descriptionDisabled ? 
													<EditIcon sx={{ width: 12, height: 12, mr: 1, }} />
												:
													<CheckIcon sx={{ width: 12, height: 12, mr: 1, }} />
												}
												<Typography variant="gradient">{descriptionDisabled ? "Edit" : "Save"}</Typography>
											</Typography> */}
										</Box>
										{!resourceData?.description && <TextField
											fullWidth
											multiline
											rows={6}
											maxRows={6}
											placeholder="Enter text..."
											value={resourceData?.description}
											// onChange={(e) => setDescription(e?.target.value)}
											disabled={descriptionDisabled}
										/>}
										{resourceData?.description && <Typography sx={{fontSize: '0.875rem', maxHeight: '160px', overflowY: 'auto'}}>{resourceData?.description}</Typography>}
									</Box>
								</Grid>
								<Grid container xs={12} lg={9} xl={8} spacing={3}>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon={categorytoIcon(resourceData?.category.name)?.icon as string}
											title="Category"
											content={resourceData?.category?.name}
										/>
									</Grid>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="clock"
											title="Hours Available"
											description={resourceData?.cta_text}
											// content="08:00 AM - 10:00 PM"
											content={`${resourceData?.hours_from ? formatTime(resourceData?.hours_from):''} - ${resourceData?.hours_to ? formatTime(resourceData?.hours_to):''}`}
										/>
									</Grid>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="email"
											title="Email"
											content={resourceData?.email_address}
											description={resourceData?.email_address}
										/>
									</Grid>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="map"
											title="Location"
											content={resourceData?.address}
											description={resourceData?.address}
										/>
									</Grid>

									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="first-aid-kit"
											title="Phase"
											content={resourceData?.treatmentPhase?.name}
										/>
									</Grid>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="user"
											title="Contact Person"
											content={resourceData?.contact_person}
										/>
									</Grid>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="phone"
											title="Phone Number"
											content={resourceData?.phone_number}
										/>
									</Grid>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="building"
											title="Cancer Center Source"
											content={resourceData?.cancerCenter?.name}
										/>
									</Grid>

									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="virus"
											title="Cancer Type"
											content={resourceData?.cancerType?.name}
										/>
									</Grid>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="language"
											title="Language"
											content={resourceData?.language}
										/>
									</Grid>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="internet"
											title="url"
											content={resourceData?.cta_url}
											description={resourceData?.cta_url}
										/>
									</Grid>
									<Grid xs={6} lg={3}>
										<ResourceDetailBlock
											icon="building"
											title="Attachment"
											content={fileName}
											button={fileUrl}
											onClick={onClickGetFile}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Card>
					<Card variant="outlined" sx={{ mt: 4, mb: 4, }}>
						<CardHeader title={`Activity Log (Copies: ${resourceData?.copy_count})`} style={{ borderBottom: 'none' }}>
							{/* <Button variant="icon">
								<EditIcon />
							</Button>
							<Button variant="icon" sx={{ ml: 2 }}>
								<CopyIcon />
							</Button> */}
						</CardHeader>

						<Box style={{ width: '100%' }}>
							<ResourceActivityTable ref={tableRef} resourceId={resourceId} />
						</Box>
					</Card>
				</>
			}
			{openModal && <ResourceForm
				data={resourceData}
				openModal={openModal} 
				closeModal={closeModal}
				categories={categories}
				cancerTypes={cancerTypes}
				cancerCenters={cancerCenters}
				treatmentPhases={treatmentPhases}
				onSuccess={onEditSuccess}
			/>}
		</>

	)
}

export default ResourceDetailPage;