import React, { Dispatch, SetStateAction, useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import ToggleOn from '@mui/icons-material/ToggleOn';
import ToggleOff from '@mui/icons-material/ToggleOff';
import Edit from '@mui/icons-material/Edit';
import { COLORS } from '../../enums/layout-enums';
import { Tooltip } from '@mui/material';
import StandardModal from '../shared/StandardModal';
import { useDispatch } from 'react-redux';
import { addRemoveRole, userActivation } from '../../store/actions/userActions';
import Loading from '../general/Loading';
import { useSnackbar } from 'notistack';
import HttpClient from '../../api/HttpClient';
import { format } from 'date-fns'
import AddEditUserForm from '../shared/AddUserForm';
import { DEFAULT_DATE_FORMATE } from '../../shared/constants/app-constants';

const client = new HttpClient();
interface IUserTable {
  title: string;
  userTableKey: number;
  setUserTableKey: Dispatch<SetStateAction<number>>;
}

const iconStyle = {
  color: COLORS.BLUE_BRAND_PRIMARY,
  cursor: 'pointer',
};

const UserTable: React.FC<IUserTable> = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState({} as any);
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [addEditUserModalOpen, setAddEditUserModalOpen] = useState(false);
  const [userAdminStatusModalOpen, setUserAdminStatusModalOpen] =
    useState(false);

  const handleAddEditUserModalOpen = () => {
    setAddEditUserModalOpen(true);
  };

  const handleAddEditUserModalClose = () => {
    setAddEditUserModalOpen(false);
  };

  //   const [rowData, setRowData] = useState({} as any);
  const { title } = props;
  const columns = [
    {
      title: 'Id',
      field: 'id',
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: 'First Name',
      field: 'first_name',
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: 'Last Name',
      field: 'last_name',
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: 'Role',
      field:'role',
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
      render: (rowData:any) => {
        return (
          <div>
            {rowData.roles.map((role:any) => role).join(',')}
          </div>
        )
      }
    },
    {
      title: 'Last Login',
      field: 'last_login',
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
      render: (row: any) => {
        if (row?.last_login)
          return <span>{format(new Date(row?.last_login), DEFAULT_DATE_FORMATE)}</span>;
      },
    },
    {
      title: 'Email',
      field: 'email',
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: 'User Actions',
      sorting: false,
      render: (row: any) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Tooltip title={'Edit User'} placement="top">
              <Edit
                style={iconStyle}
                onClick={() => {
                  setSelectedUserData({ ...row });
                  handleAddEditUserModalOpen();
                }}
              />
            </Tooltip>
            {row.active ? (
              <Tooltip title={'Deactivate'} placement="top">
                <ToggleOn
                  style={iconStyle}
                  onClick={() => {
                    setSelectedUserData({
                      ...row,
                    });
                    setActivationModalOpen(true);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={'Activate'} placement="top">
                <ToggleOff
                  style={{ cursor: "pointer", color:"grey"}}
                  onClick={() => {
                    setSelectedUserData({
                      ...row,
                    });
                    setActivationModalOpen(true);
                  }}
                />
              </Tooltip>
            )}
            {/* {row.roles?.includes('Admin') ? <Tooltip title={"Demote From Admin"} placement="top">
							<IsAdminUser
								style={iconStyle}
								onClick={() => {
									setSelectedUserData({
										...row
									})
									setUserAdminStatusModalOpen(true);
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Promote to Admin"} placement="top">
								<NotAdminUser
									style={iconStyle}
									onClick={() => {
										setSelectedUserData({
											...row
										})
										setUserAdminStatusModalOpen(true);
									}}
								/>
							</Tooltip>
						} */}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Loading loading={processing} />
      <StandardModal
        title={'Activation Modal'}
        open={activationModalOpen}
        onClose={() => setActivationModalOpen(false)}
        paragraphs={[
          `Would you like to ${
            selectedUserData.active ? 'deactivate' : 'activate'
          } ${selectedUserData.email}?`,
        ]}
        actions={[
          {
            title: 'Cancel',
            callback: () => {
              setActivationModalOpen(false);
            },
          },
          {
            title: 'Yes',
            callback: () => {
              setProcessing(true);
              dispatch(
                userActivation(
                  {
                    user_id: selectedUserData.id,
                    active: !selectedUserData.active,
                  },
                  () => {
                    enqueueSnackbar(
                      `User active status changed ${selectedUserData.email}.`,
                      { variant: 'success' },
                    );
                    setActivationModalOpen(false);
                    props.setUserTableKey(props.userTableKey + 1);
                    setProcessing(false);
                  },
                  () => {
                    enqueueSnackbar(
                      `Error changing the active status of user.`,
                      { variant: 'error' },
                    );
                    setProcessing(false);
                    setActivationModalOpen(false);
                  },
                ),
              );
            },
          },
        ]}
        customActions={[]}
      />
      <StandardModal
        title={'Alter User Admin Status'}
        open={userAdminStatusModalOpen}
        onClose={() => setUserAdminStatusModalOpen(false)}
        paragraphs={[
          `Would you like to ${
            selectedUserData.roles?.includes('Admin') ? 'demote' : 'promote'
          } ${selectedUserData.email} from Admin Status?`,
        ]}
        actions={[
          {
            title: 'Cancel',
            callback: () => {
              setUserAdminStatusModalOpen(false);
            },
          },
          {
            title: 'Yes',
            callback: () => {
              setProcessing(true);
              dispatch(
                addRemoveRole(
                  {
                    user_id: selectedUserData.id,
                    promote: !selectedUserData.roles?.includes('Admin'),
                    role: 'Admin',
                  },
                  () => {
                    setUserAdminStatusModalOpen(false);
                    props.setUserTableKey(props.userTableKey + 1);
                    setProcessing(false);
                  },
                  () => {
                    setProcessing(false);
                    setUserAdminStatusModalOpen(false);
                  },
                  `User admin status changed ${selectedUserData.email}.`,
                  true,
                ),
              );
            },
          },
        ]}
        customActions={[]}
      />
      <div key={props.userTableKey}>
        <MaterialTable
          icons={tableIcons}
          title={title}
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              let url = 'api/user/query?';
              url += `inActive=${true}&`;
              url += `take=${query.pageSize}&`;
              url += `skip=${query.page * query.pageSize}&`;
              if (query.orderBy?.field) {
                url += `orderBy=${String(query.orderBy.field)}&`;
              }
              if (query.orderDirection) {
                url += `orderDirection=${query.orderDirection}&`;
              }
              url += `search=${query.search}`;
              (async () => {
                try {
                  const result = await client.get(url);
                  if (result.data) {
                    resolve({
                      data: result.data.data,
                      page: query.page,
                      totalCount: result.data.total,
                    });
                  } else {
                    reject(new Error('Un-Authorized'));
                  }
                } catch (error) {
                  reject(error);
                }
              })();
            })
          }
          options={{
            sorting: true,
            search: true,
            pageSizeOptions: [10, 15, 20],
          }}
        />
      </div>

      <StandardModal
        actions={[]}
        title={'Edit User'}
        open={addEditUserModalOpen}
        onClose={handleAddEditUserModalClose}
      >
        <AddEditUserForm
          isEdit
          user={selectedUserData}
          handleCloseModal={handleAddEditUserModalClose}
          onSuccess={() => props.setUserTableKey(props.userTableKey + 1)}
        />
      </StandardModal>
    </div>
  );
};

export default UserTable;
