import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const style = {
	svg: {
		width: 35, height: 35
	}
}

function LockIcon(props: any) {

	return (
		<SvgIcon viewBox="0 0 35 35" sx={style.svg} {...props}>
			<path fillRule="evenodd" clipRule="evenodd" d="M12.8094 5.51786C14.0534 4.27386 15.7406 3.575 17.4999 3.575C19.2591 3.575 20.9464 4.27386 22.1903 5.51786C23.4343 6.76185 24.1332 8.44906 24.1332 10.2083V15.2417H26.2499C27.2356 15.2417 28.1809 15.6332 28.8779 16.3303C29.575 17.0273 29.9665 17.9726 29.9665 18.9583V27.7083C29.9665 28.6941 29.575 29.6394 28.8779 30.3364C28.1809 31.0334 27.2356 31.425 26.2499 31.425H8.74987C7.76415 31.425 6.8188 31.0334 6.12179 30.3364C5.42478 29.6394 5.0332 28.6941 5.0332 27.7083V18.9583C5.0332 17.9726 5.42478 17.0273 6.12179 16.3303C6.8188 15.6332 7.76415 15.2417 8.74987 15.2417H10.8665V10.2083C10.8665 8.44906 11.5654 6.76185 12.8094 5.51786ZM12.4665 15.2417H22.5332V10.2083C22.5332 8.87341 22.0029 7.59316 21.059 6.64923C20.115 5.70529 18.8348 5.175 17.4999 5.175C16.1649 5.175 14.8847 5.70529 13.9408 6.64923C12.9968 7.59316 12.4665 8.87341 12.4665 10.2083V15.2417ZM8.74987 16.8417C8.1885 16.8417 7.65011 17.0647 7.25316 17.4616C6.85621 17.8586 6.6332 18.397 6.6332 18.9583V27.7083C6.6332 28.2697 6.85621 28.8081 7.25316 29.205C7.65011 29.602 8.1885 29.825 8.74987 29.825H26.2499C26.8112 29.825 27.3496 29.602 27.7466 29.205C28.1435 28.8081 28.3665 28.2697 28.3665 27.7083V18.9583C28.3665 18.397 28.1435 17.8586 27.7466 17.4616C27.3496 17.0647 26.8112 16.8417 26.2499 16.8417H8.74987ZM17.4999 21.075C17.9417 21.075 18.2999 21.4332 18.2999 21.875V24.7917C18.2999 25.2335 17.9417 25.5917 17.4999 25.5917C17.058 25.5917 16.6999 25.2335 16.6999 24.7917V21.875C16.6999 21.4332 17.058 21.075 17.4999 21.075Z" />
		</SvgIcon>
	)
}

export default LockIcon;