import * as Yup from 'yup';
import React, { useState } from 'react';
import {
  Button,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { login } from '../../../store/actions/authActions';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import FormTextField from '../../../components/shared/form/FormTextField';

interface ILoginProps {}

const LoginSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const Login: React.FC<ILoginProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleForgotPassword = () => {
    history(`/ForgotPassword`);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    dispatch(
      login(
        values,
        () => {
          enqueueSnackbar('Login Success', { variant: 'success' });
          setLoading(false);
          history(`/`);
        },
        () => {
          enqueueSnackbar('Could not find email / password.', {
            variant: 'error',
          });
          setLoading(false);
        },
      ),
    );
  };

  return (
    <>
      <Typography variant="h2" sx={{ mb: 4 }}>
        Log in
      </Typography>

      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={LoginSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormTextField
              type="text"
              name="email"
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              {...(errors.email && touched.email
                ? {
                    error: true,
                    helperText: errors.email,
                  }
                : {})}
            />
            <div style={{ marginTop: '1rem' }}>
              <FormTextField
                type="password"
                name="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                {...(errors.password && touched.password
                  ? {
                      error: true,
                      helperText: errors.password,
                    }
                  : {})}
              />
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <FormGroup>
                <FormControlLabel
                  label={
                    <Typography sx={{ fontSize: '0.875rem' }}>
                      Remember me
                    </Typography>
                  }
                  control={
                    <Checkbox
                      size="small"
                      checked={checked}
                      onChange={(e) => setChecked(e?.target.checked)}
                    />
                  }
                />
              </FormGroup>

              <Typography
                sx={{ ml: 'auto', fontWeight: '700' }}
                color="primary"
                variant="button"
                onClick={() => handleForgotPassword()}
              >
                Forgot Password?
              </Typography>
            </Box>

            <Box sx={{ mt: 4 }}>
              <Button disabled={loading} variant="contained" type="submit">
                {loading && <CircularProgress color='inherit' size={18} sx={{marginRight:'5px'}} />}
                Log in
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Login;
