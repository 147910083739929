import {
	Box,
	Typography,
} from '@mui/material';

export default function PageTitle(props: any) {
	return (

		<Box sx={{
			display: 'flex',
			flexDirection: {
				xs: 'column',
				lg: 'row',
			},
			alignItems: {
				xs: 'center',
			}
		}}>
			<Box>
				<Typography variant="h2">{props.title}</Typography>
			</Box>
			<Box sx={{
				ml: {
					sx: 0,
					lg: 'auto',
				}
			}}>
				{props.children}
			</Box>
		</Box>

	)
}