import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IRootState } from './interfaces/store';
import AppAuthenticated from './AppAuthenticated';
import AppPublic from './AppPublic';
import './App.css';
import ErrorBoundary from './components/shared/ErrorBoundary';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Homepage from './pages/public/auth/Homepage';
import Profile from './pages/private/account/ProfilePage';
import Dashboard from './pages/private/DashboardPage';
import ResourceManagement from './pages/private/resource/ResourceManagementPage';
import ProtectedRoutes from './ProtectedRoutes';
import Users from './pages/private/users/UsersPage';
import Search from './pages/private/search/SearchPage';
import ResourceDetail from './pages/private/resource/ResourceDetailPage';
import ResourceCardPage from './pages/private/resource/ResourceCardPage';
import ResourceBoard from './pages/private/resource/ResourceBoardPage';
import EmailManagementPage from './pages/private/email/EmailManagementPage';
import SearchResultPage from './pages/private/search/SearchResultPage';
import Privacy from './pages/public/privacy/PrivacyPage';

const authContStyle = {
	marginTop: 0,
	width: 800,
  };
function App(props: any) {

	const { enqueueSnackbar } = useSnackbar();
	const error = useSelector((state: IRootState) => state.main.error);
	const success = useSelector((state: IRootState) => state.main.success);
	useEffect(() => {
	  if (error.error){
		enqueueSnackbar(error.error, { variant: 'error' });
	  }
	}, [error])
	useEffect(() => {
	  if (success.message){
		enqueueSnackbar(success.message, { variant: 'success' });
	  }
	}, [success])

	const email = useSelector(
		(state: IRootState) => state.main.my_user?.email
	);

	return (
		<ErrorBoundary>
		<div key={email}>
					{email ?(
					<Routes>
						<Route path="privacy" element={<Privacy />} />
						<Route element={<ProtectedRoutes allowedRoles={["Admin"]} />}>
							<Route path="/" element={<AppAuthenticated />} >
								<Route path="Users" element={<Users />} />
								<Route path="Dashboard" element={<Dashboard />}/>
          						<Route path="Profile" element={<Profile />} />
          						<Route path="Resources" element={<ResourceManagement />} />
          						<Route path="domains" element={<EmailManagementPage />} />
							</Route>
						</Route>
						<Route element={<ProtectedRoutes allowedRoles={["Admin","Navigator"]} />}>
							<Route element={<Homepage />} >
								<Route path="/search"  >
									<Route index element={<Search />} />  
									<Route path="results" element={<SearchResultPage />} /> 
								</Route>
								<Route path="resources/:id" element={<ResourceDetail />} />
								<Route path="/resource-card" element={<ResourceCardPage />} />
								<Route path="/resource-board" element={<ResourceBoard />} />
							</Route>
						</Route>
						<Route path='*' element={<Navigate to="/"/>} />
					</Routes>
					):(
						<AppPublic />
					)}
			{/* {email ? <AppAuthenticated /> : } */}
		</div>
		</ErrorBoundary>
	);
}

App.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default App;