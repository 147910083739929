import {
	Box,
	Tooltip
} from '@mui/material';
import theme from '../../themes/theme';

import DynamicIcon from '../shared/DynamicIcon';

type IconProps = {
	color: string,
	icon: string,
	title?: string,
};

function IconBlock(props: IconProps) {
	const { color, icon } = props;

	const style = {
		block: {
			display: 'flex',
			flexGrow: '44px',
			alignItems: 'center',
			justifyContent: 'center',
			minWidth: '44px', height: '44px',
			marginRight: '16px',
			position: 'relative',

			'&:before': {
				content: '""',
				display: 'block',
				zIndex: '0',
				position: 'absolute',
				left: 0, top: 0,
				width: '100%', height: '100%',
				background: color,
				opacity: 0.1,
				borderRadius: '8px',
			}
		},
		icon: {
			position: 'relative',
			zIndex: '2',
		},
	}

	return (

		<Tooltip

			title={props.title}
			placement="top"
			arrow
			sx={{
				'.MuiTooltip-tooltip': {
					color: color + ' !important',
				}
			}}
		>
			<Box sx={style.block} color={color}>
				<DynamicIcon color={color} icon={icon} />
			</Box>
		</Tooltip>
	)
}

IconBlock.defaultValue={
	title: '',
}

export default IconBlock;