import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function GraduationIcon(props: any) {

	return (
		<SvgIcon {...props}>
			<g clipPath="url(#clip0_158_19710)">
				<path d="M12.0003 13.7139C11.6767 13.7137 11.3569 13.645 11.0617 13.5125L6.85742 11.5925V18.3854C8.50046 19.0041 10.2449 19.3093 12.0003 19.2854C16.1832 19.2854 19.7147 17.7168 19.7147 15.8568V10.4225L12.9389 13.5125C12.6437 13.645 12.3239 13.7137 12.0003 13.7139V13.7139Z" />
				<path d="M23.7559 6.93382L12.5829 1.83802C12.2119 1.67232 11.788 1.67232 11.4171 1.83802L0.244128 6.93382C0.0973605 6.98848 0 7.12863 0 7.28524C0 7.44186 0.0973605 7.58201 0.244128 7.63667L4.64991 9.64669C4.74568 9.54052 4.86053 9.45329 4.9885 9.38953L9.44139 7.13524C9.54855 6.24808 10.6371 5.57096 12 5.57096C12.1576 5.56988 12.3152 5.57986 12.4714 5.60096C12.4945 5.60143 12.5175 5.60429 12.54 5.60954C13.7143 5.76812 14.5715 6.44955 14.5715 7.28529C14.5715 8.2453 13.44 8.99958 12 8.99958C11.8366 9.00051 11.6734 8.99048 11.5114 8.96958L7.55995 10.971L11.4171 12.7325C11.7886 12.8954 12.2114 12.8954 12.5829 12.7325L23.7559 7.63667C23.9027 7.58201 24 7.44186 24 7.28524C24 7.12863 23.9026 6.98848 23.7559 6.93382V6.93382Z" />
				<path d="M6.56103 10.5167L10.3411 8.60521L11.3825 8.07805C11.5852 8.12216 11.7922 8.14372 11.9997 8.14231C12.566 8.17325 13.1227 7.98612 13.5554 7.61946C13.6494 7.53265 13.7062 7.41288 13.714 7.28519C13.714 7.04946 13.4612 6.79661 13.054 6.62519C12.7189 6.49098 12.3607 6.424 11.9997 6.42803C11.0183 6.42803 10.2854 6.8823 10.2854 7.28519C10.2917 7.40266 10.3404 7.51385 10.4226 7.59804L9.65116 7.99231L5.56253 10.0623L5.37826 10.1566C5.23328 10.2283 5.14182 10.3763 5.14253 10.538V20.6867C4.80657 20.8807 4.64274 21.2761 4.74315 21.6509C4.84356 22.0256 5.18312 22.2862 5.57111 22.2862C5.9591 22.2862 6.29867 22.0256 6.39907 21.6509C6.49948 21.2762 6.3357 20.8807 5.99969 20.6867V10.7995L6.56103 10.5167Z" />
			</g>
		</SvgIcon>
	)
}

export default GraduationIcon;