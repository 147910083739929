import { Box, InputLabel, Select } from '@mui/material';

import ArrowDownSmallIcon from '../../icons/ArrowDownSmallIcon';

export default function FormSelectField(props: any) {

	return (

		<Box>
			<InputLabel>{props.label}
				<Box sx={{ mt: 1, }}>
					<Select
						id={props.id}
						fullWidth
						size="small"
						value={props.value}
						onChange={(e) => props.setValue(e?.target.value)}
						IconComponent={ArrowDownSmallIcon}
					>
						{props.children}
					</Select>
				</Box>
			</InputLabel>
		</Box>

	)
}
